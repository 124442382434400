import { render, staticRenderFns } from "./ConfigStepsMini.vue?vue&type=template&id=98cd9860&"
import script from "./ConfigStepsMini.vue?vue&type=script&lang=js&"
export * from "./ConfigStepsMini.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "ConfigStepsMini.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {
  VFlex,
  VLayout,
})
