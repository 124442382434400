<template>
	<g id="RTD" :transform="transform">
        <rect x="-14.5" y="-18.1" class="st0" width="30.3" height="38.9"/>
        <polyline class="st3-a" points="-10.5,25.8 -10.5,-13.4 -6.2,-13.4 -6.2,15.8 -1.6,15.8 -1.6,-13.4 3.1,-13.4 3.1,15.8 7.4,15.8 
          7.4,-13.4 11.7,-13.4 11.7,25.8  "/>
        <circle class="st4" cx="-10.5" cy="20.8" r="2"/>
        <circle class="st4" cx="11.7" cy="20.8" r="2"/>

        <path class="lead-b" d="M-10.5,20.8c0,0-7.2,119.7,27.9,185.2c30.2,56.3,89.6,39.9,89.6,39.9"/>
        <path class="lead-b" d="M11.7,20.8c0,0-4.1,65,1.3,123.6c10.2,111.3,90.9,97.5,90.9,97.5"/>
        <path class="lead-w" d="M-10.5,20.8c0,0-7.2,119.7,27.9,185.2c30.2,56.3,89.6,39.9,89.6,39.9"/>
        <path class="lead-w" d="M11.7,20.8c0,0-4.1,65,1.3,123.6c10.2,111.3,90.9,97.5,90.9,97.5"/>

        <g transform="translate(-82, -45)">
          <rect x="75.6" y="44.3" class="st0" width="16.2" height="5.4"/>
          <path class="st0" d="M68.7,47.1c0.3,0,6.8,0,6.8,0"/>
          <path class="st0" d="M91.8,47.1c0.3,0,6.8,0,6.8,0"/>
          <polyline class="st0" points="75.8,52 79.8,52 90.7,41.1   "/>
          <g>
            <path d="M84.7,53.7V54h-1.5v-0.4H84.7z"/>
            <path d="M86,51.7v0.8h0.8V53H86v1.6c0,0.4,0.1,0.6,0.4,0.6c0.1,0,0.2,0,0.3,0l0,0.4c-0.1,0-0.3,0.1-0.5,0.1
              c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.2-0.2-0.4-0.2-0.8V53H85v-0.4h0.4v-0.7L86,51.7z"/>
            <path d="M88.7,52.1c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8C88.4,51.4,88.7,51.7,88.7,52.1z
               M87.4,52.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.2-0.1-0.5-0.5-0.5C87.6,51.7,87.4,51.9,87.4,52.2z"/>

          </g>
        </g>
    </g>
</template>


<script>

import Accessory from './accessory-template.vue';

export default{
	extends: Accessory,
}

</script>
