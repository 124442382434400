<template>
  <div>
    <a class="anchor_top" id="top"></a>
    <saved-heaters v-model="saved_heaters" ref="savedHeaters"></saved-heaters>
    <v-layout pa-3 align-start :wrap="$vuetify.breakpoint.xs">
        <v-flex v-if="this.$vuetify.breakpoint.name == 'xs'" d-flex sm4 xs12>
          <heater-preview :mini="true" :ignore-watchers="ignoreWatchers" :step="currStep" v-on:next-step="next" :cfg="flexConfig"></heater-preview>
        </v-flex>
        <v-flex xs12 d-flex sm8>    
              <step-shape-power :mini="true" :ignore-watchers="ignoreWatchers" :cfg="flexConfig" :show-name-field="showNameField" v-on:reset="reset" v-on:next-step="next" v-on:update="updateConfig" :preset="preset"></step-shape-power>
        </v-flex>
        <v-flex v-if="this.$vuetify.breakpoint.name != 'xs'" d-flex xs12 sm4>
          <heater-preview :mini="true" :cfg="flexConfig"></heater-preview>
        </v-flex>
    </v-layout>
    <step-save :mini="true" ref="SaveStep" :csrf="csrf" :cfg="flexConfig" v-on:edit-heater="restart" v-on:new="newConfig" :saved_heaters="saved_heaters" v-on:update-hash="updateHash" v-on:save-heater="saveHeater"></step-save>
  </div>
</template>

<script>

import StepShapePower from "./StepShapePower.vue";
//import StepControl from "./StepControl.vue";
//import StepLeads from "./StepLeads.vue";
//import StepRequirements from "./StepRequirements.vue";
import StepSave from "./StepSave.vue";

import HeaterPreview from './HeaterPreview.vue';
import SavedHeaters from './SavedHeaters.vue';

//import PowerFlowCalc from "./PowerFlowCalc.vue";
//import OhmsLawCalc from "./OhmsLawCalc.vue";
import DefaultConfig from "./default-config.js";


export default {
  props : ['csrf', 'preset'],
	data : function(){
    //get the config stored in local storage if available. 
    var cfg = {};
    try{
      if (typeof localStorage.getItem('flex-config-v3' + this.preset) != 'undefined' && localStorage.getItem('flex-config-v3' + this.preset) !== null){
        var s_cfg = JSON.parse(localStorage.getItem('flex-config-v3' + this.preset));
        var d_cfg = JSON.parse(JSON.stringify(DefaultConfig));
        cfg = Object.assign({}, d_cfg, s_cfg);
      }else{
        cfg = JSON.parse(JSON.stringify(DefaultConfig));
      }
    }
    catch(e) {
      console.log(e);
      cfg = JSON.parse(JSON.stringify(DefaultConfig));
    }
    return {
      ignoreWatchers : false,
      currStep : 0,
      //dialog : false,
      //power_rate_calc : false,
      //ohms_law_calc : false,
      //show_watt_calculator : false,
      //dialog_title : '',
      //dialog_text : '',
      cookie_var : 'flex-config-v3',
      defaultConfig : JSON.parse(JSON.stringify(DefaultConfig)),
      flexConfig : cfg,
      //showSavedHeaters : false,
      saved_heaters : {}
    }
  },
  mounted : function(){
    if (this.flexConfig.id === null){
      this.reset();
    }
    if(typeof this.preset != 'undefined' && this.preset != this.flexConfig.material.value){
      this.reset();
    }
    var g = this;
    setTimeout(function(){g.updateConfig(g.flexConfig)}, 1000);
    setInterval(this.saveConfig, 3000)
  },
  computed : {
    wrapLayout : function () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return false
      }
      return false
    },
    showNameField : function(){
      return this.num_saved_heaters > 1;
    }
  },
  methods : {
    next : function(cfg){
      this.updateConfig(cfg);
      this.saveHeater(cfg);
      this.saveConfig(true);
      this.currStep++;
      this.$refs.savedHeaters.showme = false;
      if (typeof ga != 'undefined'){
        ga('send', 'event', 'quote-request', 'page-' + this.currStep, 'flexible-heater');
      }
      window.location = '/site/flex-specs';
      //this.$vuetify.goTo('#top');
    },
    restart : function(){
      this.currStep = 1;
      this.$refs.savedHeaters.showme = false;
      this.$vuetify.goTo('#top');
    },
    reset : function(){
      this.flexConfig = JSON.parse(JSON.stringify(this.defaultConfig));
      this.flexConfig.id = 'flex_' + (+ new Date());
      if (typeof this.preset != 'undefined'){
        this.flexConfig.material.value = this.preset;
      }
      this.saveConfig();
      this.$emit('update', this.flexConfig);
      this.$refs.savedHeaters.showme = false;
      this.$vuetify.goTo('#top');
    },
    updateConfig : function(cfg, new_config){
      this.ignoreWatchers = true;
      if (typeof new_config == 'undefined' || new_config === false){
        this.flexConfig = Object.assign(this.flexConfig, cfg);
      }else{
        this.flexConfig = cfg;
      }
      this.$emit('update', this.flexConfig);
      var g = this;
      setTimeout(function(){g.ignoreWatchers = false}, 500)
    },
    newConfig : function(cfg){
      this.updateConfig(cfg, true);
      this.saveHeater(cfg);
    },
    saveHeater : function(cfg){
      this.$refs.savedHeaters.addConfig(cfg);
    },
    saveConfig : function(hard_save){
      var success = true;
      if (typeof localStorage === 'object') {
          try {
              if (hard_save){
                localStorage.setItem(this.cookie_var, JSON.stringify(this.flexConfig));
              }else{
                localStorage.setItem(this.cookie_var + this.preset, JSON.stringify(this.flexConfig));
              }
          } catch (e) {
              console.log("Device does not support local storage");
              success = false;
          }
      }
      return success;
    },
    updateHash :function(id, hash){
      this.saved_heaters[id].hash = hash;
    },
    showDialog : function(){

    }
  },
  watch : {
    'saved_heaters' : function(newV){
    }
  },
  components : {
    StepShapePower,
    //StepControl,
    //StepLeads,
    //StepRequirements,
    StepSave,

    HeaterPreview,
    SavedHeaters,

    //PowerFlowCalc,
    //OhmsLawCalc
  }
}

</script>
