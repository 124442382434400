import { render, staticRenderFns } from "./SavedHeaters.vue?vue&type=template&id=6750395c&"
import script from "./SavedHeaters.vue?vue&type=script&lang=js&"
export * from "./SavedHeaters.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "SavedHeaters.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VDataTable } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VCard,
  VCardText,
  VDataTable,
  VIcon,
})
