<template>
		<g id="HeaterCone" :transform="_transform">
		  <circle :cx="center_x" :cy="center_y" :r="radius" fill="none" stroke-width="1" stroke="#aaaaaa" stroke-dasharray="4"/>
		  <circle :cx="center_x" :cy="center_y" :r="inner_radius" fill="none" stroke-width="1" stroke="#aaaaaa" stroke-dasharray="4"/>
	      <path id="arc1" :d="cone_shape" class="flex-heater" />

	      <g id="HeaterCircle-elements">
	        <path :style="strokeStyle" class="heater-element" :d="element_path"/>
	      </g>
	      <acc-leads :cfg="cfg" :scale="scale" :x="0" :y="0"></acc-leads>
	      <g id="Dim-Diameter" :transform="dim_diameter_transform">
	        <polyline class="dim-bracket" :points="dim_diameter_left"/>
	        <polyline class="dim-bracket" :points="dim_diameter_right"/>
	        <text text-anchor="middle" :transform="dim_diameter_text">{{cfg.diameter.value}}”</text>
	      </g>
	      <g id="Dim-Width">
	      	<path class="dim-bracket" :d="bracket_inner_width" />
	      	<text text-anchor="middle" :transform="dim_inner_width_text">{{inner_width}}”</text>
	      </g>
	      <g id="donut_hole" v-if="cfg.shape.value == 'donut'">
	        <circle  :cx="dim_center_diameter" :cy="dim_center_diameter" :r="dim_center_inner_diameter" class="donut-hole"/>
	      </g>
	      <g id="Dim-Inner-Diameter">
	        <polyline class="dim-bracket" :points="dim_inner_diameter_left"/>
	        <polyline class="dim-bracket" :points="dim_inner_diameter_right"/>
	        <text text-anchor="middle" :transform="dim_inner_diameter_text">{{cfg.inner_diameter.value}}”</text>
	      </g>
	      
	    </g>
</template>


<script>

import Accessory from './accessory-template.vue';
import AccLeads from './acc-leads.vue';

export default{
	extends: Accessory,
	props : ['cfg', 'strokeStyle', 'density'],
	data : function(){
		return {

		}
	},
	computed : {
	  //circles
      dim_center_diameter : function(){
        return this._scale(this.cfg.diameter.value / 2);
      },
      dim_center_inner_diameter : function(){
        return this._scale(this.cfg.inner_diameter.value / 2);
      },
      dim_diameter_text : function(){
        return 'matrix(1 0 0 1 ' + (this.dim_center_diameter - 2) + ' -12 )';
      },
      dim_diameter_left : function(){
        return  0 + ',-5 ' + 0 + ',-15 ' + (this.dim_center_diameter - 20) + ',-15';  
      },
      dim_diameter_right : function(){
        return  this._scale(this.cfg.diameter.value) + ',-5 ' + this._scale(this.cfg.diameter.value) + ',-15 ' + (this.dim_center_diameter + 20) + ',-15';  
      },
      //adjusted the top diameter dimension label when donut dimension need placing too. 
      dim_diameter_transform : function(){
        return 'matrix(1 0 0 1 0 -20)';
      },
      dim_inner_diameter_left : function(){
        return  this._scale(((this.cfg.diameter.value - this.cfg.inner_diameter.value)/2)) + ',-5 ' + this._scale(((this.cfg.diameter.value - this.cfg.inner_diameter.value)/2)) + ',-15 ' + (this.dim_center_diameter - 20) + ',-15';  
      },
      dim_inner_diameter_right : function(){
        return  (this._scale( (this.cfg.diameter.value / 2) + (this.cfg.inner_diameter.value/2) ) ) + ',-5 ' + (this._scale( (this.cfg.diameter.value / 2) + (this.cfg.inner_diameter.value/2) ) ) + ',-15 ' + (this.dim_center_diameter + 20) + ',-15';  
      },
      dim_inner_diameter_text : function(){
        return 'matrix(1 0 0 1 ' + (this.dim_center_diameter - 2) + ' -12 )';
      },
      dim_inner_width_text : function(){
      	var diff_radius_half = (this.radius - this.inner_radius) / 2 + this.inner_radius-4;
      	var p = this.polarToCartesian(this.center_x, this.center_y, diff_radius_half, -this.half_sweep - 8);
        return 'matrix(1 0 0 1 ' + p.x + ' ' + p.y + ' )';
      },


      radius : function(){
      	return this._scale(this.cfg.diameter.value / 2)
      },
      inner_radius : function(){
      	return this._scale(this.cfg.inner_diameter.value / 2)
      },
      inner_width : function(){
      	return this.cfg.diameter.value - this.cfg.inner_diameter.value
      },
      center_x : function(){
      	return this._scale(this.cfg.diameter.value / 2)
      },
      center_y : function(){
      	return this._scale(this.cfg.diameter.value / 2)
      },
      half_sweep: function(){
      	return this.cfg.cone_sweep.value / 2;
      },


      bracket_inner_width : function(){
      	var p = this.polarToCartesian(this.center_x, this.center_y, this.radius, -this.half_sweep - 4);
      	var path = ["M", p.x, p.y].join(" ");

      	p = this.polarToCartesian(this.center_x, this.center_y, this.radius, -this.half_sweep - 8);
      	path = path + ' ' + ["L", p.x, p.y].join(" ");

      	var diff_radius_half = (this.radius - this.inner_radius) / 2 + this.inner_radius + 14;
      	p = this.polarToCartesian(this.center_x, this.center_y, diff_radius_half, -this.half_sweep - 8);
      	path = path + ' ' + ["L", p.x, p.y].join(" ");

      	diff_radius_half = (this.radius - this.inner_radius) / 2 + this.inner_radius - 14;
      	p = this.polarToCartesian(this.center_x, this.center_y, diff_radius_half, -this.half_sweep - 8);
      	path = path + ' ' + ["M", p.x, p.y].join(" ");
      	
      	p = this.polarToCartesian(this.center_x, this.center_y, this.inner_radius, -this.half_sweep - 8);
      	path = path + ' ' + ["L", p.x, p.y].join(" ");

      	p = this.polarToCartesian(this.center_x, this.center_y, this.inner_radius, -this.half_sweep - 4);
      	path = path + ' ' + ["L", p.x, p.y].join(" ");

      	return path;
      },

      //element 
      element_path : function(){
      	

        if(this.cfg.material.value == "mica"){
          if (this.cfg.watt_density.value > 80){return ''}
          var margin = this._scale(.15);
          //var density = this._scale(1 - this.cfg.watt_density.value / 50);
          var density = this.density * 2.5;
          var curr_sweep = this.half_sweep;
          //var half_sweep = this.half_sweep - margin - 3;     
          //console.log("half_sweep" , this.density)
          var start = this.polarToCartesian(this.center_x, this.center_y, this.radius+ margin, curr_sweep);
          var x = start.x;
          var y = start.y;
          var path = ["M", x, y].join(" ");
          var i;
          for(i = 0; i < ((this.half_sweep * 2) / density); i++){
            var end = this.polarToCartesian(this.center_x, this.center_y, this.inner_radius - margin, curr_sweep - 6);
            path = path + " " + ["L", end.x, end.y].join(" ");
            curr_sweep = curr_sweep - (density * 1.5);
            if (curr_sweep - 6 < -this.half_sweep){break}
            start = this.polarToCartesian(this.center_x, this.center_y, this.radius + margin, curr_sweep);
            path = path + " " + ["M", start.x, start.y].join(" ");
          }
          return path;
        }
        if (this.cfg.watt_density.value > 30){return ''}
      	var margin = this._scale(.5);
      	//var density = this._scale(1 - this.cfg.watt_density.value / 50);
        var density = this.density * 2.5;
      	var curr_radius = this.radius - margin;
      	var half_sweep = this.half_sweep - margin;
      	var start = this.polarToCartesian(this.center_x, this.center_y, curr_radius, half_sweep);
      	var x = start.x;
      	var y = start.y;
      	var path = ["M", x, y].join(" ");
      	var i;
      	for(i = 0; i < (this._scale(this.inner_width) / density /4); i++){
      		if (i > 0){
      			path = path + " " + ["L", x, y].join(" ");
      		}
	      	path = path + " " + this.describeArc(this.center_x, this.center_y, curr_radius, -half_sweep, half_sweep);
	      	curr_radius = curr_radius - density;
          if (curr_radius < this.inner_radius + 4){break};
	      	var start = this.polarToCartesian(this.center_x, this.center_y, curr_radius, -half_sweep);
	      	var x = start.x;
	      	var y = start.y;
	      	path = path + " " + ["L", x, y].join(" ");
	      	path = path + " " + this.describeReverseArc(this.center_x, this.center_y, curr_radius, -half_sweep, half_sweep);
	      	curr_radius = curr_radius - density;
          if (curr_radius < this.inner_radius + 4) {break};
	      	start = this.polarToCartesian(this.center_x, this.center_y, curr_radius, half_sweep);
	      	x = start.x;
	      	y = start.y;
	      }
        return path;
      },

      watt_density : function(){
          radius = this.cfg.diameter.value/2;
          var inner_radius = this.cfg.inner_diameter.value/2;
          var circle_area = (Math.PI * (radius * radius) );
          var hole_area = (Math.PI * (inner_radius * inner_radius) )
          return this.cfg.watts.value / ((circle_area - hole_area) * (this.cfg.cone_sweep.value / 360));
      },

      _transform : function(){
      	return;
      	return "translate(" + this.radius + ", 0)";
      },
      cone_shape : function(){
	      	//outter arc
	      	var start = this.polarToCartesian(this.center_x, this.center_y, this.radius, this.half_sweep);
	      	var x = start.x;
	      	var y = start.y;
	      	var path = ["M", x, y].join(" ");
	      	path = path + " " + this.describeArc(this.center_x, this.center_y, this.radius, -this.half_sweep, this.half_sweep);
	      	//line to inner arc
	      	start = this.polarToCartesian(this.center_x, this.center_y, this.inner_radius, -this.half_sweep);
	      	x = start.x;
	      	y = start.y;
	      	path = path + [" L", x, y].join(" ");
	      	path = path + " " + this.describeReverseArc(this.center_x, this.center_y, this.inner_radius, -this.half_sweep, this.half_sweep);
	      	var start = this.polarToCartesian(this.center_x, this.center_y, this.radius, this.half_sweep);
	      	var x = start.x;
	      	var y = start.y;
	      	path = path + [" L", x, y].join(" ");
	      	return path;
	   },
	},
	methods : {
		_scale : function(v){
	        return v * this.scale;
	    },
	    polarToCartesian : function (centerX, centerY, radius, angleInDegrees) {
		  var angleInRadians = (angleInDegrees + 90) * Math.PI / 180.0;
		  return {
		    x: centerX + (radius * Math.cos(angleInRadians)),
		    y: centerY + (radius * Math.sin(angleInRadians))
		  };
		},
		describeArc : function (x, y, radius, startAngle, endAngle){

		    var start = this.polarToCartesian(x, y, radius, endAngle);
		    var end = this.polarToCartesian(x, y, radius, startAngle);

		    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

		    var d = ["A", radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(" ");

		    return d;       
		},
		describeReverseArc : function (x, y, radius, startAngle, endAngle){

		    var start = this.polarToCartesian(x, y, radius, startAngle);
		    var end = this.polarToCartesian(x, y, radius, endAngle);

		    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

		    var d = ["A", radius, radius, 0, largeArcFlag, 1, end.x, end.y].join(" ");

		    return d;       
		}
	},
	components : {
		AccLeads
	}
}

</script>


<style type="text/css">
  text {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: .8em;
  }
  .dim-bracket{fill:none;stroke:#999999;stroke-miterlimit:10;}
</style>