<template>
	 <g id="ThreeProng" :transform="transform">
	 	<path class="white-plastic" d="M31.2-0.8v-6H16.5l-0.8-0.3v-2h-5.6v-1.8h14.5l1.7-1.1v-1.2l-1.2-0.7L2-14.7l-0.4-0.9l-1.7,0.1v4.6h8.5v1.6
		h-8.5L0,9.8h8.5c0,0.6,0,1.4,0,1.9h-8.6v4.6l1.7,0.1L2,15.5l23.2-0.9l1.2-0.7v-1.2l-1.7-1.1H10.2V9.8h0.2h5.3v-2h15.6V1.9H15.7
		v-2.7H31.2z"/>
		<rect x="15.7" y="-7.2" class="white-plastic" width="0.6" height="6.6"/>
		<rect x="15.7" y="1.7" class="white-plastic" width="0.6" height="6.6"/>
		<rect x="11.5" y="-6.4" class="white-plastic" width="4.1" height="13.6"/>
		<rect x="0" y="-7.4" class="white-plastic" width="15.7" height="15.7"/>
	</g>
</template>


<script>

import Accessory from './accessory-template.vue';

export default{
	extends: Accessory,
	computed : {
		transform : function(){
			return 'translate(' + this.x + ' ' + this.y + ') scale(' + this.scale/20 + ' ' + this.scale/20 + ')';
		}
	}
}

</script>