//used to get the internal part number for the options when seding to zoho. 
//label must match EXACTLY the value of the cfg param. 
//
export default {
  'MC-5A Temeprature Controller' : '',
  'RTD - 100K OHM CLASS A' : 'THRM236',
  'RTD - 1000K OHM' : 'THRM242',
  'Thermistor NTC – 100KOHM 4066K BEAD B25/85' : 'THRM238',
  'Thermistor NTC - 10KOHM 3977K BEAD B25/85' : 'THRM239',
  'Thermocouple - 18in TYPE K Fiberglass' : 'THRM055',
  'Mini K SMPW-K-M-RoHS' : 'THRM233',
  'FREEZE PROTECTION Close (On) 32°F - Open (Off) 50°F' : 'STAT1525',
  'Open (Off) 120°F/49°C' : 'STAT1517',
  'Open (Off) 149°F/65°C ' : 'STAT1599',
  'Open (Off) 158°F/70°C' : 'STAT1210',
  'Open (Off) 158°F/70°F' : 'STAT1070',
  'Open (Off) 176°F/80°C ' : 'STAT1230',
  'Open (Off) 180°F/82°C ' : 'STAT1523',
  'Open (Off) 194°F/90°C' : 'STAT1570',
  'Open (Off) 212°F/100°C' : 'STAT1597',
  'Open (Off) 239°F/115°C' : 'STAT1595',
  'Open (Off) 248°F/120°C' : 'STAT1521',
  'Open (Off) 266°F/130°F' : 'STAT1522',
  'Open (Off) 300°F/149°C' : 'STAT1220',
  'Open (Off) 302°F/150°C' : 'STAT1235',
  'Open (Off) 329°F/165°C' : 'STAT1516',
  'Open (Off) 338°F/170°C' : 'STAT1596',
  'Open (Off) 350°F/176°C' : 'STAT1450',
  'Extruded Silicone Rubber / Sil-A-Bend' : '',
  'PTFE / Teflon' : '22 GA UL1199',
  "AMP # 2-520183-2" : 'QKCONN997',
  "Molex Housing 39-01-2020 and Molex Pin 39-00-0038" : 'PIN560, PLU560',
  'Over Mold 120V Plug' : 'HAR1600',
  'Pressure Sensitive Adhesive' : 'TAPE002'
}

