import Geometry from '@/plugins/Geometry';

export default {

  scale : 0,
  cfg : 0,
  center : {x : 0, y : 0},
  radius : 0,
  inner_radius : 0,
  diameter : 0,
  inner_diameter : 0,

  setup : function(cfg, scale, density){
    if (typeof scale != 'undefined'){
      this.scale = scale;
    }
    if (typeof density != 'undefined'){
      this.density = density;
    }
    if (typeof cfg == 'undefined' || cfg == null){
      return;
    }
    this.cfg = cfg;
    this.diameter = cfg.diameter.value;
    this.inner_diameter = cfg.inner_diameter.value;
    this.cone_sweep = cfg.cone_sweep.value;
    this.num_sides = cfg.num_sides.value;
    this.edge_length = cfg.edge_length.value;
    this.center = this.findCenter();
    this.radius = this.findRadius();
    this.inner_radius = this.findInnerRadius();
    this.half_sweep = this.findHalfSweep();

    //scaled
    this._diameter = this._scale(this.diameter);
    this._inner_diameter = this._scale(this.inner_diameter);
    this._center = this._scale(this.center);
    this._radius = this._scale(this.radius);
    this._inner_radius = this._scale(this.inner_radius);
  },

  findCenter : function(cfg){
    this.setup(cfg);
    return {x : this.diameter / 2, y : this.diameter / 2};
  },
  findRadius : function(cfg){
    this.setup(cfg);
    return this.diameter / 2;
  },
  findInnerRadius : function(cfg){
    this.setup(cfg);
    return this.inner_diameter / 2;
  },
  findInnerWidth : function(cfg){
    this.setup(cfg);
    return this.diameter - this.inner_diameter;
  },
  findHalfSweep : function(cfg){
    return 45;
    this.setup(cfg);
    return this.cone_sweep / 2;
  },
  findMaxEdgeLength : function(cfg){
    this.setup(cfg);
    var segmentSweep = 360 / this.num_sides
    return Geometry.arcLength(segmentSweep, this.radius);
  },
  findArea : function(cfg){
    //percentage of area. 
    this.setup(cfg);
    var circum = 2 * Math.PI * this.radius;
    var void_length = (circum - (this.edge_length * this.num_sides)) / this.num_sides;
    var rad_diff = this.radius - this.inner_radius;
    var angle = Geometry.arcAngle(void_length, rad_diff);
    var sub_area = (angle / 360) * (Math.PI * (rad_diff * rad_diff)) * this.num_sides;
    var area = (Math.PI * (this.radius * this.radius)) - sub_area;
    return area;
    var vertices = this.star_shape_vertices(cfg, 1, 1, true);
    var total = 0;
    for (var i = 0, l = vertices.length; i < l; i++) {
      var addX = vertices[i].x;
      var addY = vertices[i == vertices.length - 1 ? 0 : i + 1].y;
      var subX = vertices[i == vertices.length - 1 ? 0 : i + 1].x;
      var subY = vertices[i].y;
      total += (addX * addY * 0.5);
      total -= (subX * subY * 0.5);
    }
    return Math.abs(total);
  },


  _scale : function(v){
    if (typeof v == 'object' && v != null){
      var g = this;
      var r = {};
      Object.keys(v).forEach(function(k){
        if (typeof v[k] == "number"){
          r[k] = v[k] * g.scale;
        }else{
          r[k] = v[k];
        }
      });
      return r;     
    }
    return v * this.scale;
  },

  inner_points : function(scaled, cfg, scale){
    this.setup(cfg, scale);
    var p = [];
    for (var i = 0; i < this.num_sides; i++){
      var _p = Geometry.getPerimeterPoint(i, this.inner_radius, this.num_sides, true)._offset(this.center.x);
      if (typeof scaled != 'undefined' || scaled === true){
        p[i] = _p._scale(this.scale).value;
      }else{
        p[i] = _p.value;
      }
    }
    return p;
  },

  outer_points : function(scaled, cfg, scale){
    this.setup(cfg, scale);
    var margin = .5;
    var _margin = this._scale(.5);
    var segmentSweep = 360 / this.num_sides
    var halfArcAngle = segmentSweep / 2;
    var inner_edge_length = Geometry.arcLength(segmentSweep, this.inner_radius - margin) - (margin*2);
    var p = Geometry.getPerimeterPoint(0, this.inner_radius - margin, this.num_sides, false)._offset(this.center.x)._scale(this.scale).value;
    var start = Geometry.getPerimeterCorners(0, this.inner_radius - margin, this.num_sides, inner_edge_length)._offset(this.center.x)._scale(this.scale).value;
    var seg = Geometry.getPerimeterCorners(0, this.radius - margin, this.num_sides, this.edge_length - (margin * 2))._offset(this.center.x)._scale(this.scale).value;
    return [start[1], seg[1], start[0], seg[0], p];

  },

  star_shape : function(cfg, scale, density){
      this.setup(cfg, scale, density);
      //outter arc
      //var start = Geometry.getInnerPoint(1, )
      // /var segmentSweep = Geometry.arcAngle(this.edge_length, this.radius);
      var segmentSweep = 360 / this.num_sides;
      var halfArcAngle = segmentSweep / 2;
      var offsetSweep = Geometry.arcOffsetAngle(this.radius, this.num_sides, this.edge_length)
      var start = Geometry.getPerimeterPoint(0, this.inner_radius, this.num_sides)._offset(this.center.x)._scale(this.scale).value;
      var path = ["M", start.x, start.y].join(" ");
      for (var i = 0; i < this.num_sides; i++){
        if (i > 0){
          var start = Geometry.getPerimeterPoint(i, this.inner_radius, this.num_sides)._offset(this.center.x)._scale(this.scale).value;
          path = path + " " + ["L", start.x, start.y].join(" ");
        }
        var seg = Geometry.getPerimeterCorners(i, this.radius, this.num_sides, this.edge_length)._offset(this.center.x)._scale(this.scale).value;
        path = path + " " + ["L", seg[0].x, seg[0].y].join(" ");
        //path = path + " " + ["L", seg[1].x, seg[1].y].join(" ");
        path = path + " " + Geometry.describeReverseArc(this._center.x, this._center.y, this._radius, (segmentSweep * i) + offsetSweep - halfArcAngle, (segmentSweep * (i + 1)) - offsetSweep - halfArcAngle);
      }
      return path;
   },

   star_shape_poly : function(cfg, scale, density){
      var cfg2 = JSON.parse(JSON.stringify(cfg))
      if (cfg2.material.value == 'mica'){
        cfg2.edge_length.value = cfg2.edge_length.value + .5;
        cfg2.inner_diameter.value = cfg2.inner_diameter.value + .25;
      }else{
        cfg2.edge_length.value = cfg2.edge_length.value - .5;
        cfg2.inner_diameter.value = cfg2.inner_diameter.value * .75;
      }
      this.setup(cfg2, scale, density);
      var segmentSweep = 360 / this.num_sides;
      var halfArcAngle = segmentSweep / 2;
      var offsetSweep = Geometry.arcOffsetAngle(this.radius, this.num_sides, this.edge_length)
      var start = Geometry.getPerimeterPoint(0, this.inner_radius, this.num_sides)._offset(this.center.x)._scale(this.scale).value;
      var path = ["M", start.x, start.y].join(" ");
      for (var i = 0; i < this.num_sides; i++){
        if (i > 0){
          var start = Geometry.getPerimeterPoint(i, this.inner_radius, this.num_sides)._offset(this.center.x)._scale(this.scale).value;
          path = path + " " + ["L", start.x, start.y].join(" ");
        }
        var seg = Geometry.getPerimeterCorners(i, this.radius, this.num_sides, this.edge_length)._offset(this.center.x)._scale(this.scale).value;
        path = path + " " + ["L", seg[0].x, seg[0].y].join(" ");
        //path = path + " " + ["L", seg[1].x, seg[1].y].join(" ");
        path = path + " " + Geometry.describeReverseArc(this._center.x, this._center.y, this._radius, (segmentSweep * i) + offsetSweep - halfArcAngle, (segmentSweep * (i + 1)) - offsetSweep - halfArcAngle);
      }

      return path;
   },


   star_shape_vertices : function(cfg, scale, density){
      this.setup(cfg, scale, density);
      //outter arc
      //var start = Geometry.getInnerPoint(1, )
      // /var segmentSweep = Geometry.arcAngle(this.edge_length, this.radius);
      var segmentSweep = 360 / this.num_sides;
      var halfArcAngle = segmentSweep / 2;
      var offsetSweep = Geometry.arcOffsetAngle(this.radius, this.num_sides, this.edge_length)
      var start = Geometry.getPerimeterPoint(0, this.inner_radius, this.num_sides).value;
      var vertices = [];
      vertices.push(start);
      for (var i = 0; i < this.num_sides; i++){
        if (i > 0){
          var start = Geometry.getPerimeterPoint(i, this.inner_radius, this.num_sides).value;
          vertices.push(start);
        }
        var seg = Geometry.getPerimeterCorners(i, this.radius, this.num_sides, this.edge_length).value;
        vertices.push(seg[0]);
        vertices.push(Geometry.getPerimeterPoint(i, this.radius, this.num_sides, false).value);
        vertices.push(seg[1]);
      }
      return vertices;
   },




  //element 
  element_path : function(cfg, scale, density){
    this.setup(cfg, scale, density);

    //if (this.cfg.watt_density.value > 30){return ''}
    var margin = .5;
    var _margin = this._scale(.5);
    //var density = this._scale(1 - this.cfg.watt_density.value / 50);
    var density = this.density / 2.6;

    var path = ["M", this._center.x, this._center.y].join(" ");
    var angle;
    for (var i=0; i< (5 * this._diameter * 1.90); i++) {
      angle = 0.05 * i;
      var x=(1+angle)*Math.cos(angle/density) + this._center.x;
      var y=(1+angle)*Math.sin(angle/density) + this._center.y;
      path = path + " " + ["L", x, y].join(" ");
    }
    return path;
    
  },

}