<template>
	<g id="Thermocouple" :transform="transform">
		<text>put svg here</text>
	</g>
</template>


<script>

export default{
	props : ['scale', 'x' , 'y'],
	data : function(){
		return {

		};
	},
	computed : {
		transform : function(){
			return 'translate(' + this.x + ' ' + this.y + ') scale(' + this.scale/18 + ' ' + this.scale/18 + ')';
		},
	},
	methods : {
		polarToCartesian : function (centerX, centerY, radius, angleInDegrees) {
		  var angleInRadians = (angleInDegrees + 90) * Math.PI / 180.0;
		  return {
		    x: centerX + (radius * Math.cos(angleInRadians)),
		    y: centerY + (radius * Math.sin(angleInRadians))
		  };
		},
	}
}

</script>