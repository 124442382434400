<template>
	 <g id="ThreeProng" :transform="transform">
		<g>
			<path class="metal" d="M94.4-1.5c0-1.5-1.2-2.8-2.7-2.8v0H57.9v5.7h33.8v0C93.2,1.3,94.4,0,94.4-1.5z"/>
			<rect x="69.6" y="-12.4" class="metal" width="21.7" height="2.7"/>
			<rect x="69.6" y="6.7" class="metal" width="21.7" height="2.7"/>
		</g>
		<rect x="0" y="-5.4" class="black-plastic" width="22.8" height="9.8"/>
		<path class="black-plastic" d="M46.9-18.3c-4.7,5.6-14.8,9.5-26.5,9.5c-1.7,0-3.3-0.1-4.9-0.2V8.3c1.6-0.2,3.2-0.2,4.9-0.2
			c10.1,0,18.9,2.9,24.2,7.2h26.6v-33.5H46.9z"/>
		<path class="black-plastic" d="M45.9-1.4c0,4,3.1,7.2,7.1,7.4v0h18.1v-15H53v0C49.1-8.6,45.9-5.4,45.9-1.4z"/>
	</g>
</template>


<script>

import Accessory from './accessory-template.vue';

export default{
	extends: Accessory,
	computed : {
		transform : function(){
			return 'translate(' + this.x + ' ' + this.y + ') scale(' + this.scale/20 + ' ' + this.scale/20 + ')';
		}
	}
}

</script>