<template>
		<g id="HeaterStar" :transform="_transform">
      <defs>
        <clipPath id="clip-elements">
          <path id="arc1" :d="star_shape_poly" />
        </clipPath>
      </defs>
		  <circle :cx="center_x" :cy="center_y" :r="radius" fill="none" stroke-width="1" stroke="#aaaaaa" stroke-dasharray="4"/>
		  <circle :cx="center_x" :cy="center_y" :r="inner_radius" fill="none" stroke-width="1" stroke="#aaaaaa" stroke-dasharray="4"/>
	      
        <path id="arc1" :d="star_shape" class="flex-heater" />

	      <g id="HeaterStar-elements" clip-path="url(#clip-elements)">
	        <path :style="strokeStyle" class="heater-element" :d="element_path"/>
	      </g>
        

	      <acc-leads :cfg="cfg" :scale="scale" :x="0" :y="0"></acc-leads>
	      
        <g id="Dim-Diameter" :transform="dim_diameter_transform">
	        <polyline class="dim-bracket" :points="dim_diameter_left"/>
	        <polyline class="dim-bracket" :points="dim_diameter_right"/>
	        <text text-anchor="middle" :transform="dim_diameter_text">{{cfg.diameter.value}}”</text>
	      </g>
	      
	      <g id="Dim-Inner-Diameter">
	        <polyline class="dim-bracket" :points="dim_inner_diameter_left"/>
	        <polyline class="dim-bracket" :points="dim_inner_diameter_right"/>
	        <text text-anchor="middle" :transform="dim_inner_diameter_text">{{cfg.inner_diameter.value}}”</text>
	      </g>
	      
	    </g>
</template>


<script>

import Accessory from './accessory-template.vue';
import AccLeads from './acc-leads.vue';
import Star from '@/plugins/ShapeStar';

export default{
	extends: Accessory,
	props : ['cfg', 'strokeStyle', 'density'],
	data : function(){
		return {

		}
	},
	computed : {

      //returns simple variable for cfg that are already scaled. 
      diameter : function(){
        return this._scale(this.cfg.diameter.value);
      },
      inner_diameter : function(){
        return this._scale(this.cfg.inner_diameter.value);
      },
      radius : function(){
        return this._scale(Star.findRadius(this.cfg))
      },
      inner_radius : function(){
        return this._scale(Star.findInnerRadius(this.cfg))
      },
      inner_width : function(){
        return Star.findInnerWidth(this.cfg); 
      },
      center_x : function(){
        return this._scale(Star.findCenter(this.cfg).x)
      },
      center_y : function(){
        return this._scale(Star.findCenter(this.cfg).y)
      },
      half_sweep: function(){
        return Star.findHalfSweep(this.cfg)
      },



      //handles displaying all teh brackets and dimension labels in the drawing. 
      dim_diameter_text : function(){
        return 'matrix(1 0 0 1 ' + (this.center_x - 2) + ' -12 )';
      },
      dim_diameter_left : function(){
        return  0 + ',-5 ' + 0 + ',-15 ' + (this.center_x - 20) + ',-15';  
      },
      dim_diameter_right : function(){
        return  this.diameter + ',-5 ' + this.diameter + ',-15 ' + (this.center_x + 20) + ',-15';  
      },
      //adjusted the top diameter dimension label when donut dimension need placing too. 
      dim_diameter_transform : function(){
        return 'matrix(1 0 0 1 0 -20)';
      },
      dim_inner_diameter_left : function(){
        return  ((this.diameter - this.inner_diameter)/2) + ',-5 ' + ((this.diameter - this.inner_diameter)/2) + ',-15 ' + (this.center_x - 20) + ',-15';  
      },
      dim_inner_diameter_right : function(){
        return  ((this.diameter / 2) + (this.inner_diameter/2)) + ',-5 ' + ((this.diameter / 2) + (this.inner_diameter/2)) + ',-15 ' + (this.center_x + 20) + ',-15';  
      },
      dim_inner_diameter_text : function(){
        return 'matrix(1 0 0 1 ' + (this.center_x - 2) + ' -12 )';
      },
      dim_inner_width_text : function(){
      	var diff_radius_half = (this.radius - this.inner_radius) / 2 + this.inner_radius-4;
      	var p = this.polarToCartesian(this.center_x, this.center_y, diff_radius_half, -this.half_sweep - 8);
        return 'matrix(1 0 0 1 ' + p.x + ' ' + p.y + ' )';
      },
      


      bracket_inner_width : function(){
      	var p = this.polarToCartesian(this.center_x, this.center_y, this.radius, -this.half_sweep - 4);
      	var path = ["M", p.x, p.y].join(" ");

      	p = this.polarToCartesian(this.center_x, this.center_y, this.radius, -this.half_sweep - 8);
      	path = path + ' ' + ["L", p.x, p.y].join(" ");

      	var diff_radius_half = (this.radius - this.inner_radius) / 2 + this.inner_radius + 14;
      	p = this.polarToCartesian(this.center_x, this.center_y, diff_radius_half, -this.half_sweep - 8);
      	path = path + ' ' + ["L", p.x, p.y].join(" ");

      	diff_radius_half = (this.radius - this.inner_radius) / 2 + this.inner_radius - 14;
      	p = this.polarToCartesian(this.center_x, this.center_y, diff_radius_half, -this.half_sweep - 8);
      	path = path + ' ' + ["M", p.x, p.y].join(" ");
      	
      	p = this.polarToCartesian(this.center_x, this.center_y, this.inner_radius, -this.half_sweep - 8);
      	path = path + ' ' + ["L", p.x, p.y].join(" ");

      	p = this.polarToCartesian(this.center_x, this.center_y, this.inner_radius, -this.half_sweep - 4);
      	path = path + ' ' + ["L", p.x, p.y].join(" ");

      	return path;
      },

      //element 
      element_path : function(){
        return Star.element_path(this.cfg, this.scale, this.density)
      },

      star_shape : function(){
        return Star.star_shape(this.cfg, this.scale, this.density);
      },

      star_shape_poly : function(){
        return Star.star_shape_poly(this.cfg, this.scale, this.density);
      },

      watt_density : function(){
        //var area = Star.findArea(this.cfg);
        //return this.cfg.watts.value / area;
      },

      _transform : function(){
      	return;
      	return "translate(" + this.radius + ", 0)";
      },
      
	},
	methods : {
		_scale : function(v){
	        return v * this.scale;
	    },

    inner_points : function(){
      return Star.inner_points(true, this.cfg, this.scale);

        return pts
    },
    outer_points : function(){
      return Star.outer_points(true, this.cfg, this.scale);
    },

	  
	},
	components : {
		AccLeads
	}
}

</script>


<style type="text/css">
  text {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: .8em;
  }
  .dim-bracket{fill:none;stroke:#999999;stroke-miterlimit:10;}
</style>