<template>
    <g id="HeaterRectangle">
    	<mask id="elementmask">
	    	<path :d="heater_mask" fill="white" />
  		</mask>	
        <path fill-rule="even-odd" class="flex-heater" :d="heater_path" />
        <g id="HeaterRectangle-elements" mask="url(#elementmask)">
            <path :style="strokeStyle" class="heater-element" :d="element_path" />
        </g>
        <acc-leads :cfg="cfg" :scale="scale" :x="0" :y="0"></acc-leads>
        <g id="Dim-Height">
            <polyline class="dim-bracket" :points="dim_height_top" />
            <polyline class="dim-bracket" :points="dim_height_bottom" />
            <text :transform="dim_height_text">{{cfg.height.value}}”</text>
        </g>
        <g id="Dim-Width">
            <polyline class="dim-bracket" :points="dim_width_left" />
            <polyline class="dim-bracket" :points="dim_width_right" />
            <text :transform="dim_width_text">{{cfg.width.value}}”</text>
        </g>
    </g>
</template>

<script>

import Accessory from './accessory-template.vue';
import AccLeads from './acc-leads.vue';

export default{
	extends: Accessory,
	props : ['cfg', 'strokeStyle', 'density', 'strokeWidth'],
	data : function(){
		return {

		}
	},
	computed : {

		heater_path :function(inner_offset){
			this.check_design();
			return this.make_heater_path(0);
		},

		heater_mask :function(){
			if (this.cfg.material.value == "mica"){
				console.log("is mica")
				return this.make_heater_path(.25, .25);
			}
			return this.make_heater_path(.25);
		},

		//element 
      	element_path : function(){
	        if(this.cfg.watt_density.value > 60){
	          return '';
	        }
	        //if(this.flexCalc.distance_Between_Traces.value < .035){
	        //  return '';
	        //}
	        if (this.cfg.material.value == "mica"){
	            var margin = -this._scale(.05);
	        }else{
	          margin = this._scale(.25);
	        }
	        
	        var density = this.cfg.material.value == "mica" ? this.strokeWidth/4 : this.strokeWidth/5; //1 - (this.cfg.watt_density.value / 20);
	          var x = (this._left + (margin));
	          if (this.cfg.material.value == "mica"){
	            x = x + this._scale(.5);
	          }
	          var y = (this._top + (margin))
	          var p = 'M' + x + ' ' + y;
	          var i;
	          var c = (this._scale(this.cfg.width.value) - (margin * 2)) / (this._scale(density) / 2);
	          var h = this._scale(density);
	          for (i = 0; i < c; i++){
	            //bottom curve
	            if (x + margin > this._scale(this.cfg.width.value)) break;
	            y = this._scale(this.cfg.height.value) - (margin * 2);
	            if (this.cfg.material.value == "mica"){
	              x = x + this._scale(density)
	            }
	            p = p + this.lineTo(x, y);
	            if (x + this._scale(density) >= this._scale(this.cfg.width.value)) break;
	            if (this.cfg.material.value == "mica"){
	              p = p + 'M ' + (x + this._scale(density)) + ' ' + y;
	            }else{
	              p = p + this.curveTo(x, y, x + this._scale(density), y, h);
	            }
	            x = x + this._scale(density);
	            if (this.cfg.material.value == "mica"){
	              x = x - this._scale(density)
	            }
	            y = this._scale(this._top) + (margin * 2);
	            if (x + this._scale(density) >= this._scale(this.cfg.width.value)) break;
	            p = p + this.lineTo(x, y);
	            //top curve
	            if (x + margin + this._scale(density) >= this._scale(this.cfg.width.value)) break;
	            if (this.cfg.material.value == "mica"){
	              if (x + margin + this._scale(density * 2) >= this._scale(this.cfg.width.value)) break;
	              p = p + 'M ' + (x + this._scale(density)) + ' ' + y;
	            }else{
	              p = p + this.curveTo(x, y, x + this._scale(density), y, -h);
	            }
	            
	            x = x + this._scale(density);
	          }
          return p;
	    
      	},

      //left side height dim bars
      dim_height_bottom : function(){
        return '-10, ' + this._bottom + ' -15,' + this._bottom + ' -15,' + (this._center_height + 20);  
      },
      dim_height_top : function(){
        return '-10, ' + this._top + ' -15,' + this._top + ' -15,' + (this._center_height - 20);  
      },
      dim_height_text : function(){
        return 'matrix(1 0 0 1 -30 ' + (this._center_height + 3) + ')';
      },

      //top side width dim bars
      dim_width_left : function(){
        return  this._left + ',-5 ' + this._left + ',-15 ' + (this._center_width - 20) + ',-15';  
      },
      dim_width_right : function(){
        return  this._right + ',-5 ' + this._right + ',-15 ' + (this._center_width + 20) + ',-15';  
      },
      dim_width_text : function(){
        return 'matrix(1 0 0 1 ' + (this._center_width - 7) + ' -12 )';
      },


      //general purpose reference points
      _top : function(){
        return 0
      },
      _bottom : function(){
        return this._scale(this.cfg.height.value)
      },
      _left : function(){
        return 0
      },
      _right : function(){
        return this._scale(this.cfg.width.value)
      },
      _center_height : function(){
        return this._scale(this.cfg.height.value / 2);
      },
      _center_width : function(){
        return this._scale(this.cfg.width.value / 2);
      },

      

      watt_density : function(){
          radius = this.cfg.diameter.value/2;
          var inner_radius = this.cfg.inner_diameter.value/2;
          var circle_area = (Math.PI * (radius * radius) );
          var hole_area = (Math.PI * (inner_radius * inner_radius) )
          return this.cfg.watts.value / ((circle_area - hole_area) * (this.cfg.cone_sweep.value / 360));
      },

      

      _transform : function(){
      	return;
      	return "translate(" + this.radius + ", 0)";
      },
      trapezoid_shape : function(){
	      	var path = ["M", this.bottom_left.x, this.bottom_left.y].join(" ");
	      	path = path + [" L", this.top_left.x, this.top_left.y].join(" ");
	      	path = path + [" L", this.top_right.x, this.top_right.y].join(" ");
	      	path = path + [" L", this.bottom_right.x, this.bottom_right.y].join(" ");
	      	return path + " Z";
	   },
	},
	methods : {
		_scale : function(v){
	        return v * this.scale;
	    },
	    lineTo : function(x, y){
	        return ' L' + x + ' ' + y;
	      },
	      curveTo : function(x, y, x2, y2, h){ // pass negative h for top curve and positive h for bottom curve.
	        return ' L' + x2 + ' ' + y2;
	        return ' C' + x + ' ' + (y + h) + ' ' + x2 + ' ' + (y2 + h) + ' ' + x2 + ' ' + y2;
	      },
	    arcTo : function(x, y, largearc, sweep){
	    	if(typeof largearc == 'undefined'){
	    		largearc = 0
	    	}
	    	if(typeof sweep == 'undefined'){
	    		sweep = 1
	    	}
	        return 'a' + x + ',' + y + ' 0 ' + largearc + ' ' + sweep + ' ' + x + ',' + y + '';
	      },

	    make_heater_path : function(inner_offset, outer_offset){
	    	if(typeof inner_offset == 'undefined'){
				inner_offset = 0;
			}
			if(typeof outer_offset == 'undefined'){
				outer_offset = 0;
			}
			var p = 'M' + this._scale(.25 - outer_offset) + ' ' + this._scale(-outer_offset);
			p = p + this.lineTo(this._scale(this.cfg.width.value + outer_offset - .25), this._scale(-outer_offset));
			p = p + this.arcTo(this._scale(.25), this._scale(.25));
			p = p + this.lineTo(this._scale(this.cfg.width.value + outer_offset), this._scale(this.cfg.height.value + outer_offset - .25));
			p = p + this.arcTo(-this._scale(.25), this._scale(.25));
			p = p + this.lineTo(this._scale(.25 - outer_offset), this._scale(this.cfg.height.value + outer_offset));
			p = p + this.arcTo(this._scale(-.25), this._scale(-.25));
			p = p + this.lineTo(-outer_offset, this._scale(.25 - outer_offset));
			p = p + this.arcTo(this._scale(.25), this._scale(-.25));
			p = p + "z "; 
			if (this.cfg.hole_offset.value > 0){
				if (this.cfg.material.value == 'mica'){
					var offset = this.cfg.hole_offset.value + inner_offset;
				}else{
					offset = this.cfg.hole_offset.value - inner_offset;
				}
				p = p + 'M' + this._scale(.25 + offset) + ' ' + this._scale(offset);
				p = p + this.arcTo(this._scale(-.25), this._scale(.25), 0, 0);
				p = p + this.lineTo(this._scale(offset), this._scale(-.25 + this.cfg.height.value - offset));
				p = p + this.arcTo(this._scale(.25), this._scale(.25), 0, 0);
				p = p + this.lineTo(this._scale(this.cfg.width.value -.25 - offset), this._scale(this.cfg.height.value - offset));
				p = p + this.arcTo(this._scale(.25), this._scale(-.25), 0, 0);
				p = p + this.lineTo(this._scale(this.cfg.width.value - offset), this._scale(.25 + offset));
				p = p + this.arcTo(this._scale(-.25), this._scale(-.25), 0, 0);
				p = p + this.lineTo(this._scale(this.cfg.width.value - .25 - offset), this._scale(offset) );
			}
			return p + "z";
	    },

	    check_design : function(){
	    	if (this.cfg.hole_offset.value * 2 >= this.cfg.width.value || this.cfg.hole_offset.value * 2 >= this.cfg.height.value ){
	    		this.cfg.hole_offset.value = this.cfg.width.value / 2 - .25;
	    	}
	    },

	    find_point : function(x1,y1,x2,y2,i,d){
			var r = (d * i) / (y2-y1);
			var x3 = x1 + ((x2-x1) * r)
			var y3 = y1 + (d * i)
			return{"x" : x3, "y" : y3};
	      },
	    polarToCartesian : function (centerX, centerY, radius, angleInDegrees) {
		  var angleInRadians = (angleInDegrees + 90) * Math.PI / 180.0;
		  return {
		    x: centerX + (radius * Math.cos(angleInRadians)),
		    y: centerY + (radius * Math.sin(angleInRadians))
		  };
		},
		describeArc : function (x, y, radius, startAngle, endAngle){

		    var start = this.polarToCartesian(x, y, radius, endAngle);
		    var end = this.polarToCartesian(x, y, radius, startAngle);

		    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

		    var d = ["A", radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(" ");

		    return d;       
		},
		describeReverseArc : function (x, y, radius, startAngle, endAngle){

		    var start = this.polarToCartesian(x, y, radius, startAngle);
		    var end = this.polarToCartesian(x, y, radius, endAngle);

		    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

		    var d = ["A", radius, radius, 0, largeArcFlag, 1, end.x, end.y].join(" ");

		    return d;       
		},
		topBracketPath : function(x1, y1, x2, y2){
			if (y1 > 0 || y2 > 0){
				var offset = 10;
			}else{
				offset = -10
			}
	      	var path = ["M", x1, y1].join(" ");
	      	path = path + ' ' + ["L", x1, y1 + offset].join(" ");
	      	path = path + ' ' + ["L", ((x2 - x1)/2) - 15 + x1 , y1 + offset].join(" ");
	      	path = path + ' ' + ["M", ((x2 - x1)/2) + 15 + x1 , y1 + offset].join(" ");
	      	path = path + ' ' + ["L", x2 , y2  + offset].join(" ");
	      	path = path + ' ' + ["L", x2 , y2].join(" ");
	      	return path;
		},
		leftBracketPath : function(x1, y1, x2, y2){
			if (x1 > 0 || x2 > 0){
				var offset = 10;
			}else{
				offset = -10
			}
	      	var path = ["M", x1, y1].join(" ");
	      	path = path + ' ' + ["L", x1 + offset, y1].join(" ");
	      	path = path + ' ' + ["L", x1 + offset, ((y2 - y1)/2) - 15].join(" ");
	      	path = path + ' ' + ["M", x1 + offset, ((y2 - y1)/2) + 15].join(" ");
	      	path = path + ' ' + ["L", x2 + offset, y2].join(" ");
	      	path = path + ' ' + ["L", x2 , y2].join(" ");
	      	return path;
		}
	},
	components : {
		AccLeads
	}
}

</script>
<style type="text/css">
text {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: .8em;
}

.dim-bracket {
    fill: none;
    stroke: #999999;
    stroke-miterlimit: 10;
}
</style>