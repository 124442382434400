import request from 'superagent';

export default {

	postFields : [],
  images : [],

	//pass an object with key value fields specific for this account. 
	init : function(hidden){
		this.postFields = hidden;
		return this;
	},

  attachImages : function(imgs){
    if (imgs.length > 0){
      this.images = imgs;
    }
    return this;
  },

	send : function(cb){
      var g = this;
      
      var req = request.post('https://crm.zoho.com/crm/WebToLeadForm');

      if (this.images.length > 0){
        //for(var i=0; i < this.images.length; i++){
          console.log(typeof this.images[0].imageFile);
          if (typeof this.images[0].imageFile != 'undefined'){
            req.attach('theFile', this.images[0].imageFile);
          }
        //}
        var g = this;
        Object.keys(this.postFields).forEach(function(k){
          req.field(k, g.postFields[k])
        })
      }else{

        req.send(this.postFields)
          .type('form');
      }
      req.then(function(){
            cb(true, 'Complete');
          }, function(){
            if (typeof ga != 'undefined'){
              ga('send', 'event', 'quote-request', 'send-error-onzoho', 'flexible-heater');
            }
            cb(false, 'Error, could not submit your request.');
            document.documentElement.scrollTop = 200;
          })
    },
}