<template>
	<g id="Straps" :transform="transform2">

	  <rect v-for="n in num_straps" :key="n" :x="_scale(rect(n)[0])" :y="_scale(rect(n)[1])" class="flex-straps" :width="_scale(rect(n)[2])" :height="_scale(rect(n)[3])"/>
      <rect v-for="n in num_straps" :key="n + '2'" :x="_scale(rect2(n)[0])" :y="_scale(rect2(n)[1])" class="flex-straps" :width="_scale(rect2(n)[2])" :height="_scale(rect2(n)[3])"/>

    </g>
</template>


<script>

import Accessory from './accessory-template.vue';

export default{
	extends: Accessory,
	props : ['cfg'],
	data : function(){
		return {
			spacing : 8
		}
	},
	computed : {
		transform2 : function(){
			return '';
		},
		num_straps : function(){
			if(this.cfg.shape.value == 'trapezoid'){
				return 1;
			}
			if (this.cfg.shape.value == 'rectangle' || this.cfg.shape.value == 'trapezoid'){
				switch(this.cfg.attachment.options){
					case 'Top to Bottom':
						return Math.ceil((this.cfg.width.value - 2) / this.spacing);
						break;
					case 'Left to Right':
						return Math.ceil((this.cfg.height.value - 2) / this.spacing);
						break;
				}
			}else{
				switch(this.cfg.attachment.options){
					case 'Top to Bottom':
						return Math.ceil((this.cfg.diameter.value - 2) / this.spacing);
						break;
					case 'Left to Right':
						return Math.ceil((this.cfg.diameter.value - 2) / this.spacing);
						break;
				}
			}
		},
		//location of the square terminal block on the rubber heater. 
		

	},
	methods : {
		_scale: function(v){
			return this.scale * v
		},
		lineTo : function(x, y){
			return ' L' + x + ' ' + y;
		},
		rect : function(n){
			var m = (this.num_straps - 1) * this.spacing;
			if (this.cfg.shape.value == 'rectangle' || this.cfg.shape.value == 'trapezoid'){
				switch(this.cfg.attachment.options){
					case 'Top to Bottom':
						var offset = ((this.cfg.width.value - m) / 2) + ((n - 1) * this.spacing) - .5
						return [
							offset, //x
							-5, //y
							1, //x2
							6 //y2
						];
						break;
					case 'Left to Right':
						offset = ((this.cfg.height.value - m) / 2) + ((n - 1) * this.spacing) - .5
						return [
							- 5,	//x
							offset,	//y
							6,	//x2
							1	//y2
						];
						break;
				}
			}else{
				switch(this.cfg.attachment.options){
					case 'Top to Bottom':
						offset = ((this.cfg.diameter.value - m) / 2) + ((n - 1) * this.spacing) - .5
						return [
							offset, //x
							-3, //y
							1, //x2
							this.cfg.diameter.value + 7 //y2
						];
						break;
					case 'Left to Right':
						offset = ((this.cfg.diameter.value - m) / 2) + ((n - 1) * this.spacing) - .5
						return [
							- 3,	//x
							offset,	//y
							this.cfg.diameter.value + 7,	//x2
							1	//y2
						];
						break;
				}
			}
			return [0, 0, 0, 0];
		},
		rect2 : function(n){
			var m = (this.num_straps - 1) * this.spacing;
			if (this.cfg.shape.value == 'rectangle' || this.cfg.shape.value == 'trapezoid'){
				switch(this.cfg.attachment.options){
					case 'Top to Bottom':
						var offset = ((this.cfg.width.value - m) / 2) + ((n - 1) * this.spacing) - .5
						return [
							offset, //x
							this.cfg.height.value - 1, //y
							1, //x2
							5 //y2
						];
						break;
					case 'Left to Right':
						offset = ((this.cfg.height.value - m) / 2) + ((n - 1) * this.spacing) - .5
						return [
							this.cfg.width.value - 1, //y,	//x
							offset,	//y
							this.cfg.width.value + 6, //y,	//x2
							1	//y2
						];
						break;
				}
			}else{
				switch(this.cfg.attachment.options){
					case 'Top to Bottom':
						offset = ((this.cfg.diameter.value - m) / 2) + ((n - 1) * this.spacing) - .5
						return [
							offset, //x
							-3, //y
							1, //x2
							this.cfg.diameter.value + 7 //y2
						];
						break;
					case 'Left to Right':
						offset = ((this.cfg.diameter.value - m) / 2) + ((n - 1) * this.spacing) - .5
						return [
							- 3,	//x
							offset,	//y
							this.cfg.diameter.value + 7,	//x2
							1	//y2
						];
						break;
				}
			}
			return [0, 0, 0, 0];
		},
	}
}

</script>


<style type="text/css">
  text {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: .8em;
  }
  .flex-straps{fill:#EFCFC7;stroke:#999999;stroke-miterlimit:10;}
  .bare-lead{fill:none;stroke:#888888;stroke-miterlimit:10;stroke-width:1px;}
  .black-rubber{fill:none;stroke:#000000;stroke-miterlimit:10;stroke-width:3px;}
  .orange-rubber{fill:none;stroke:#AD1F05;stroke-miterlimit:10;stroke-width:1.5px;}
  .white-rubber{fill:none;stroke:#ffffff;stroke-miterlimit:10;stroke-width:1.5px;}
  .red-teflon{fill:none;stroke:#999999;stroke-miterlimit:10;stroke-width:1.5px;stroke-dasharray: 2;}
  .blue-teflon{fill:none;stroke:#ffff00;stroke-miterlimit:10;stroke-width:1.5px;stroke-dasharray: 5,2;}
</style>