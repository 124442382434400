<template>
	<g id="Leads" :transform="transform2">

	  <path v-if="cfg.leads.location" class="bare-lead" :d="lead_end_a"/>
      <path v-if="cfg.leads.location" class="black-rubber" :d="lead_a"/>
      <path v-if="cfg.leads.location" :class="leadClass" :d="lead_a"/>
      <g :transform="'translate(' + rect_translate + ') rotate(' + rect_rotation[0] + ')'">
      	<rect :x="_scale(rect_xy[0])" :y="_scale(rect_xy[1])" class="flex-heater lead-cover" width="20" height="20"/>
      </g>
      <path v-if="cfg.leads.location" class="bare-lead" :d="lead_end_b"/>
      <path v-if="cfg.leads.location" class="black-rubber" :d="lead_b"/>
      <path v-if="cfg.leads.location" :class="leadClass" :d="lead_b"/>
      <g :transform="'rotate(' + (rect_rotation[1]) + ')'">
      	<rect :x="_scale(rect_xy[2])" :y="_scale(rect_xy[3])" class="flex-heater lead-cover" width="20" height="20"/>
      </g>
      
      <text :transform="leadLengthLabelTransform" v-if="cfg.leads.length.value != 'Other'">{{cfg.leads.length.value}} Leads</text>
      <text v-else :transform="leadLengthLabelTransform">{{cfg.leads.length.options}} Leads</text>
    </g>
</template>


<script>

import Accessory from './accessory-template.vue';
import Geometry from '@/plugins/Geometry';

export default{
	extends: Accessory,
	props : ['cfg'],
	computed : {
		transform2 : function(){
			return '';
		},
		rect_translate : function(){
			if (this.cfg.shape.value == 'star' && this.cfg.leads.location.value == 'Opposite'){
				return this._scale(this.radius) + ", " + this._scale(this.radius);
			}
			if (this.cfg.shape.value == 'star' && this.cfg.leads.location.value == 'Inside'){
				return this._scale(-.85) + ", " + this._scale(-.85);
			}
			return"0, 0";
		},
		rect_rotation : function(){
			if (this.cfg.shape.value == 'cone'){
				switch(this.cfg.leads.location.value){
					case 'Left':
						pivot = ' ' + (this._scale(this.rect_xy[0])) + ' ' + (this._scale(this.rect_xy[1]))
						return [this.half_sweep - 90 + pivot, this.half_sweep - 90 + pivot];
					case 'Right':
						pivot = ' ' + (this._scale(this.rect_xy[0])) + ' ' + (this._scale(this.rect_xy[1]))
						return [-this.half_sweep + 90 + pivot, -this.half_sweep + 90 + pivot];
					case 'Split':
						var pivot_l = ' ' + (this._scale(this.rect_xy[0])) + ' ' + (this._scale(this.rect_xy[1]))
						var pivot_r = ' ' + (this._scale(this.rect_xy[2])) + ' ' + (this._scale(this.rect_xy[3]))
						return [this.half_sweep - 90 + pivot_l, -this.half_sweep + 90 + pivot_r];
				}
			}
			if (this.cfg.shape.value == 'star'){
				switch(this.cfg.leads.location.value){
					case 'Opposite':
						if (Math.abs(this.cfg.num_sides.value % 2) == 1){
							var angle = Geometry.getPerimeterAngle(Math.round(this.cfg.num_sides.value / 2) -1, this.radius, this.cfg.num_sides.value, false) + 90
						}else{
							angle = 270
						}
						return [angle, 0];
						break;
				}
			}
			if (this.cfg.shape.value == 'trapezoid'){
				switch(this.cfg.leads.location.value){
					case 'Left':
						var angle_r = this.getAngle(this.trap_top_left.x, this.trap_top_left.y, this.trap_bottom_left.x, this.trap_bottom_left.y);
					 	var angle_d = angle_r * 180/Math.PI;
						var pivot = ' ' + (this._scale(this.rect_xy[0])) + ' ' + (this._scale(this.rect_xy[1]))
						return [-angle_d + pivot, -angle_d + pivot];
					case 'Right':
						var angle_r = this.getAngle(this.trap_top_right.x, this.trap_top_right.y, this.trap_bottom_right.x, this.trap_bottom_right.y);
					 	var angle_d = angle_r * 180/Math.PI + 180;
						pivot = ' ' + (this._scale(this.rect_xy[0])) + ' ' + (this._scale(this.rect_xy[1]))
						return [-angle_d + pivot, -angle_d + pivot];
					case 'Split':
						var angle_r = this.getAngle(this.trap_top_right.x, this.trap_top_right.y, this.trap_bottom_right.x, this.trap_bottom_right.y);
					 	var angle_d_r = angle_r * 180/Math.PI + 180;
						angle_r = this.getAngle(this.trap_top_left.x, this.trap_top_left.y, this.trap_bottom_left.x, this.trap_bottom_left.y);
					 	var angle_d_l = angle_r * 180/Math.PI;
						var pivot_l = ' ' + (this._scale(this.rect_xy[0])) + ' ' + (this._scale(this.rect_xy[1]))
						var pivot_r = ' ' + (this._scale(this.rect_xy[2])) + ' ' + (this._scale(this.rect_xy[3]))
						return [-angle_d_l + pivot_l, -angle_d_r + pivot_r];
				}
			}
			return [0,0];
		},
		//location of the square terminal block on the rubber heater. 
		rect_xy : function(){
			if (this.cfg.shape.value == 'rectangle'){
				switch(this.cfg.leads.location.value){
					case 'Standard':
						return [
							this.cfg.width.value/2 - .85, //x
							this.cfg.height.value - 1.7, //y
							this.cfg.width.value/2 - .85, //x2
							this.cfg.height.value - 1.7 //y2
						];
						break;
					case 'Left':
						return [
							0,	//x
							this.cfg.height.value > 8 ? this.cfg.height.value/2 + 1.7 : this.cfg.height.value - 2.7,	//y
							0,	//x2
							this.cfg.height.value > 8 ? this.cfg.height.value/2 + 1.7 : this.cfg.height.value - 2.7	//y2
						];
						break;
					case 'Split':
						return [
							0,	//x
							this.cfg.height.value - 1.7,	//y
							0,	//x2
							0	//y2
						];
						break;
					case 'Top':
						return [
							this.cfg.width.value/2 - 1.85, //x
							0,	//y
							this.cfg.width.value/2 - 1.85, //x2
							0	//y2
						];
						break;
					case 'Right':
						return [
							this.cfg.width.value - 1.7,	//x
							this.cfg.height.value > 8 ? this.cfg.height.value/2 - .85  : this.cfg.height.value - 2.7,	//y
							this.cfg.width.value - 1.7,	//x2
							this.cfg.height.value > 8 ? this.cfg.height.value/2 - .85 : this.cfg.height.value - 2.7	//y2
						];
						break;
					case 'Inside':
						if (this.cfg.hole_offset.value > 0){
							console.log("l")
							return [
								this.cfg.width.value/2 - 1.7,	//x
								(this.cfg.height.value - (this.cfg.hole_offset.value / 2)) - .9,	//y
								this.cfg.width.value/2 - 1.7,	//x2
								(this.cfg.height.value - (this.cfg.hole_offset.value / 2)) - .9,	//y2
							];
						}
						return [
							this.cfg.width.value/2 - 1.7,	//x
							this.cfg.height.value/2 + 1.85,	//y
							this.cfg.width.value/2 - 1.7,	//x2
							this.cfg.height.value/2 + 1.85,	//y2
						];
						break;
					case 'Opposite':
						return [
							this.cfg.width.value/2 - 1.85, //x
							0,	//y
							this.cfg.width.value/2 - 1.85, //x2
							this.cfg.height.value - 1.7 //y2
						];
						break;
				}
			}else if (this.cfg.shape.value == 'cone'){
				switch(this.cfg.leads.location.value){
					case 'Standard':
						return [
							this.cfg.diameter.value/2 - .85, //x
							this.cfg.diameter.value - 1.7, //y
							this.cfg.diameter.value/2 - .85, //x2
							this.cfg.diameter.value - 1.7 //y2
						];
						break;
					case 'Left':
						var diff_radius_half = (this.radius - this.inner_radius) / 2 + this.inner_radius + .5;
				      	var p = this.polarToCartesian(this.center_x, this.center_y, diff_radius_half, this.half_sweep);
						return [
							p.x,	//x
							p.y,	//y
							p.x,	//x2
							p.y	//y2
						];
						break;
					case 'Right':
						var diff_radius_half = (this.radius - this.inner_radius) / 2 + this.inner_radius - .5;
				      	var p = this.polarToCartesian(this.center_x, this.center_y, diff_radius_half, -(this.half_sweep));
						return [
							p.x,	//x
							p.y,	//y
							p.x,	//x2
							p.y	//y2
						];
						break;
					case 'Top':
						return [
							this.center_x - .85, //x
							this.center_y + this.inner_radius, //y
							this.center_x - .85, //x2
							this.center_y + this.inner_radius //y2
						];
						break;
					case 'Inside':
						diff_radius_half = (this.radius - this.inner_radius) / 2 + this.inner_radius + .5;
				      	p = this.polarToCartesian(this.center_x, this.center_y, diff_radius_half, this.half_sweep/2);
						return [
							p.x,	//x
							p.y,	//y
							p.x,	//x2
							p.y	//y2
						];
						break;
					case 'Opposite':
						return [
							this.cfg.diameter.value/2 - .85, //x
							this.cfg.diameter.value - 1.7, //y
							this.center_x - .85, //x2
							this.center_y + this.inner_radius //y2
						];
						break;
					case 'Split':
						var diff_radius_half = (this.radius - this.inner_radius) / 2 + this.inner_radius;
						//var offset = this.half_sweep * .25
				      	var p_r = this.polarToCartesian(this.center_x, this.center_y, diff_radius_half - .5, -(this.half_sweep));
				      	var p_l = this.polarToCartesian(this.center_x, this.center_y, diff_radius_half + .5, this.half_sweep);
						return [
							p_l.x,	//x
							p_l.y,	//y
							p_r.x,	//x2
							p_r.y	//y2
						];
						break;
				}
			}else if (this.cfg.shape.value == 'star'){
				switch(this.cfg.leads.location.value){
					case 'Standard':
						return [
							this.cfg.diameter.value/2 - .85, //x
							this.cfg.diameter.value - 1.7, //y
							this.cfg.diameter.value/2 - .85, //x2
							this.cfg.diameter.value - 1.7 //y2
						];
						break;
					case 'Inside':
						diff_radius_half = (this.radius - this.inner_radius) / 2 + this.inner_radius + .5;
				      	p = this.polarToCartesian(this.center_x, this.center_y, diff_radius_half, this.half_sweep/2);
						return [
							this.center_x,	//x
							this.center_y,	//y
							this.center_x - .85,	//x2
							this.center_y - .85	//y2
						];
						break;
					case 'Opposite':
						return [
							this.cfg.diameter.value/2 - 1.7, //x2
							-.85, //y2/y2
							this.cfg.diameter.value/2 - .85, //x
							this.cfg.diameter.value - 1.7, //y
						];
						break;
				}
			}else if (this.cfg.shape.value == 'trapezoid'){
				switch(this.cfg.leads.location.value){
					case 'Standard':
						var offset = this.cfg.offset.value < 0 ? Math.abs(this.cfg.offset.value) : 0;
						if (this.cfg.shape.options.type == "isosceles"){
							if (this.cfg.width.value < this.cfg.top_width.value){
								offset = (this.cfg.top_width.value - this.cfg.width.value)/2;
							}else{
								offset = 0;
							}
						}
						return [
							this.cfg.width.value/2 + offset - .85, //x
							this.cfg.height.value - 1.7, //y
							this.cfg.width.value/2 + offset - .85, //x
							this.cfg.height.value - 1.7, //y
						];
						break;
					case 'Left':
				      	var p = this.find_point(this.trap_top_left.x, this.trap_top_left.y, this.trap_bottom_left.x, this.trap_bottom_left.y, .5)
						return [
							p.x,	//x
							p.y,	//y
							p.x,	//x2
							p.y	//y2
						];
						break;
					case 'Right':
						var p = this.find_point(this.trap_top_right.x, this.trap_top_right.y, this.trap_bottom_right.x, this.trap_bottom_right.y, .5)
						return [
							p.x,	//x
							p.y,	//y
							p.x,	//x2
							p.y	//y2
						];
						break;
					case 'Top':
						var offset = this.cfg.offset.value > 0 ? Math.abs(this.cfg.offset.value) : 0;
						if (this.cfg.shape.options.type == "isosceles"){
							if (this.cfg.width.value > this.cfg.top_width.value){
								offset = (this.cfg.width.value - this.cfg.top_width.value)/2;
							}else{
								offset = 0;
							}
						}
						return [
							this.cfg.top_width.value/2 + offset - .85, //x
							0, //y
							this.cfg.top_width.value/2 + offset - .85, //x
							0, //y
						];
						break;
					case 'Inside':
						var top_mid = this.trap_top_left.x + (this.cfg.top_width.value/2) - 2.85;
						var bottom_mid = this.trap_bottom_left.x + (this.cfg.width.value/2) - 2.85;
						var p = this.find_point(top_mid, 0, bottom_mid, this.cfg.height.value - 1, .5)
						if (this.cfg.shape.options.type == "isosceles"){
							if (this.cfg.width.value > this.cfg.top_width.value){
								p.x = (this.cfg.width.value/2) - 2.85;
							}else{
								p.x = (this.cfg.top_width.value/2) - 2.85;
							}
							p.y = (this.cfg.height.value/2) + .85
						}
						return [
							p.x, //x
							p.y, //y
							p.x, //x
							p.y, //y
						];
						break;
					case 'Opposite':
						var offset = this.cfg.offset.value < 0 ? Math.abs(this.cfg.offset.value) : 0;
						var t_offset = this.cfg.offset.value > 0 ? Math.abs(this.cfg.offset.value) : 0;
						if (this.cfg.shape.options.type == "isosceles"){
							if (this.cfg.width.value < this.cfg.top_width.value){
								offset = (this.cfg.top_width.value - this.cfg.width.value)/2;
								t_offset = 0;
							}else{
								t_offset = (this.cfg.width.value - this.cfg.top_width.value)/2;
								offset = 0;
							}
						}
						return [
							this.cfg.top_width.value/2 + t_offset - .85, //x
							0, //y
							this.cfg.width.value/2 + offset - .85, //x
							this.cfg.height.value - 1.7, //y2
						];
						break;
					case 'Split':
						//var offset = this.half_sweep * .25
				      	var p_r = this.find_point(this.trap_top_right.x, this.trap_top_right.y, this.trap_bottom_right.x, this.trap_bottom_right.y, .5)
				      	var p_l = this.find_point(this.trap_top_left.x, this.trap_top_left.y, this.trap_bottom_left.x, this.trap_bottom_left.y, .5)
						return [
							p_l.x,	//x
							p_l.y,	//y
							p_r.x,	//x2
							p_r.y	//y2
						];
						break;
				}
			}else{
				switch(this.cfg.leads.location.value){
					case 'Standard':
						return [
							this.cfg.diameter.value/2 - .85, //x
							this.cfg.diameter.value - 1.7, //y
							this.cfg.diameter.value/2 - .85, //x2
							this.cfg.diameter.value - 1.7 //y2
						];
						break;
					case 'Left':
						return [
							0,	//x
							(this.cfg.diameter.value/2) - .85,	//y
							0,	//x2
							(this.cfg.diameter.value/2) - .85	//y2
						];
						break;
					case 'Right':
						return [
							this.cfg.diameter.value - 1.7,	//x
							(this.cfg.diameter.value/2) - .85,	//y
							this.cfg.diameter.value - 1.7,	//x2
							(this.cfg.diameter.value/2) - .85	//y2
						];
						break;
					case 'Top':
						return [
							this.cfg.diameter.value/2 - .85, //x
							0, //y
							this.cfg.diameter.value/2 - .85, //x2
							0 //y2
						];
						break;
					case 'Inside':
						if (this.cfg.shape.value == 'donut'){
							return [
								this.cfg.diameter.value/2 - 1, //x
								this.cfg.diameter.value/2 + this.cfg.inner_diameter.value/2, //y
								this.cfg.diameter.value/2 - 1, //x2
								this.cfg.diameter.value/2 + this.cfg.inner_diameter.value/2 //y2
							];
						}
						return [
							this.cfg.diameter.value/2 - 3, //x
							this.cfg.diameter.value/2 - 1, //y
							this.cfg.diameter.value/2 - 3, //x2
							this.cfg.diameter.value/2 - 1 //y2
						];
						break;
					case 'Opposite':
						return [
							this.cfg.diameter.value/2 - .85, //x2
							0, //y2/y2
							this.cfg.diameter.value/2 - .85, //x
							this.cfg.diameter.value - 1.7, //y
						];
						break;
					case 'Split':
						return [
							0,	//x
							(this.cfg.diameter.value/2) - .85,	//y
							this.cfg.diameter.value - 1.7,	//x2
							(this.cfg.diameter.value/2) - .85	//y2
						];
						break;
				}
			}
			return [0, 0];
		},
		lead_a : function(){
			var x = this.getLeadPosition('a')[0];
			var y = this.getLeadPosition('a')[1];		

			var final_x = this.getFinalPosition()[0];
			var final_y = this.getFinalPosition()[1] + .2;
			return this.getPath(x, y, final_x, final_y, 'a');
		},
		lead_end_a : function(){
			var final_x = this.getFinalPosition()[0];
			var final_y = this.getFinalPosition()[1] + .2;
			var p = 'M ' + this._scale(final_x) + ' ' + this._scale(final_y);
			p = p + ' L ' + this._scale(final_x + 1) + ' ' + this._scale(final_y);
			return p;
		},
		lead_b : function(){
			var x = this.getLeadPosition('b')[0];
			var y = this.getLeadPosition('b')[1];
			var final_x = this.getFinalPosition()[0];
			var final_y = this.getFinalPosition()[1];;
			return this.getPath(x, y, final_x, final_y, 'b');
		},
		lead_end_b : function(){
			var final_x = this.getFinalPosition()[0];
			var final_y = this.getFinalPosition()[1];
			var p = 'M ' + this._scale(final_x) + ' ' + this._scale(final_y);
			p = p + ' L ' + this._scale(final_x + 1) + ' ' + this._scale(final_y);
			return p;
		},
		leadClass : function(){
			switch(this.cfg.leads.type.value){
				case 'No Preference':
					return 'white-rubber';
					break;
				case 'Extruded Silicone Rubber / Sil-A-Bend':
					return 'orange-rubber';
					break;
				case 'PTFE / Teflon':
					return 'red-teflon';
					break;
				case 'MG':
					return 'blue-teflon';
					break;
			}
		},
		leadLengthLabelTransform : function(){
			var x = this._scale(this.getFinalPosition()[0] - 6.5);
			var y = this._scale(this.getFinalPosition()[1] + 2);
			return "translate(" + x + ", " + y + ")";
		},
		radius : function(){
	      	return this.cfg.diameter.value / 2
	    },
	    inner_radius : function(){
	      	return this.cfg.inner_diameter.value / 2
	    },
	    inner_width : function(){
	    	return this.cfg.diameter.value - this.cfg.inner_diameter.value
	    },
	    center_x : function(){
	      	return this.cfg.diameter.value / 2
	    },
	    center_y : function(){
	      	return this.cfg.diameter.value / 2
	    },
	    half_sweep: function(){
      		return this.cfg.cone_sweep.value / 2;
      	},

      	trap_top_left : function(){
		  	var y = 0;
	      	if (this.cfg.offset.value < 0){
	      		var x = 0;
	      	}else if (this.cfg.offset.value > 0){
	      		x = this.cfg.offset.value;
	      	}else{
	      		x = 0;
	      	}
	      	if (this.cfg.shape.options.type == "isosceles"){
	      		if (this.cfg.width.value > this.cfg.top_width.value){
		      		x = this.cfg.width.value/2 - this.cfg.top_width.value/2
		      	}else{
		      		x = 0
		      	}
		    }
	      	return {'x': x, 'y': y};
		  },
		trap_top_right : function(){
		  	var x = this.trap_top_left.x + this.cfg.top_width.value;
		    return {'x': x, 'y': 0};
		  },
		trap_bottom_right : function(){
		  	var y = this.cfg.height.value;
	      	var x = this.trap_bottom_left.x + this.cfg.width.value;
	      	return {'x': x, 'y': y};
		  },
		trap_bottom_left : function(){
		  	if (this.cfg.offset.value < 0){
	      		var x = -this.cfg.offset.value;
	      	}else{
	      		x = 0;
	      	}
	      	if (this.cfg.shape.options.type == "isosceles"){
	      		if(this.cfg.width.value < this.cfg.top_width.value){
	      			x = this.cfg.top_width.value/2 - this.cfg.width.value/2
	      		}else{
	      			x = 0;
	      		}
	      	}
	      	var y = this.cfg.height.value;
	      	return {'x': x, 'y': y};
		  },


	},
	methods : {
		_scale: function(v){
			return this.scale * v
		},
		lineTo : function(x, y){
			return ' L' + x + ' ' + y;
		},
		getPath : function(x, y, final_x, final_y, lead){
			var c;
			var p = 'M ' + this._scale(x) + ' ' + this._scale(y);
			var c1x;
			var c1y;
			var c2x = final_x - 10;
			var c2y = final_y;
			switch(this.cfg.leads.location.value){
				case 'Standard':
					c1x = x;
					c1y = y + 2;
					break;
				case 'Left':
					c1x = x - 10;
					c1y = y;
					if (this.cfg.shape.value == 'cone'){
						var diff_radius_half = (this.radius - this.inner_radius) / 2 + this.inner_radius + .5;
						var point = this.polarToCartesian(this.rect_xy[0], this.rect_xy[1], 10, this.half_sweep + 90);
						c1x = point.x;
						c1y = point.y;
					}
					if (this.cfg.shape.value == 'trapezoid'){
						var angle_r = this.getAngle(this.trap_top_left.x, this.trap_top_left.y, this.trap_bottom_left.x, this.trap_bottom_left.y);
					 	var angle_d = angle_r * 180/Math.PI - 90;
						var point = this.polarToCartesian(this.rect_xy[0], this.rect_xy[1], 10, -angle_d);
						c1x = point.x;
						c1y = point.y;
					}
					break;
				case 'Split':
					c1x = x - 6;
					c1y = y;
					if ( (this.cfg.shape.value == 'circle' || this.cfg.shape.value == 'donut')  && lead == 'b'){
						c1x = x + 10;
						c1y = y;
					}
					if (this.cfg.shape.value == 'cone' && lead == 'b'){
						var diff_radius_half = (this.radius - this.inner_radius) / 2 + this.inner_radius + .5;
						var point = this.polarToCartesian(this.rect_xy[2], this.rect_xy[3], 10, -this.half_sweep - 90);
						c1x = point.x;
						c1y = point.y;
					}
					if (this.cfg.shape.value == 'trapezoid' && lead == 'b'){
						var angle_r = this.getAngle(this.trap_top_right.x, this.trap_top_right.y, this.trap_bottom_right.x, this.trap_bottom_right.y);
					 	var angle_d = angle_r * 180/Math.PI +90;
						var point = this.polarToCartesian(this.rect_xy[2], this.rect_xy[3], 10, -angle_d);
						c1x = point.x;
						c1y = point.y;
					}
					if (this.cfg.shape.value == 'cone' && lead == 'a'){
						var diff_radius_half = (this.radius - this.inner_radius) / 2 + this.inner_radius + .5;
						var point = this.polarToCartesian(this.rect_xy[0], this.rect_xy[1], 10, this.half_sweep + 90);
						c1x = point.x;
						c1y = point.y;
					}
					if (this.cfg.shape.value == 'trapezoid' && lead == 'a'){
						var angle_r = this.getAngle(this.trap_top_left.x, this.trap_top_left.y, this.trap_bottom_left.x, this.trap_bottom_left.y);
					 	var angle_d = angle_r * 180/Math.PI - 90;
						var point = this.polarToCartesian(this.rect_xy[0], this.rect_xy[1], 10, -angle_d);
						c1x = point.x;
						c1y = point.y;
					}
					break;
				case 'Top':
					c1x = x - 2;
					c1y = y - 10;
					break;
				case 'Right':
					c1x = x + 10;
					c1y = y;
					if (this.cfg.shape.value == 'cone'){
						var diff_radius_half = (this.radius - this.inner_radius) / 2 + this.inner_radius + .5;
						var point = this.polarToCartesian(this.rect_xy[0], this.rect_xy[1], 20, -this.half_sweep - 90);
						c1x = point.x;
						c1y = point.y;
					}
					if (this.cfg.shape.value == 'trapezoid'){
						var angle_r = this.getAngle(this.trap_top_right.x, this.trap_top_right.y, this.trap_bottom_right.x, this.trap_bottom_right.y);
					 	var angle_d = angle_r * 180/Math.PI +90;
						var point = this.polarToCartesian(this.rect_xy[0], this.rect_xy[1], 10, -angle_d);
						c1x = point.x;
						c1y = point.y;
					}
					break;
				case 'Inside':
					c1x = x;
					c1y = y + 2;
					if (this.cfg.shape.value == 'cone'){
						var diff_radius_half = (this.radius - this.inner_radius) / 2 + this.inner_radius + .5;
						var point = this.polarToCartesian(this.rect_xy[0], this.rect_xy[1], 10, (this.half_sweep/2) + 0);
						c1x = point.x;
						c1y = point.y;
					}
					break;
				case 'Opposite':
					if (lead == 'b'){
						c1x = x;
						c1y = y + 2;
						if ( this.cfg.shape.value == 'cone'){
							c1x = x;
							c1y = y - 10;
						}
					}else{
						c1x = x - 2;
						c1y = y - 10;
						if ( this.cfg.shape.value == 'donut'){
							var offset = (this.cfg.diameter.value - this.cfg.inner_diameter.value) / 2;
							// /c1x = x - 10 - (10/offset);
							c2x = x - 5 - (50/offset);
						}
					}
					break;
			}
			c = ' C ' + this._scale(c1x) + ' ' + this._scale(c1y) + ' ' + this._scale(c2x) + ' ' + this._scale(c2y) + ' ' + this._scale(final_x) + ' ' + this._scale(final_y);
			return p + c;
		},
		getLeadPosition : function(lead){
			var x;
			var y;
			switch(this.cfg.leads.location.value){
				case 'Standard':
					x = this.rect_xy[0] + .6 + (lead == 'b' ? .6 : 0);
					y = this.rect_xy[1] + 1;
					break;
				case 'Left':
					x = this.rect_xy[0];
					y = this.rect_xy[1] + .6 + (lead == 'b' ? .6 : 0);
					if (this.cfg.shape.value == 'cone'){
						var p = this.polarToCartesian(this.rect_xy[0], this.rect_xy[1], -1.5, (this.half_sweep) + 45)
						x = p.x;
						y = p.y + (lead == 'b' ? .6 : 0);
					}
					if (this.cfg.shape.value == 'trapezoid'){
						var angle_r = this.getAngle(this.trap_top_left.x, this.trap_top_left.y, this.trap_bottom_left.x, this.trap_bottom_left.y);
					 	var angle_d = angle_r * 180/Math.PI + 45;
						var p = this.polarToCartesian(this.rect_xy[0], this.rect_xy[1], 1.5, -angle_d)
						var p2 = this.polarToCartesian(this.rect_xy[0], this.rect_xy[1], 1, -angle_d)
						x = p.x;
						y = (lead == 'b' ? p2.y : p.y);;
					}
					break;
				case 'Split':
					if (lead == "a"){
						x = this.rect_xy[0];
						y = this.rect_xy[1] + .6;
						if (this.cfg.shape.value == 'cone'){
							var p = this.polarToCartesian(this.rect_xy[0], this.rect_xy[1], -1.5, (this.half_sweep) + 45)
							x = p.x;
							y = p.y;
						}
						if (this.cfg.shape.value == 'trapezoid'){
							var angle_r = this.getAngle(this.trap_top_left.x, this.trap_top_left.y, this.trap_bottom_left.x, this.trap_bottom_left.y);
						 	var angle_d = angle_r * 180/Math.PI + 45;
							var p = this.polarToCartesian(this.rect_xy[0], this.rect_xy[1], 1.5, -angle_d)
							x = p.x;
							y = p.y;
						}
					}else{
						x = this.rect_xy[2];
						y = this.rect_xy[3] + .6;
						if (this.cfg.shape.value == 'cone'){
							var p = this.polarToCartesian(this.rect_xy[2], this.rect_xy[3], 1.5, -(this.half_sweep) + 45)
							x = p.x;
							y = p.y - .6;
						}
						if (this.cfg.shape.value == 'trapezoid'){
							var angle_r = this.getAngle(this.trap_top_right.x, this.trap_top_right.y, this.trap_bottom_right.x, this.trap_bottom_right.y);
						 	var angle_d = angle_r * 180/Math.PI + 180 + 45;
							var p = this.polarToCartesian(this.rect_xy[2], this.rect_xy[3], 1, -angle_d)
							x = p.x;
							y = p.y;
						}
					}
					break;
				case 'Top':
					x = this.rect_xy[0] + .6 + (lead == 'b' ? .6 : 0);
					y = 0;
					if (this.cfg.shape.value == 'cone'){
						y = this.rect_xy[1];
					}
					break;
				case 'Right':
					x = this.rect_xy[0];
					y = this.rect_xy[1] + .6 + (lead == 'b' ? .6 : 0);
					if (this.cfg.shape.value == 'cone'){
						var p = this.polarToCartesian(this.rect_xy[0], this.rect_xy[1], 1.5, -(this.half_sweep) + 45)
						x = p.x;
						y = p.y + (lead == 'b' ? .6 : 0);;
					}
					if (this.cfg.shape.value == 'trapezoid'){
						var angle_r = this.getAngle(this.trap_top_right.x, this.trap_top_right.y, this.trap_bottom_right.x, this.trap_bottom_right.y);
					 	var angle_d = angle_r * 180/Math.PI + 180 + 45;
						var p = this.polarToCartesian(this.rect_xy[0], this.rect_xy[1], 1.5, -angle_d)
						var p2 = this.polarToCartesian(this.rect_xy[0], this.rect_xy[1], 1, -angle_d)
						x = p.x;
						y = (lead == 'b' ? p2.y : p.y);;
					}
					break;
				case 'Inside':
					x = this.rect_xy[0] + .6 + (lead == 'b' ? .6 : 0);
					y = this.rect_xy[1] + 1;
					if (this.cfg.shape.value == 'star' ){
						x = x - .85;
						y = y - .85;
					}
					if (this.cfg.shape.value == 'cone'){
						var p = this.polarToCartesian(this.rect_xy[0], this.rect_xy[1], -1, (this.half_sweep/2) + 45)
						x = p.x;
						y = p.y + (lead == 'b' ? .6 : 0);
					}
					break;
				case 'Opposite':
					if(lead == 'a'){
						if (this.cfg.shape.value == 'star' ){
							if (Math.abs(this.cfg.num_sides.value % 2) == 1){
								var p = Geometry.getPerimeterPoint(Math.round(this.cfg.num_sides.value / 2) -.95, this.radius, this.cfg.num_sides.value, false)._offset(this.radius).value;
								console.log(p)
								x = p.x + (lead == 'b' ? .6 : 0);
								y = p.y + 1;
							}else{
								x = this.rect_xy[0] + 1.85 + (lead == 'b' ? .6 : 0);
								y = this.rect_xy[1] + 1;
							}
						}else{
							x = this.rect_xy[0] + .85 + (lead == 'b' ? .6 : 0);
							y = this.rect_xy[1] + 1;
						}
					}else{
						x = this.rect_xy[2] + .85;
						y = this.rect_xy[3] + 1.7;
					};
					break;
			}
			return [x, y];
		},
		getFinalPosition : function(){
			if (this.cfg.shape.value == 'rectangle'){
				return [
					this.cfg.width.value/2 + 2, //x
					this.cfg.height.value + 4  //y
				]
			}if (this.cfg.shape.value == 'trapezoid'){
				var offset = this.cfg.offset.value < 0 ? Math.abs(this.cfg.offset.value) : 0;
				return [
					this.cfg.width.value/2 + offset + 2, //x
					this.cfg.height.value + 4  //y
				]
			}else{
				return [
					this.cfg.diameter.value/2 + 2, //x
					this.cfg.diameter.value + 4  //y
				]
			}
		},
		polarToCartesian : function (centerX, centerY, radius, angleInDegrees) {
		  var angleInRadians = (angleInDegrees + 90) * Math.PI / 180.0;
		  return {
		    x: centerX + (radius * Math.cos(angleInRadians)),
		    y: centerY + (radius * Math.sin(angleInRadians))
		  };
		},
		getAngle : function(x1,y1,x2,y2){
			return Math.atan2((x2 - x1), (y2 - y1));
		},
		find_point : function(x1,y1,x2,y2,r){
			var x3 = x1 + ((x2-x1) * r)
			var y3 = y1 + ((y2-y1) * r)
			return{"x" : x3, "y" : y3};
	      },
	}
}

</script>


<style type="text/css">
  text {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: .8em;
  }
  .flex-heater{fill:#CF3F27;stroke:#000000;stroke-miterlimit:10;}
  .bare-lead{fill:none;stroke:#888888;stroke-miterlimit:10;stroke-width:1px;}
  .black-rubber{fill:none;stroke:#000000;stroke-miterlimit:10;stroke-width:3px;}
  .orange-rubber{fill:none;stroke:#AD1F05;stroke-miterlimit:10;stroke-width:1.5px;}
  .white-rubber{fill:none;stroke:#ffffff;stroke-miterlimit:10;stroke-width:1.5px;}
  .red-teflon{fill:none;stroke:#999999;stroke-miterlimit:10;stroke-width:1.5px;stroke-dasharray: 2;}
  .blue-teflon{fill:none;stroke:#ffff00;stroke-miterlimit:10;stroke-width:1.5px;stroke-dasharray: 5,2;}
</style>