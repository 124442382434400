<template>
  <v-container class="text-xs-left">
    <v-alert v-if="!mini" transition="scroll-y-transition" :value="show_error" type="warning" dark>
      {{error}}
    </v-alert>

    <v-layout v-if="loading || sent">
      <v-flex xs12 class="text-xs-center">
        <h3>{{status_msg}}</h3>
        <v-progress-circular v-if="loading" indeterminate color="blue"></v-progress-circular>
      </v-flex>
      <v-flex xs12 class="text-xs-center" v-if="sent">
        <v-btn @click="showSaveForm" block>Save and Build Another?</v-btn>
      </v-flex>
    </v-layout>

    <v-form ref="form" v-model="form_valid" lazy-validation v-if="!showSave && !loading && !sent && !mini">
      <v-layout row wrap>
        <v-flex xs12>
          <h2>Send to Tutco-Farnam for Quote</h2>
        </v-flex>
        <v-flex xs12>
          <div class="required">
              <strong>General Data Privacy Authorization*</strong>
              <p>By entering my personal information into the contact forms, I hereby give consent to TUTCO to collect,
              process, review, and transfer data in relation to sales and marketing communications, technical support
              communications, and engineering communications. I understand Tutco shares personal data with
              organizations facilitating the above communications. I also consent to the transfer of personal data
              outside of my country for the purposes described above.</p>
              <p>Please indicate below if you give permission to receive communications from TUTCO. If you select “opt
              in” you can stop receiving communications at any time clicking “unsubscribe” in any email.</p>
              <v-container class="text-xs-left">
                <v-layout row>
                  <v-flex class="xs6">
                    <v-checkbox box v-model="LEADCF106" name="LEADCF106" label="GDPR Acknowledgement"></v-checkbox>
                  </v-flex>
                  <v-flex class="xs6">
                    <v-checkbox box v-model="Email_Opt_Out" name="Email Opt Out" label="Opt-out"></v-checkbox>
                  </v-flex>
              </v-layout>
            </v-container>
          </div>
        </v-flex>
        <v-flex xs12 sm6 pr-3>
          <v-text-field box label="Company Name*" required :rules="[rules.required]" v-model="company_name" name="company_name"></v-text-field>
          <v-text-field box label="First Name*" required :rules="[rules.required]" v-model="first_name" name="first_name"></v-text-field>
          <v-text-field box label="Last Name*" required :rules="[rules.required]" v-model="last_name" name="last_name"></v-text-field>
          <v-text-field box label="Phone*" required :rules="[rules.required, rules.phone]" v-model="phone" name="phone"></v-text-field>
          <v-text-field box label="Email*" required :rules="[rules.required, rules.email]" v-model="email" name="email"></v-text-field>
        </v-flex>
        <v-flex xs12 sm6>
          <v-textarea class="description-text" box name="description" block label="Project Details" v-model="description"></v-textarea>
        </v-flex>

        <v-flex xs12 v-if="num_saved_heaters > 1 && cfg.shape.value != 'custom'">
          <h3>Send the following saved configurations to Farnam Custom Heaters</h3>
          <v-container class="text-xs-left">
            <v-layout row wrap>
              <v-flex xs12 sm6 md4 v-for="(heater, id) in saved_heaters" :key="heater.id">
                <v-checkbox :label="heater.label" v-model="heatersToSend" :value="heater.id"></v-checkbox>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>

        <v-flex xs12>
          <div class="caption pl-4  pr-4">
            <div>
              <v-checkbox label="I have read and understand statement below..." v-model="readAndUnderstand" :value="true"></v-checkbox>
              <p>The heater images presented in this interactive product builder are intended to assist you in developing and communicating product specifications. These heater images should not be considered accurate depictions of the final heater that we produce. <br><a v-if="!showmore" @click="showmore = true">Learn more...</a></p>
            </div>
            <div v-if="showmore">
              <p>Before your heater can be manufactured it needs to be designed. Many variables will come into play including material, trace dimensions and watt density. The design process will ultimately dictate more specifically where leads will exit the heater or where a sensor can or should be placed. </p><p>Flexible heaters that we consider to be simple in design can be quoted quickly. Flexible heaters with greater complexity may require your sign-off on an engineered design drawing. All design drawings require sign-off before they can be manufactured. For any requirements not addressed by our Flexible Heater configurator please include them in the Project Details section of the form above. <br><a v-if="showmore" @click="showmore = false">Show less...</a></p>
            </div>
          </div>
        </v-flex>
        <v-flex xs12 sm5>
          <v-btn block :disabled="!form_valid || !readAndUnderstand" @click="sendContactForm" color="primary">Send</v-btn>
        </v-flex>
        <v-flex xs12 sm2 class="text-xs-center"><br>or</v-flex>
        <v-flex xs12 sm5>
          <v-btn class="mr-0"  @click="showSaveForm" block>Save and Build Another?</v-btn>
        </v-flex>
      </v-layout>
    </v-form>


    <v-layout row wrap v-if="showSave">
      <h2>Save your current configuration first</h2>
      <v-flex xs9>
        <v-text-field ref="heater_name" label="Name Your Heater" box v-model="cfg.label" clearable></v-text-field>
      </v-flex>
      <v-flex xs3>
        <v-btn @click="saveHeater(cfg)" color="green"><v-icon v-if="isSaved" left small>fa-check</v-icon>Save</v-btn>
      </v-flex>
    </v-layout>
  

    <v-divider></v-divider>

    
    
    <!--<PRE>{{_debug_desc}}</PRE>//-->
    
    
  </v-container>
</template>

<script>

  /* eslint-disable */
  import request from 'superagent';
  import Zoho from '@/plugins/zoho.js';
  import FlexSpec from '@/plugins/FlexSpecCalculations.js';
  import debounce from 'lodash/debounce';
  import Cookies from 'js-cookie';
  import PartNumbers from "./part-numbers.js";

  export default {
    props : ['act', 'cfg', 'csrf', 'saved_heaters', 'mini'],
    data: function(){
      return {
        sent : false,
        error : '',
        saved_hash : 0, //the hash of the saved config.
        heatersToSend : [], //array of heaters to send to zoho
        first_name : '',
        last_name : '',
        company_name : '',
        phone : '',
        email : '',
        description : '',
        readAndUnderstand : false,
        LEADCF106 : false,
        Email_Opt_Out : false,
        rules : {
          required : v => !!v || 'This field is required',
          email : v => /.+@.+/.test(v) || 'E-mail must be valid',
          phone : v => /^[\d ()+-]+$/.test(v) || 'Phone number must be valid',
        },
        form_valid : true,
        showmore : false,
        showSave : false,
        loading : false,
        clientId : '',
        status_msg : ' Sending Configuration and Info to our Engineers...'
      }
    },
    updated: function () {
      //creates a default label if there is not current default label. 

    },
    mounted : function() {
      //saved hash is used to see if this heater needs saving yet. 
      //if (typeof this.saved_heaters[this.cfg.id] != 'undefined'){
     //   this.saved_hash = this.hashCode(JSON.stringify(this.cfg.id]) )
      //}
      //automatically add the current heater config to the heaters we will send to zoho
      //if(!this.heatersToSend.includes(this.cfg.id)){
      //  this.heatersToSend.push(this.cfg.id);
      //}
      //automatically add the current heater to saved heaters array
      //this.saveHeater(this.cfg); 
      //this.$emit('update', this.cfg);// may not need this anymore...
      var g = this;
      setTimeout(function(){
        if (typeof ga != 'undefined'){
          ga(function(tracker) {
            var clientId = tracker.get('clientId');
            // set hidden input to client id
            g.clientId = clientId;
          });
        }
      }, 1000);

    },
    computed : {
      show_error : function(){
        return this.error != '';
      },
      //returned true if this config has already been saved. 
      isSaved : function(){
        var curr = this.hashCode(JSON.stringify(this.cfg));
        return this.saved_hash == curr;
      },
      //tests if we can sotre heaters in local storage. If so, then we allow it. 
      canStore : function (){
          var test = 'test';
          try {
              localStorage.setItem(test, test);
              localStorage.removeItem(test);
              return true;
          } catch(e) {
              return false;
          }
      },
      num_saved_heaters : function(){
        if (typeof this.saved_heaters == 'undefined' || this.saved_heaters === null){
          return 0;
        }
        return Object.keys(this.saved_heaters).length;
      },
      _debug_desc : function(){
        return this.getHeaterDescriptions([this.cfg]);
      },
    },
    methods : {
      prevStep : function(){
        this.$emit('prev-step', this.cfg)
      },
      valid_config : function(){
        //validate the form and confirm all fields are valid before submitting 
        if (typeof this.cfg.requirements.quantity.value == 'undefined' || this.cfg.requirements.quantity.value == null || this.cfg.requirements.quantity.value == ''){
          this.error = "You must enter an initial quantity in Step 4 - 'Requirements'."
          //this.show_error = true;
          this.$vuetify.goTo('#top');
          return false;
        }
        if (!this.LEADCF106){
          this.error = "You must acknowledge our GDPR statement."
          //this.show_error = true;
          this.$vuetify.goTo('#top');
          return false;
        }
        //this.show_error = false;
        this.error = ""
        return true;
      },
      titleCase : function(str) {
        if(typeof(str) == 'number') return Math.round(str * 100) / 100;
        if (typeof(str) == 'undefined' || str === null) return str;
        return str.toLowerCase().split(' ').map(function(word) {
          return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
      },
      logEngagement : debounce(function(type){
        if (typeof ga != 'undefined'){
          ga('send', 'event', 'flex-interaction', 'page-5', type);
        }
      }, 1000),
      saveHeater(c){
        if (typeof c == 'undefined'){
          c = this.cfg;
        }
        if (c.id !== null){
          this.saved_hash = this.hashCode(JSON.stringify(this.cfg))
          if(!this.heatersToSend.includes(c.id)){
            this.heatersToSend.push(c.id);
          }
          this.$emit('save-heater',c);
        }
        this.logEngagement('saved-heater');
        this.showSave = false;
        this.newHeater();
      },
      sendContactForm : function(){
        //we only send one custom heater at a time. 
        if (this.cfg.shape.value == 'custom'){
          this.heatersToSend = [this.cfg.id];
        }
        var g = this;
        var cfgs = [];
        var i;
        if (!this.valid_config()){
          return false;
        }
        this.show_error = false;
        if (this.$refs.form.validate()) {
          if (typeof ga != 'undefined'){
            ga('send', 'event', 'quote-request', 'send-init', 'flexible-heater');
          }
          this.$vuetify.goTo('#top');
          this.loading = true;
          this.sent = false;
          this.status_msg = "Saving and compiling configuration(s)...";
          try{
            for (i=0; i<this.heatersToSend.length; i++){
              if (typeof this.saved_heaters[this.heatersToSend[i]] != 'undefined'){
                cfgs.push(this.saved_heaters[this.heatersToSend[i]])
              }
            }
            if (cfgs.length == 0){
              cfgs.push(this.cfg);
            }
          }catch(e){
            if (typeof ga != 'undefined'){
              ga('send', 'event', 'quote-request', 'send-error-configs', 'flexible-heater');
            }
          }
          this.saveAndSetHash(cfgs, function(){
            //then send to zoho. 
            g.loading = true;
            g.status_msg = "Sending Configuration and Info to our Engineers...";
            var _cfgs = [];
            var singleFlexUrl = '';
            var multiFlexUrls = '';
            try{
              for (i=0; i<g.heatersToSend.length; i++){
                if(typeof g.saved_heaters[g.heatersToSend[i]] != 'undefined'){
                  _cfgs.push(g.saved_heaters[g.heatersToSend[i]])
                }
              }
              if (_cfgs.length == 0){
                _cfgs.push(g.cfg);
              }
              singleFlexUrl = 'http://' + window.location.hostname + '/site/flex-specs/' + _cfgs[0]['hash'];
              if (_cfgs.length > 1){
                for (i=1; i<_cfgs.length; i++){
                  multiFlexUrls = multiFlexUrls + 'http://' + window.location.hostname + '/site/flex-specs/' + _cfgs[i]['hash'] + "\n";
                }
              }
            }catch(e){
              console.log(e)
              if (typeof ga != 'undefined'){
                ga('send', 'event', 'quote-request', 'send-error-configszoho', 'flexible-heater');
              }
            }
            try{
              var heater_description = g.getHeaterDescriptions(_cfgs);
            }catch(e){
              if (typeof ga != 'undefined'){
                ga('send', 'event', 'quote-request', 'send-error-descriptions', 'flexible-heater');
              }
            }
            try{
              var custom_images = g.getCustomImages(_cfgs);
            }catch(e){
              if (typeof ga != 'undefined'){
                ga('send', 'event', 'quote-request', 'send-error-images', 'flexible-heater');
              }
            }
            var landingPage = typeof Cookies.get('landingPage') != 'undefined' ? Cookies.get('landingPage') : '';
            var z = Zoho.init(
                {
                  'actionType' : 'TGVhZHM=',
                  'zc_gad' : '',
                  'xmIwtLD' : '561312ab7748a1d7252a5ec4d720d84e5aae58b2a483659dc045cd1865b135de',
                  'xnQsjsdp' : 'c4800ca021f51e3d20beb54bcea133b66c6dc775d35378c942f375287f707b73',
                  'returnURL' : 'https://' + window.location.hostname + '/about-us/contact/thanks/?utm_nooverride=1',
                  'First Name' : g.first_name,
                  'Last Name' : g.last_name,
                  'Company' : g.company_name,
                  'Phone' : g.phone,
                  'Email' : g.email,
                  'LEADCF8' : 'Web Form',
                  'Lead Source' : 'Website - Flexible Heater Configurator',
                  'LEADCF10' : g.description + "\n\n" + heater_description,
                  'LEADCF21' : singleFlexUrl,
                  'LEADCF20' : multiFlexUrls,
                  'LEADCF106' : g.LEADCF106 ? 'on' : '',
                  'Email Opt Out' : g.Email_Opt_Out ? 'on' : '',
                  'LEADCF16' : g.clientId,
                  'LEADCF14' : landingPage
                }
              ).attachImages(custom_images).send(function(success, msg){
                g.sent = true;
                g.loading = false;
                g.status_msg = "Complete! Request has been sent!";
                if (typeof ga != 'undefined'){
                  ga('send', 'pageview', '/about-us/contact/thanks/');
                  ga('send', 'event', 'quote-request', 'send-complete', 'flexible-heater', 5);
                }
              });
          })
        }
      },
      //saves the configs to the server and gets a hash back that is used for linking and pulling the cfg from the server. 
      //maybe use the hash for updating as well?
      //last param should be a callback function
      saveAndSetHash(cfgs, cb){
        var json_cfgs = [];
        for (var i = 0; i < cfgs.length; i++){
          json_cfgs[i] = JSON.stringify(cfgs[i]);
        }
        var data = {
          'configs[]' : json_cfgs,
          first_name : this.first_name,
          last_name : this.last_name,
          company_name : this.company_name,
          phone : this.phone,
          email : this.email,
          description : this.description,
          csrf_token : this.csrf,
        }
        var g = this;
        request
          .post('/site/api?ACT=' + this.act)
          .type('form')
          .send(data) // sends a JSON post body
          .set('accept', 'json')
          .end((err, res) => {
            g.loading = false;
            if (res.body === null){
              try{
                var hashes = JSON.parse(res.text);
              }catch(e){
                g.loading = false;
                g.$emit('dialog', {title:'Uh oh...', text:"<p>Something went wrong and we couldn't send the info. <br><br>Please try again or please give us a call to let us know. 828.684.3766</p>" });
                if (typeof ga != 'undefined'){
                  ga('send', 'event', 'quote-request', 'send-error-onsave', 'flexible-heater');
                }
                return;
              }
            }else{
              hashes = res.body
            }
            //now add these to the correct configs and then send those configs to ZOHO...
            g.setHashes(hashes);
            //execute the callback function. 
            cb();
          });
      },
      //hashes are created server side and will be used in links to pull configs from server. 
      setHashes(hashes){
        var i;
        for (i=0; i < hashes.length; i++){
          var h = hashes[i];
          //update hashes in the saved heaters var
          this.$emit('update-hash', h.id, h.hash);
          //if this is the current cfg then set that hash for it as well. 
          if (this.cfg.id == h.id){
            this.cfg.hash = h.hash;
          }
        }
      },
      newHeater(){
        this.sent = false;
        var copy = JSON.parse(JSON.stringify(this.cfg));
        //this.cfg = null;
        copy.id = 'flex_' + (+ new Date());
        copy.label = "Flexible Heater";
        this.$emit('new', copy);
        //this.saveHeater(copy);
        this.$emit('edit-heater');
        this.saved_hash = 0;
      },
      hashCode : function(str) {
        var hash = 0, i, chr;
        if (str.length === 0) return hash;
        for (i = 0; i < str.length; i++) {
          chr   = str.charCodeAt(i);
          hash  = ((hash << 5) - hash) + chr;
          hash |= 0; // Convert to 32bit integer
        }
        return hash;
      },
      getCustomImages : function(cfgs){
        var images = [];
        //only add the current config's images...
        if(this.cfg.shape.value == 'custom'){
          images.push(this.cfg.shape.options);
        }
        return images;
        /*multiple images
        for (var i = 0; i < cfgs.length; i++){
          var cfg = cfgs[i];
          if(cfg.shape.value == 'custom'){
            images.push(cfg.shape.options);
          }
        }*/
        //console.log(images);
        return images;
      },
      getHeaterDescriptions : function(cfgs){
        var d = "";
        for (var i = 0; i < cfgs.length; i++){
          d = d + "\n\n++++++++++++++++++++++++++++++++++++++\n--------------------------------------\n" + this.getDescription(cfgs[i]);
        }
        return d;
      },
      getDescription(cfg, indent){
        if (typeof indent == 'undefined'){
          indent = '';
        }
        var d = "";
        var g = this;
        var ignoreKeys = [
          'id',
          'label',
        ]
        Object.keys(cfg).forEach(function(key){
          if (!ignoreKeys.includes(key)){
            if (key == 'hash'){
              d = d + indent + 'Link: http://' + window.location.hostname + '/site/flex-specs/' + g.escapeHtml(cfg[key]) + "\n";
            }else if (typeof cfg[key] == 'string'){
              d = d + indent + 'Label: ' + g.titleCase(g.escapeHtml(cfg[key])) + "\n";
            }else if (typeof cfg[key].value == 'undefined'){
              var sub_d = g.getDescription(cfg[key], (indent + '   '));
              if (sub_d != ''){
                d = d + "\n" + sub_d;
              }
            }else{
              if (typeof cfg[key].value != 'undefined' && cfg[key].value != '' && cfg[key].value !== null && cfg[key].value != 'None'){
                d = d + indent + g.escapeHtml(cfg[key].label) + " : " + g.escapeHtml(cfg[key].value);
                if (typeof cfg[key].units != 'undefined'){
                  d = d + " " + g.escapeHtml(cfg[key].units);
                }
                d = d + g.getPartNumber(cfg[key].value);
                if (typeof cfg[key].options != 'undefined' && cfg[key].options != '' && cfg[key].options !== null && cfg[key].options != 'None'){
                  if (typeof cfg[key].options == 'string'){
                    d = d + " >> " + g.escapeHtml(cfg[key].options) + g.getPartNumber(cfg[key].options) + "\n";
                  }else if(typeof cfg[key].options.imageName != 'undefined' && cfg[key].options.imageName != '' && cfg[key].options.imageName !== null){
                    d = d + " >> " + g.escapeHtml(cfg[key].options.imageName) + g.getPartNumber(cfg[key].options) + "\n";
                  }
                }
                d = d + "\n";
              }
            }
          }
        })
        if (typeof cfg.area != "undefined"){
          var engineering = this.flexCalc(cfg);
          d = d + "\nFor Engineering:\n--------------------------------------\n"

          Object.keys(engineering).forEach(function(key){
            var v = isNaN(parseFloat(engineering[key].value)) ? engineering[key].value : Math.round(parseFloat(engineering[key].value) * 10000) / 10000;
            //v = isNaN(parseFloat(engineering[key].value)) ? engineering[key].value : parseFloat(engineering[key].value)
            d = d + indent + g.escapeHtml(engineering[key].label) + " : " + g.titleCase(g.escapeHtml(v) );
            if (typeof engineering[key].units != 'undefined'){
              d = d + " " + g.escapeHtml(engineering[key].units);
            }
            d = d + "\n";
          });
        }

        if (d != ''){
          return g.escapeHtml(cfg['label']) + "\n--------------------------------------\n" + d
        }
        return d;
      },
      getPartNumber : function(label){
        if (typeof PartNumbers[label] == 'undefined' || PartNumbers[label] == null || PartNumbers[label] == ''){
          return '';
        }
        return ' (' + PartNumbers[label] + ")";
      },
      flexCalc : function(cfg){
        if(typeof cfg.area.value == 'undefined' || typeof cfg.watts.value == 'undefined' || typeof cfg.volts.value == 'undefined'){
          return {};
        }
        if(typeof cfg.area.value == null || typeof cfg.watts.value == null || typeof cfg.volts.value == null){
          return {};
        }
        if (parseFloat(cfg.watts.value) <= 0){
          return {};
        }
        var c = FlexSpec.getFlexSpecsCalculations(cfg.watts.value, cfg.volts.value, cfg.area.value);
        return c;
      }, 
      escapeHtml : function(unsafe) {
        unsafe = new String(unsafe)
          return unsafe
               .replace(/&/g, "&amp;")
               .replace(/</g, "&lt;")
               .replace(/>/g, "&gt;")
               .replace(/"/g, "&quot;")
               .replace(/'/g, "&#039;");
       },
      showSaveForm : function(){
        if(this.num_saved_heaters > 1){
          this.saveHeater(this.cfg);
          return;
        }
        this.$vuetify.goTo('#top');
        this.showSave = true;
        var g = this;
        setTimeout(function(){g.$refs.heater_name.$el.focus()}, 500);
      },
      reset : function(){
        this.sent = false;
        this.loading = false;
      }
    },
    watch : {
      'cfg.id' : function(){
        if(!this.heatersToSend.includes(this.cfg.id)){
          this.heatersToSend.push(this.cfg.id);
        }
      },
      'cfg.requirements.quantity.value' : function(newV){
        if (newV != null && newV != ''){
          this.error = '';
          //this.show_error = false;
        }
      },
    },
    components : {
    
    },
  }
</script>

<style>
.description-text .v-input__control textarea{
  height: 368px;
}
</style>
