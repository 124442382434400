import { render, staticRenderFns } from "./StepShapePower.vue?vue&type=template&id=01766a82&"
import script from "./StepShapePower.vue?vue&type=script&lang=js&"
export * from "./StepShapePower.vue?vue&type=script&lang=js&"
import style0 from "./StepShapePower.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "StepShapePower.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib'
import { VBtn } from 'vuetify/lib'
import { VCheckbox } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VRadio } from 'vuetify/lib'
import { VRadioGroup } from 'vuetify/lib'
import { VSelect } from 'vuetify/lib'
import { VSlider } from 'vuetify/lib'
import { VTab } from 'vuetify/lib'
import { VTabItem } from 'vuetify/lib'
import { VTabs } from 'vuetify/lib'
import { VTabsItems } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
installComponents(component, {
  VAlert,
  VBtn,
  VCheckbox,
  VContainer,
  VFlex,
  VIcon,
  VLayout,
  VRadio,
  VRadioGroup,
  VSelect,
  VSlider,
  VTab,
  VTabItem,
  VTabs,
  VTabsItems,
  VTextField,
})
