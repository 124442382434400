<template>
		<g id="HeaterTrapezoid" :transform="_transform">
		  <rect :width="total_width" :height="_height" fill="none" stroke-width="1" stroke="#aaaaaa" stroke-dasharray="4"/>
	      <path id="trap1" :d="trapezoid_shape" class="flex-heater" />

	      <g id="HeaterCircle-elements">
	        <path :style="strokeStyle" class="heater-element" :d="element_path"/>
	      </g>
	      <acc-leads :cfg="cfg" :scale="scale" :x="0" :y="0"></acc-leads>
	      <g id="Dim-Offset" transform="" v-if="cfg.shape.options.type != 'right' && !isNaN(_offset)">
	        <path class="dim-bracket" :d="bracket_offset"/>
	        <text text-anchor="middle" :transform="bracket_offset_text">{{offset}}"</text>
	      </g>
	      <g id="Dim-Width" transform="">
	        <path class="dim-bracket" :d="bracket_width"/>
	        <text text-anchor="middle" :transform="bracket_width_text">{{cfg.width.value}}”</text>
	      </g>
	      <g id="Dim-TotalWidth" transform="" v-if="show_total_width_bracket">
	        <path class="dim-bracket" :d="bracket_total_width"/>
	        <text text-anchor="middle" :transform="bracket_total_width_text">{{total_width_inches}}”</text>
	      </g>
	      <g id="Dim-Height" transform="">
	        <path class="dim-bracket" :d="bracket_height"/>
	        <text text-anchor="middle" :transform="bracket_height_text">{{cfg.height.value}}”</text>
	      </g>
	      <g id="Dim-TopWidth" transform="">
	        <path class="dim-bracket" :d="bracket_topwidth"/>
	        <text text-anchor="middle" :transform="bracket_topwidth_text">{{cfg.top_width.value}}”</text>
	      </g>
	      <rect x="0" y="0" :width="Math.abs(_offset)" :height="_height" fill="none" stroke-width="1" stroke="#aaaaaa" stroke-dasharray="4"/>
	    </g>
</template>


<script>

import Accessory from './accessory-template.vue';
import AccLeads from './acc-leads.vue';

export default{
	extends: Accessory,
	props : ['cfg', 'strokeStyle', 'density'],
	data : function(){
		return {

		}
	},
	computed : {
	  //trapezoid
	  _width : function(){
	  	return this._scale(this.cfg.width.value);
	  },
	  _top_width : function(){
	  	if (this.cfg.shape.options.type == "parallelogram"){
	  		return this._scale(this.cfg.width.value);
	  	}
	  	if (this.cfg.top_width.value !== null && this.cfg.top_width.value > 0){
	  		return this._scale(this.cfg.top_width.value);
	  	}
	  	return this._scale(this.cfg.width.value);
	  },
	  _height : function(){
	  	return this._scale(this.cfg.height.value);
	  },
	  _offset : function(){
	  	if (this.cfg.shape.options.type == "right"){
	  		return 0;
	  	}
	  	if (this.cfg.shape.options.type == "isosceles"){
	  		return this._scale((this.cfg.width.value - this.cfg.top_width.value) / 2);
	  	}
	  	return this._scale(this.cfg.offset.value);
	  },
	  offset : function(){
	  	if (this.cfg.shape.options.type == "right"){
	  		return 0;
	  	}
	  	if (this.cfg.shape.options.type == "isosceles"){
	  		return Math.abs((this.cfg.width.value - this.cfg.top_width.value) / 2);
	  	}
	  	return Math.abs(this.cfg.offset.value);
	  },
	  total_width : function(){
	  	if (this._offset <= 0){
	  		if(this._width + Math.abs(this._offset) > this._top_width){
	  			return this._width + Math.abs(this._offset);
	  		}else{
	  			return this._top_width;
	  		}
	  	}
	  	if(this._width >= this._top_width + Math.abs(this._offset)){
	  		return this._width
	  	}else if(this._offset < 0 && this._width + Math.abs(this._offset) > this._top_width + Math.abs(this._offset)){
	  		if (this.cfg.shape.options.type == "isosceles"){
	  			return this._width;
	  		}
	  		return this._width + Math.abs(this._offset);
	  	}else{
	  		if (this.cfg.shape.options.type == "isosceles"){
	  			return this._top_width;
	  		}
	  		return this._top_width + Math.abs(this._offset);
	  	}
	  },
	  total_width_inches : function(){
	  	if (this._offset <= 0){
	  		if(this._width + Math.abs(this._offset) > this._top_width){
	  			return this.cfg.width.value + Math.abs(this.cfg.offset.value);
	  		}else{
	  			return this.cfg.top_width.value;
	  		}
	  	}
	  	if(this._width >= this._top_width + Math.abs(this._offset)){
	  		return this.cfg.width.value;
	  	}else if(this._offset < 0 && this._width + Math.abs(this._offset) > this._top_width + Math.abs(this._offset)){
	  		if (this.cfg.shape.options.type == "isosceles"){
	  			return this.cfg.width.value;
	  		}
	  		return this.cfg.width.value + Math.abs(this.cfg.offset.value);
	  	}else{
	  		if (this.cfg.shape.options.type == "isosceles"){
	  			return this.cfg.top_width.value;
	  		}
	  		return this.cfg.top_width.value + Math.abs(this.cfg.offset.value);
	  	}
	  },
	  show_total_width_bracket : function(){
	  	if(isNaN(this.total_width_inches)){ return false}
	  	return !(this.total_width_inches == this.cfg.width.value)
	  },
	  total_height : function(){
	  	return this._scale(this.cfg.height.value);
	  },


	  mid_point : function(){
	  	var top_mid = this.top_left.x + (this.cfg.top_width.value/2);
		var bottom_mid = this.bottom_left.x + (this.cfg.width.value/2);
		return this.find_point(top_mid, 0, bottom_mid, this.cfg.height.value, .5)
	  },
	  top_left : function(){
	  	var y = 0;
      	if (this._offset < 0){
      		var x = 0;
      	}else if (this._offset > 0){
      		x = this._offset;
      	}else{
      		x = 0;
      	}
      	return {'x': x, 'y': y};
	  },
	  top_right : function(){
	  	var x = this.top_left.x + this._top_width;
	    return {'x': x, 'y': 0};
	  },
	  bottom_right : function(){
	  	var y = this._height;
      	if (this._offset < 0){
      		var x = (-this._offset) + this._width;
      	}else{
      		x = this._width;;
      	}
      	return {'x': x, 'y': y};
	  },
	  bottom_left : function(){
	  	if (this._offset < 0){
      		var x = -this._offset;
      	}else{
      		x = 0;
      	}
      	var y = this._height;
      	return {'x': x, 'y': y};
	  },


      bracket_offset_text : function(){
      	return "translate(" + (Math.abs(this._offset)/2) + " " + (-10) + ")";
      },
      bracket_offset : function(){
      	return this.topBracketPath(0, (-5), Math.abs(this._offset), (-5));
      },
      bracket_width_text : function(){
      	if (this._offset < 0){
      		return "translate(" + (this._width/2 + Math.abs(this._offset)) + " " + (this.total_height +18) + ")";
      	}
      	return "translate(" + (this._width/2) + " " + (this.total_height +18) + ")";
      },
      bracket_width : function(){
      	if (this._offset < 0){
      		return this.topBracketPath(Math.abs(this._offset), this.total_height + 5, (this._width + Math.abs(this._offset)), this.total_height + 5);
      	}
      	return this.topBracketPath(0, this.total_height + 5, this._width, this.total_height + 5);
      },
      bracket_total_width_text : function(){
      	return "translate(" + (this.total_width/2) + " " + (this.total_height +30) + ")";
      },
      bracket_total_width : function(){
      	return this.topBracketPath(0, this.total_height + 17, this.total_width, this.total_height + 17);
      },
      bracket_height_text : function(){
      	return "translate(-12 " + (this.total_height/2) + ")";
      },
      bracket_height : function(){
      	return this.leftBracketPath(-5, 0, -5, this.total_height);
      },
      bracket_topwidth_text : function(){
      	if (this._offset > 0){
      		return "translate(" + (this._top_width/2 + Math.abs(this._offset)) + " " + (-20) + ")";
      	}
      	return "translate(" + (this._top_width/2) + " " + (-20) + ")";
      },
      bracket_topwidth : function(){
      	if (this._offset > 0){
      		return this.topBracketPath(Math.abs(this._offset), -17, (this._top_width + Math.abs(this._offset)), -17);
      	}
      	return this.topBracketPath(0, -17, this._top_width, -17);
      },

      //element 
      element_path : function(){
      	if (this.cfg.watt_density.value > 61) return '';
      	var margin = this._scale(.7);
      	
        var t = this.cfg.watt_density.value / 10;
      	//var density = this._scale(1 - (this.cfg.watt_density.value / .75));
      	var density = this.cfg.material.value =="mica" ? this.density * 4 : this.density * 2.5;
      	//if (density > 16) density = 16;
      	var dir = 'down'

      	var tl = {x : this.top_left.x + margin, y : this.top_left.y + margin};
      	var tr = {x : this.top_right.x - margin, y : this.top_right.y + margin};
      	var bl = {x : this.bottom_left.x + margin, y : this.bottom_left.y - margin};
      	var br = {x : this.bottom_right.x - margin, y : this.bottom_right.y - margin};

      	var tl = this.find_point(this.top_left.x, this.top_left.y, this.bottom_left.x, this.bottom_left.y, 1, margin);
      	var tr = this.find_point(this.top_right.x, this.top_left.y, this.bottom_right.x, this.bottom_left.y, 1, margin);
      	var bl = this.find_point(this.top_left.x, this.top_left.y, this.bottom_left.x, this.bottom_left.y, -1, margin);
      	var br = this.find_point(this.top_right.x, this.top_left.y, this.bottom_right.x, this.bottom_left.y, -1, margin);
      	
      	if (this.cfg.material.value =="mica"){
      		margin = -this._scale(.25);
      	}
      	tl.x = tl.x + margin;
      	tr.x = tr.x - margin;
      	bl.x = bl.x + margin;
      	br.x = br.x - margin;

      	var path = ["M", tl.x, tl.y].join(" ");
      	if (this.cfg.material.value =="mica"){
      		margin = -1;
      		point = this.find_point(tl.x, tl.y, bl.x, bl.y, 0.25, density);	
      		path = ["M", point.x, point.y].join(" ");
      		tr.y = tr.y + this._scale(.25);
      		br.y = br.y + this._scale(.25);
      	}
      	var i;
      	for(i = 0; i < this._scale(this.cfg.height.value) / density; i++){
      		
      		var point = this.find_point(tr.x, tr.y, br.x, br.y, i, density);
      		if (this.cfg.material.value =="mica"){
      			point = this.find_point(tr.x, tr.y, br.x, br.y, i - .25, density);	
      		}
      		path = path + " " + ["L", point.x, point.y].join(" ");
      		i = i + 1;
      		point = this.find_point(tr.x, tr.y, br.x, br.y, (i), density);
      		if (point.y > this._scale(this.cfg.height.value) - margin){
      			break;
      		}
      		if (this.cfg.material.value =="mica"){
      			point = this.find_point(tr.x, tr.y, br.x, br.y, (i - .25), density);
	      		path = path + " " + ["M", point.x, point.y].join(" ");
	      	}else{
	      		path = path + " " + ["L", point.x, point.y].join(" ");
	      	}
			
			point = this.find_point(tl.x, tl.y, bl.x, bl.y, (i), density);	
			if (this.cfg.material.value =="mica"){
      			point = this.find_point(tl.x, tl.y, bl.x, bl.y, (i + .25), density);	
      		}
			path = path + " " + ["L", point.x, point.y].join(" ");		
			point = this.find_point(tl.x, tl.y, bl.x, bl.y, (i+1), density);
			if (point.y > this._scale(this.cfg.height.value) - margin){
      			break;
      		}
      		if (this.cfg.material.value =="mica"){
      			point = this.find_point(tl.x, tl.y, bl.x, bl.y, (i+1.25), density);
	      		path = path + " " + ["M", point.x, point.y].join(" ");
	      	}else{
	      		path = path + " " + ["L", point.x, point.y].join(" ");	
	      	}	
			

	      }
        return path;
      },

      watt_density : function(){
          radius = this.cfg.diameter.value/2;
          var inner_radius = this.cfg.inner_diameter.value/2;
          var circle_area = (Math.PI * (radius * radius) );
          var hole_area = (Math.PI * (inner_radius * inner_radius) )
          return this.cfg.watts.value / ((circle_area - hole_area) * (this.cfg.cone_sweep.value / 360));
      },

      

      _transform : function(){
      	return;
      	return "translate(" + this.radius + ", 0)";
      },
      trapezoid_shape : function(){
	      	var path = ["M", this.bottom_left.x, this.bottom_left.y].join(" ");
	      	path = path + [" L", this.top_left.x, this.top_left.y].join(" ");
	      	path = path + [" L", this.top_right.x, this.top_right.y].join(" ");
	      	path = path + [" L", this.bottom_right.x, this.bottom_right.y].join(" ");
	      	return path + " Z";
	   },
	},
	methods : {
		_scale : function(v){
	        return v * this.scale;
	    },
	    find_point : function(x1,y1,x2,y2,i,d){
			var r = (d * i) / (y2-y1);
			var x3 = x1 + ((x2-x1) * r)
			var y3 = y1 + (d * i)
			return{"x" : x3, "y" : y3};
	      },
	    polarToCartesian : function (centerX, centerY, radius, angleInDegrees) {
		  var angleInRadians = (angleInDegrees + 90) * Math.PI / 180.0;
		  return {
		    x: centerX + (radius * Math.cos(angleInRadians)),
		    y: centerY + (radius * Math.sin(angleInRadians))
		  };
		},
		describeArc : function (x, y, radius, startAngle, endAngle){

		    var start = this.polarToCartesian(x, y, radius, endAngle);
		    var end = this.polarToCartesian(x, y, radius, startAngle);

		    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

		    var d = ["A", radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(" ");

		    return d;       
		},
		describeReverseArc : function (x, y, radius, startAngle, endAngle){

		    var start = this.polarToCartesian(x, y, radius, startAngle);
		    var end = this.polarToCartesian(x, y, radius, endAngle);

		    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

		    var d = ["A", radius, radius, 0, largeArcFlag, 1, end.x, end.y].join(" ");

		    return d;       
		},
		topBracketPath : function(x1, y1, x2, y2){
			if (y1 > 0 || y2 > 0){
				var offset = 10;
			}else{
				offset = -10
			}
	      	var path = ["M", x1, y1].join(" ");
	      	path = path + ' ' + ["L", x1, y1 + offset].join(" ");
	      	path = path + ' ' + ["L", ((x2 - x1)/2) - 15 + x1 , y1 + offset].join(" ");
	      	path = path + ' ' + ["M", ((x2 - x1)/2) + 15 + x1 , y1 + offset].join(" ");
	      	path = path + ' ' + ["L", x2 , y2  + offset].join(" ");
	      	path = path + ' ' + ["L", x2 , y2].join(" ");
	      	return path;
		},
		leftBracketPath : function(x1, y1, x2, y2){
			if (x1 > 0 || x2 > 0){
				var offset = 10;
			}else{
				offset = -10
			}
	      	var path = ["M", x1, y1].join(" ");
	      	path = path + ' ' + ["L", x1 + offset, y1].join(" ");
	      	path = path + ' ' + ["L", x1 + offset, ((y2 - y1)/2) - 15].join(" ");
	      	path = path + ' ' + ["M", x1 + offset, ((y2 - y1)/2) + 15].join(" ");
	      	path = path + ' ' + ["L", x2 + offset, y2].join(" ");
	      	path = path + ' ' + ["L", x2 , y2].join(" ");
	      	return path;
		}
	},
	components : {
		AccLeads
	}
}

</script>


<style type="text/css">
  text {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: .8em;
  }
  .dim-bracket{fill:none;stroke:#999999;stroke-miterlimit:10;}
</style>