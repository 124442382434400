<template>
	<g id="Thermocouple" :transform="transform">
		<g>
	    	<path d="M-0.2,20c0.3,67.9-6.5,70.1-4.1,98.5c3.5,42.1,20,60.3,35.9,70.9l10,10" class="st2-A"/>
	    	<path d="M-0.2,20c0.3,67.9-6.5,70.1-4.1,98.5c3.5,42.1,20,60.3,35.9,70.9l13,6" class="st2-A"/>
		</g>
		<path class="lead-b" d="M-0.2,20c0.3,67.9-6.5,70.1-4.1,98.5c3.5,42.1,20,60.3,35.9,70.9"/>
		<path class="lead-w" d="M-0.2,20c0.3,67.9-6.5,70.1-4.1,98.5c3.5,42.1,20,60.3,35.9,70.9"/>
	    <rect class="st0" x="-8.2" y="-13.2" width="17.1" height="37.9"/>
	    
	    <g transform="translate(-48,-30) scale(.7, .7)">
			<polyline class="st3-b" points="64.4,40.8 64.4,55 69,64.9 73.5,55 73.5,40.8 	"/>
			<circle cx="69" cy="64.9" r="3.7"/>
			<g>
				<path d="M78.2,45.2l0,2.8l2.7,0l0,0.7l-2.7,0l0,2.8l-0.8,0l0-2.8l-2.7,0l0-0.7l2.7,0l0-2.8L78.2,45.2z"/>
				<path d="M61.8,48v0.8h-3V48H61.8z"/>
			</g>
		</g>
  	</g>
</template>


<script>

import Accessory from './accessory-template.vue';

export default{
	extends: Accessory,
}

</script>