
export default {

  foil_resistivity : 0.00000073, //[Ohms*m]
  foil_thickness : 0.0508, //mm
  edge_setback : 0.125, //in

  getResistance : function(watts, volts){
    return (volts * volts) / watts;
  },

  getResistanceDensity : function(watts, volts, w, l){
    return this.getResistance(watts, volts) / (w * l);
  },

  getEstimateTraceLength : function(num_passes, l){
    return num_passes * (l - (2 * this.edge_setback));
  },

  getEstimatedTraceWidth : function(num_passes, l, watts, volts){
    var traceWidth = (this.foil_resistivity * this.getEstimateTraceLength(num_passes, l)/(this.getResistance(watts, volts) * (this.foil_thickness/1000)));
    //if (traceWidth > .25){traceWidth = .25}
    return traceWidth;
  },

  getEstimatedDistanceBetweenTraces : function(w, l, num_passes, watts, volts){
    return (w - (2 * this.edge_setback) - (num_passes * this.getEstimatedTraceWidth(num_passes, l, watts, volts)))/(num_passes-1);
  },

  getEstimatedCLDistanceBetweenTraces : function(w, l, num_passes, watts, volts){
    return this.getEstimatedDistanceBetweenTraces(w, l, num_passes, watts, volts) + this.getEstimatedTraceWidth(num_passes, l, watts, volts);
  },

  getHeaterSurfaceWattDensity : function(w, l, watts){
    return watts / (w * l);
  },

  getRibbonWattDensity : function(watts, volts, num_passes, l){
    return watts / (2 * this.getEstimateTraceLength(num_passes, l) * this.getEstimatedTraceWidth(num_passes, l, watts, volts));
  },

  findNumPasses : function(w, l, watts, volts){
    var curr_num_passes = 0;
    var num_passes = 2;
    var current_diff = 1000000
    for (var i = 1; i < 5000; i++){
      curr_num_passes = i *2;
      var traceWidth = this.getEstimatedTraceWidth(curr_num_passes, l, watts, volts);
      var distanceBetween = this.getEstimatedDistanceBetweenTraces(w, l, curr_num_passes, watts, volts);
      var d = Math.abs(distanceBetween - traceWidth);
      if (d < current_diff){
        current_diff = d;
        num_passes = curr_num_passes;
      }
      if (traceWidth > distanceBetween){
        break;
      }
    }
    return num_passes;
  },

  getFlexSpecsCalculations : function(watts, volts, area){
    if(typeof watts == 'undefined' || volts == 'undefined' || area == 'undefined'){
      return {};
    }
    if(watts == null || volts == null || area == null){
      return {};
    }
    if (isNaN(parseFloat(watts))){
      return {};
    }
    if (isNaN(parseFloat(volts))){
      return {};
    }
    if (isNaN(parseFloat(area))){
      return {};
    }

    if (area == 1){
      return {};
    }
    if (volts > 2000){
      return {};
    }
    //console.log("watts2", parseFloat(watts))
    var l = parseFloat(Math.sqrt(area));
    var w = parseFloat(Math.sqrt(area));
    var num_passes = this.findNumPasses(w, l, watts, volts);
    return {
      number_of_passes : {
        label : 'Number of Passes',
        value : num_passes,
      },
      resistance : {
        label : 'Resistance',
        value : this.getResistance(watts, volts),
        units : 'Ohms'
      },
      resistance_Density : {
        label : 'Resistance Density',
        value : this.getResistanceDensity(watts, volts, w, l),
        units : 'Ohms/in^2'
      },
      watt_Density : {
        label : 'Watt Density',
        value : this.getHeaterSurfaceWattDensity(w, l, watts),
        units : 'Watts/in^2'
      },
      trace_Length : {
        label : 'Trace Length',
        value : this.getEstimateTraceLength(num_passes, l),
        units : 'in'
      },
      trace_Width : {
        label : 'Trace Width',
        value : this.getEstimatedTraceWidth(num_passes, l, watts, volts),
        units : 'in'
      },
      distance_Between_Traces : {
        label : 'Distance Between Traces',
        value : this.getEstimatedDistanceBetweenTraces(w, l, num_passes, watts, volts),
        units : 'in'
      },
      CL_distance_Between_Traces : {
        label : 'CL Distance Between Traces',
        value : this.getEstimatedCLDistanceBetweenTraces(w, l, num_passes, watts, volts),
        units : 'in'
      },
      ribbon_Watt_Density : {
        label : 'Ribbon Watt Density',
        value : this.getRibbonWattDensity(watts, volts, num_passes, l),
        units : 'Watts/in^2'
      }
    }
  }

}