import Offset from 'polygon-offset';

export default {

	value : null,

	constructor(){

	},

	getPerimeterPoint : function(i, radius, num, halfStep){
		var arcAngle = 360 / num;
		var halfArcAngle = arcAngle / 2;
		if (typeof halfStep == 'undefined' || halfStep === true){
			this.value = this._polarToCartesian(0, 0, radius, (arcAngle * i) - halfArcAngle);
		}else{
			this.value = this._polarToCartesian(0, 0, radius, (arcAngle * i));
		}
		return this;
	},

	getPerimeterAngle : function(i, radius, num, halfStep){
		var arcAngle = 360 / num;
		var halfArcAngle = arcAngle / 2;
		if (typeof halfStep == 'undefined' || halfStep === true){
			return ((arcAngle * i) - halfArcAngle);
		}else{
			return (arcAngle * i);
		}
	},

	getPerimeterCorners : function(i, radius, num, length){
		var full_arcAngle = 360 / num;
		var halfArcAngle = full_arcAngle / 2;
		var offset_arcAngle = this.arcOffsetAngle(radius, num, length)
		var p1 = this._polarToCartesian(0, 0, radius, (full_arcAngle * i) + offset_arcAngle - halfArcAngle);
		var p2 = this._polarToCartesian(0, 0, radius, (full_arcAngle * (i + 1)) - offset_arcAngle - halfArcAngle);
		this.value = [p1, p2];
		return this;
	},

	polarToCartesian : function (centerX, centerY, radius, angleInDegrees){ this.value = this._polarToCartesian(centerX, centerY, radius, angleInDegrees); return this; },
	_polarToCartesian : function (centerX, centerY, radius, angleInDegrees) {
	  var angleInRadians = (angleInDegrees + 90) * Math.PI / 180.0;
	  return {
	    x: centerX + (radius * Math.cos(angleInRadians)),
	    y: centerY + (radius * Math.sin(angleInRadians))
	  };
	},


	

	_offset : function(amt){
		if (Array.isArray(this.value)){
			for (var i = 0; i < this.value.length; i++){
				this.value[i] = {x : this.value[i].x + amt, y : this.value[i].y + amt}
			}
		}else if (typeof this.value == 'object'){
			this.value.x = this.value.x + amt;
			this.value.y = this.value.y + amt;
		}else{
			this.value = this.value + amt;
		}
		return this;
	},

	_scale : function(amt, v){
		if (typeof v != 'undefined'){
			this.value = v;
		}
		if (Array.isArray(this.value)){
			for (var i = 0; i < this.value.length; i++){
				this.value[i] = {x : this.value[i].x * amt, y : this.value[i].y * amt}
			}
		}else if (typeof this.value == 'object'){
			this.value.x = this.value.x * amt;
			this.value.y = this.value.y * amt;
		}else{
			this.value = this.value * amt;
		}
		return this;
	},

	
	offsetPolygon : function(amt){
		var v = this.flattenPoints(this.value);
		var offset = new Offset();
		var g = offset.data(v).offset(amt);
		return g;
	},

	flattenPoints : function(pts){
		var r = [];
		for(var i = 0; i < pts.length; i++){
			r.push([pts[i].x, pts[i].y])
		}
		r.push([pts[0].x, pts[0].y])
		return r;
	},


	arcAngle : function(d, radius){
		return (d / 2 / Math.PI / radius) * 360;
	},

	arcLength : function(angle, radius){
		return 2 * Math.PI * radius * (angle/360);
	},

	distance : function (x1, y1, x2, y2){
		return Math.sqrt( ((x2 - x1) * (x2 - x1)) + ((y2 - y1) * (y2 - y1)));
	},

	find_angle : function (A,B,C) {
	    var AB = Math.sqrt(Math.pow(B.x-A.x,2)+ Math.pow(B.y-A.y,2));    
	    var BC = Math.sqrt(Math.pow(B.x-C.x,2)+ Math.pow(B.y-C.y,2)); 
	    var AC = Math.sqrt(Math.pow(C.x-A.x,2)+ Math.pow(C.y-A.y,2));
	    return (Math.acos((BC*BC+AB*AB-AC*AC)/(2*BC*AB)) * 180) / Math.PI;
	},

	find_right_angle : function (B,C) {
		  var A = {};
		  A.y = C.y - B.y;
		  A.x = C.x - B.x;
		  var theta = Math.atan2(A.y, A.x) // range (-PI, PI]
		  theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
		  //if (theta < 0) theta = 360 + theta; // range [0, 360)
		  return theta;
	},

	arcOffsetAngle : function(radius, num, length){
		var full_arcAngle = 360 / num;
		var full_arcLength = this.arcLength(full_arcAngle, radius);
		var segment_arcLength = length;
		var offset_arcLength = (full_arcLength - segment_arcLength) / 2;
		var offset_arcAngle = this.arcAngle(offset_arcLength, radius);
		return offset_arcAngle;
	},


	describeArc : function (x, y, radius, startAngle, endAngle){

	    var start = this._polarToCartesian(x, y, radius, endAngle);
	    var end = this._polarToCartesian(x, y, radius, startAngle);

	    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

	    var d = ["A", radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(" ");

	    return d;      
	},

	describeReverseArc : function (x, y, radius, startAngle, endAngle){

	    var start = this._polarToCartesian(x, y, radius, startAngle);
	    var end = this._polarToCartesian(x, y, radius, endAngle);

	    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

	    var d = ["A", radius, radius, 0, largeArcFlag, 1, end.x, end.y].join(" ");

	    return d;   ;    
	}
}