<template>
	 <g id="TwoProng" :transform="transform">
		<polygon class="black-plastic" points="20.5,7.1 0,3.6 0,-4.4 20.5,-8.1 	"/>
		<path class="black-plastic" d="M31.5-14.7c-2.9,0-5.2,1.9-5.2,4.2c0,0,0,0.1,0,0.1v1c-0.5,1.1-1.6,1.8-2.9,1.8c-1.7,0-3.1-1.3-3.1-3h-2.5V9.3
			h2.6c0-0.1,0-0.2,0-0.3c0-1.7,1.4-3.2,3.2-3.2c1.3,0,2.3,0.7,2.9,1.8v1c0,0,0,0.1,0,0.1c0,2.3,2.3,4.2,5.2,4.2c0.1,0,0.1,0,0.2,0
			h21v-27.7H31.5z"/>
		<g>
			<rect x="51.1" y="-11.8" class="metal" width="21.7" height="2.7"/>
			<rect x="51.1" y="7.3" class="metal" width="21.7" height="2.7"/>
		</g>
		<rect x="47.6" y="-17.7" class="black-plastic" width="5.1" height="33.6"/>
	</g>
</template>


<script>

import Accessory from './accessory-template.vue';

export default{
	extends: Accessory,
	computed : {
		transform : function(){
			return 'translate(' + this.x + ' ' + this.y + ') scale(' + this.scale/20 + ' ' + this.scale/20 + ')';
		}
	}
}

</script>