<template>
  <v-app>
    <v-layout text-xs-center wrap>
      <v-flex xs12>
        <v-progress-linear v-if="loading" :indeterminate="true"></v-progress-linear>
        <config-steps-mini :preset="preset" :csrf="csrf" ref="heaterConfigurator" v-on:update="updateConfig"></config-steps-mini>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
  
  import ConfigStepsMini from './ConfigStepsMini.vue';
  import request from 'superagent';

  export default {
    props : ['csrf', 'hash', 'preset'],
    data: () => ({
      config : {},
      loading : false
    }),
    mounted : function(){

    },
    methods : {
      updateConfig : function(cfg){
        this.$set(this, 'config', cfg)
      },
      loadHeater : function(cfg){
        cfg.id = 'flex_copy_' + (+ new Date());
        this.$set(this, 'config', cfg);
        this.$refs.heaterConfigurator.updateConfig(cfg, true);
        var g = this;
        var filesrc = cfg.shape.options.imageUrl;
        //this is a mess but the image doesn't load on the preview panel when loading a config from the server. 
        setTimeout(function(){
          g.$refs.heaterConfigurator.flexConfig.shape.options.imageUrl = filesrc;
        }, 500)
        this.$refs.heaterConfigurator.restart();
      },
      getConfig : function(){
        var g = this;
        request
          .get('/site/api/' + this.hash)
          .then(res => {
            g.loading = false;
            if (res.body === null){
              var cfg = JSON.parse(res.text);
              g.loadHeater(cfg);
              return
            }
            g.loadHeater(res.body);
          });
      }
    },
    components : {
      ConfigStepsMini
    }
  }
</script>

<style>

</style>
