<template>
	 <g id="ThreeProng" :transform="transform">
	 	<rect x="0.1" y="-8.9" class="black-plastic"" width="30.9" height="18.2"/>
		<rect x="25.7" y="-2.2" class="black-plastic" width="4.4" height="5"/>
		<rect x="26.6" y="-2.3" class="black-plastic" width="1.2" height="5.3"/>
		<g>
			<polygon class="black-plastic" points="3.5,-0.7 3.5,-6.3 15.2,-6.3 15.2,-8.8 0.1,-8.8 0.1,-0.7 		"/>
			<polygon class="black-plastic" points="3.5,6.5 3.5,1.6 0.1,1.6 0.1,9.3 15.2,9.3 15.2,6.5 		"/>
		</g>
		<rect x="17.8" y="4.6" class="black-plastic" width="9.8" height="3.6"/>
	</g>
</template>


<script>

import Accessory from './accessory-template.vue';

export default{
	extends: Accessory,
	computed : {
		transform : function(){
			return 'translate(' + this.x + ' ' + this.y + ') scale(' + this.scale/20 + ' ' + this.scale/20 + ')';
		}
	}
}

</script>