<template>
	<g id="ThermocoupleMini" :transform="transform">
		
		<path class="st1" d="M-0.2,16.6c0.3,67.9-6.5,70.1-4.1,98.5c3.5,42.1,23.1,61.5,42,64.5"/>
		<path class="st2" d="M-0.2,16.6c0.3,67.9-6.5,70.1-4.1,98.5c3.5,42.1,23.1,61.5,42,64.5"/>

		<rect class="st0" x="-8.2" y="-13.2" width="17.1" height="37.9"/>
	    <g transform="translate(-48,-30) scale(.7, .7)">
			<polyline class="st3-b" points="64.4,40.8 64.4,55 69,64.9 73.5,55 73.5,40.8 	"/>
			<circle cx="69" cy="64.9" r="3.7"/>
			<g>
				<path d="M78.2,45.2l0,2.8l2.7,0l0,0.7l-2.7,0l0,2.8l-0.8,0l0-2.8l-2.7,0l0-0.7l2.7,0l0-2.8L78.2,45.2z"/>
				<path d="M61.8,48v0.8h-3V48H61.8z"/>
			</g>
		</g>
		
		<g>
			<polygon class="st4" points="30.2,175.3 29.1,180.1 45.3,186.2 47.4,176.5 		"/>
			<path class="st4" d="M61.9,178.7l-2.7,12.1l-16.2-3.6c0,0-1.2-0.3,0.2-6.4c1.4-6,2.6-5.8,2.6-5.8L61.9,178.7z"/>
		</g>
	</g>
</template>


<script>

import Accessory from './accessory-template.vue';

export default{
	extends: Accessory,
}

</script>