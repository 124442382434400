<template>
	<g id="Preset" :transform="transform">
            <g transform="scale(.6, .6)">
              <path class="st0-a" d="M11.7,29.7h-23c-1.4,0-2.5-1.1-2.5-2.5v-51c0-1.4,1.1-2.5,2.5-2.5h23c1.4,0,2.5,1.1,2.5,2.5v51
              C14.1,28.6,13,29.7,11.7,29.7z"/>
              <g transform="scale(.9, .9)">
                <path class="st0-a" d="M11.7,29.7h-23c-1.4,0-2.5-1.1-2.5-2.5v-51c0-1.4,1.1-2.5,2.5-2.5h23c1.4,0,2.5,1.1,2.5,2.5v51
                C14.1,28.6,13,29.7,11.7,29.7z"/>
              </g>
            </g>
            <g transform="translate(-43 ,-50) scale(.5,.5)">
              <circle class="st1-a" cx="75.9" cy="99.5" r="2.6"/>
              <circle class="st1-a"  cx="93.7" cy="99.5" r="2.6"/>
              <polyline class="st3" points="68.2,99.5 75.9,99.5 93.4,90.9   "/>
              <line class="st3" x1="93.7" y1="99.5" x2="101.9" y2="99.5"/>
              <polyline class="st3" points="83.5,95.9 83.5,99.2 86.3,99.2 86.3,103.2 83.2,103.2 83.2,107.6  "/>
            </g>
    </g>
</template>


<script>

import Accessory from './accessory-template.vue';

export default{
	extends: Accessory,
}

</script>