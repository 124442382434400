<template>
	<v-card class="elevation-4" v-if="showme && num_saved_heaters > 0">
		<v-card-text>
			<h3 class="text-xs-center">Saved Heater Configurations</h3>
			<v-data-table :headers="headers" :items="saved_heaters_flat" :hide-actions="saved_heaters_flat.length < 6" :pagination.sync="pagination">
			    <template slot="items" slot-scope="props">
			      <td class="text-xs-center"><v-btn color="blue" small dark @click="loadConfig(props.item.id)" fab><v-icon small>fa-edit</v-icon></v-btn></td>
			      <td class="text-xs-left"><strong>{{ titleCase(props.item.label) }}</strong></td>
			      <td class="text-xs-center hide-on-mobile">{{ titleCase(props.item.material) }}</td>
			      <td class="text-xs-center hide-on-mobile">{{ titleCase(props.item.shape) }}</td>
			      <td class="text-xs-center hide-on-mobile">{{ props.item.size }}</td>
			      <td class="text-xs-center"><v-btn color="red" small dark @click="deleteConfig(props.item.id)" fab><v-icon small>fa-times</v-icon></v-btn></td>
			    </template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>


<script>

import debounce from 'lodash/debounce';

export default{
	props : ['cfg', 'value'],
	data : function(){
		//presetting the default data values...
		
		return {
			saved_cookie : 'flex-saved-v2', // key for the cookie we are storing in local storage
        	saved_heaters_flat :[],
        	pagination: {rowsPerPage:5},
        	showme : false,
        	headers : [
        		{
        			text : 'Edit',
        			value : '',
        			sortable: false,
        			align : 'center',
        			width : '0'
        		},
        		{
        			text : 'Name',
        			value : 'label',
        			align : 'left',
        			width : "300px"
        		},
        		{
        			text : 'Material',
        			value : 'material',
        			sortable: false,
        			align : 'center',
        			class : 'hide-on-mobile'
        		},
        		,
        		{
        			text : 'Shape',
        			value : 'shape',
        			sortable: false,
        			align : 'center',
        			class : 'hide-on-mobile'
        		},
        		{
        			text : 'Size',
        			value : 'width',
        			sortable: false,
        			align : 'center',
        			class : 'hide-on-mobile'
        		},
        		{
        			text : 'Delete',
        			value : '',
        			sortable: false,
        			align : 'center',
        			width : '0'
        		},
        		
        	]
		}
	},
	mounted : function(){
		this.loadStorage();
	},
	watch : {
	},
	methods : {
	    logEngagement : debounce(function(type){
	        if (typeof ga != 'undefined'){
	          ga('send', 'event', 'flex-interaction', 'page-save', type);
	        }
	    }, 1000),
		titleCase : function(str) {
			if(typeof(str) == 'number') return Math.round(str * 100) / 100;
				if (typeof(str) == 'undefined' || str === null) return str;
				return str.toLowerCase().split(' ').map(function(word) {
				  return (word.charAt(0).toUpperCase() + word.slice(1));
				}).join(' ');
		},
		loadConfig : function(id){
			this.$emit('load-heater', id);
			this.logEngagement('load_saved_heater');
		},
		addConfig : function(cfg){
			if(this.value === null || Object.keys(this.value).length == 0){
				var v = this.loadStorage()
			}else{
				v = JSON.parse(JSON.stringify(this.value));
			}
			if (v === null){
				v = {};
			}
			v[cfg.id] = cfg
			this.updateSavedHeatersFlat(v)
			this.saveStorage(v);
			this.$emit('input', v);
		},
		deleteConfig : function(id){
			var v = JSON.parse(JSON.stringify(this.value));
			delete(v[id]);
			this.saveStorage(v);
			this.$emit('input', v)
			this.updateSavedHeatersFlat(v);
		},
		loadStorage : function(){
			var v = JSON.parse(localStorage.getItem(this.saved_cookie));
			this.updateSavedHeatersFlat(v);
			this.$emit('input', v);
			return v;
		},
		saveStorage : function(v){
			if (typeof localStorage === 'object') {
			    try {
			        localStorage.setItem(this.saved_cookie, JSON.stringify(v) );
			    } catch (e) {
			        Storage.prototype._setItem = Storage.prototype.setItem;
			        Storage.prototype.setItem = function() {};
			        alert('Your web browser does not support storing custom files this large. You will be able to submit this configuration to us, however, other features like saving multiple configurations may not work.');
			    }
			}
		},
		updateSavedHeatersFlat(v){
			var r = [];
			var g = this;
			if (v !== null){
				Object.keys(v).forEach(function(key){
					var cfg = v[key];
					var item = {
						'label' : cfg.label,
						'material' : cfg.material.value,
						'shape' : cfg.shape.value,
						'size' : cfg.shape.value == "rectangle" ? cfg.width.value + ' ' + cfg.width.units + " x " + cfg.height.value + " " + cfg.width.units : cfg.diameter.value + " " + cfg.diameter.units,
						'id' : cfg.id,
					}
					r.push(item);
				})
			}else{
				r = [];
			}
			this.saved_heaters_flat = r;
		}
	},
	computed : {
		num_saved_heaters : function(){
			return Object.keys(this.value).length;
		}
	}
}

</script>