<template>
	<g id="Spring-Attachment" :transform="transform2">

	  <g v-for="n in num_straps" :key="n"  class="flex-springs">
	  	<g id="spring" :transform="translate_spring(n)">
			<path class="clasp" :d="customClaspSpring"/>
			<g>
				<path class="spring" d="M64.9,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7c1.6,0,2.5,5.9,1.9,12.9
					C61.1,4,51.6,17.7,36.7,5.7c-3.3-3.8,1.5-5.4,3.9-5.8"/>
				<path class="spring" d="M68.4,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7c1.6,0,1.9,5.9,1.9,12.9"/>
				<path class="spring" d="M71.8,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7s1.9,5.9,1.9,12.9"/>
				<path class="spring" d="M75.3,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7c1.6,0,1.9,5.9,1.9,12.9"/>
				<path class="spring" d="M78.8,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7c1.6,0,1.9,5.9,1.9,12.9"/>
				<path class="spring" d="M82.3,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7s1.9,5.9,1.9,12.9"/>
				<path class="spring" d="M85.8,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7c1.6,0,1.9,5.9,1.9,12.9"/>
				<path class="spring" d="M89.2,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7c1.6,0,1.9,5.9,1.9,12.9"/>
				<path class="spring" d="M92.7,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7c1.6,0,1.9,5.9,1.9,12.9"/>
				<path class="spring" d="M96.2,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7s1.9,5.9,1.9,12.9"/>
				<path class="spring" d="M99.8,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7c1.6,0,1.9,5.9,1.9,12.9"/>
				<path class="spring" d="M103.3,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7s1.9,5.9,1.9,12.9"/>
				<path class="spring" d="M106.9,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7c1.6,0,1.9,5.9,1.9,12.9"
					/>
				<path class="spring" d="M110.4,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7c1.6,0,1.9,5.9,1.9,12.9"
					/>
				<path class="spring" d="M114,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7c1.6,0,1.9,5.9,1.9,12.9"/>
				<path class="spring" d="M117.6,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7c1.6,0,1.9,5.9,1.9,12.9"
					/>
				<path class="spring" d="M121.1,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7s1.9,5.9,1.9,12.9"/>
				<path class="spring" d="M124.7,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7c1.6,0,1.9,5.9,1.9,12.9"
					/>
				<path class="spring" d="M128.2,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7c1.6,0,1.9,5.9,1.9,12.9"
					/>
				<path class="spring" d="M131.8,2c-0.1,6.8-2.4,12.4-4.7,12.4c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7c1.6,0,1.9,5.9,1.9,12.9"
					/>
				<path class="spring" d="M137.3,2.1c0.1,0.5,1.3,1.6,3.1,1s3.3-1.9,1.5-3.7s-6.4-1.6-6.5,2.5c-0.1,6.8-2.4,12.4-4.7,12.4
					c-2.4,0-3.6-5.7-3.6-12.7c0-7,1.3-12.7,2.8-12.7c1.6,0,1.9,5.9,1.9,12.9"/>
			</g>
		</g>

		<path :transform="translate_clasp(n)" class="st0" :d="customClasp"/>
	  </g>
      
    </g>
</template>


<script>

import Accessory from './accessory-template.vue';

export default{
	extends: Accessory,
	props : ['cfg'],
	data : function(){
		return {
			spacing : 12
		}
	},
	computed : {
		transform2 : function(){
			return '';
		},
		num_straps : function(){
			if(this.cfg.shape.value == 'trapezoid'){
				return 1;
			}
			if (this.cfg.shape.value == 'rectangle'){
				switch(this.cfg.attachment.options){
					case 'Top to Bottom':
						return Math.ceil((this.cfg.width.value - 2) / this.spacing);
						break;
					case 'Left to Right':
						return Math.ceil((this.cfg.height.value - 2) / this.spacing);
						break;
				}
			}else{
				switch(this.cfg.attachment.options){
					case 'Top to Bottom':
						return Math.ceil((this.cfg.diameter.value - 2) / this.spacing);
						break;
					case 'Left to Right':
						return Math.ceil((this.cfg.diameter.value - 2) / this.spacing);
						break;
				}
			}
		},
		//location of the square terminal block on the rubber heater. 
		customClaspSpring : function(){
			if (this.cfg.shape.value == 'rectangle'){
				return 'M53.4,0c0,13.7-11.1,24.8-24.8,24.8v11.3h-68.9v-68.9h68.9v8.1C42.3-24.8,53.4-13.7,53.4,0z M36.2-4.6 c-2.5,0-4.5,2-4.5,4.5s2,4.5,4.5,4.5s4.5-2,4.5-4.5S38.7-4.6,36.2-4.6z';
			}else if (this.cfg.shape.value == 'trapezoid'){
				var offset = (68.9 + (this.cfg.width.value * 18))
				return 'M53.4,0c0,13.7-11.1,24.8-24.8,24.8v11.3h-' + offset + 'v-68.9h' + offset + '.9v8.1C42.3-24.8,53.4-13.7,53.4,0z M36.2-4.6 c-2.5,0-4.5,2-4.5,4.5s2,4.5,4.5,4.5s4.5-2,4.5-4.5S38.7-4.6,36.2-4.6z';
			}else{
				var offset = (68.9 + (this.cfg.diameter.value * 12))
				return 'M53.4,0c0,13.7-11.1,24.8-24.8,24.8v11.3h-' + offset + 'v-68.9h' + offset + '.9v8.1C42.3-24.8,53.4-13.7,53.4,0z M36.2-4.6 c-2.5,0-4.5,2-4.5,4.5s2,4.5,4.5,4.5s4.5-2,4.5-4.5S38.7-4.6,36.2-4.6z';
			}
		},
		customClasp : function(){
			if (this.cfg.shape.value == 'rectangle'){
				return 'M-49.3,3.3c0-13.7,11.1-24.8,24.8-24.8v-11.3h68.9v68.9h-68.9V28C-38.2,28.1-49.3,17-49.3,3.3z M-32.1,7.9 c2.5,0,4.5-2,4.5-4.5s-2-4.5-4.5-4.5s-4.5,2-4.5,4.5S-34.6,7.9-32.1,7.9z';
			}else if (this.cfg.shape.value == 'trapezoid'){
				var offset = (68.9 + (this.cfg.width.value * 18))
				return 'M-49.3,3.3c0-13.7,11.1-24.8,24.8-24.8v-11.3h' + offset + 'v68.9h-' + offset + 'V28C-38.2,28.1-49.3,17-49.3,3.3z M-32.1,7.9 c2.5,0,4.5-2,4.5-4.5s-2-4.5-4.5-4.5s-4.5,2-4.5,4.5S-34.6,7.9-32.1,7.9z';
			}else{
				var offset = (68.9 + (this.cfg.diameter.value * 12))
				return 'M-49.3,3.3c0-13.7,11.1-24.8,24.8-24.8v-11.3h' + offset + 'v68.9h-' + offset + 'V28C-38.2,28.1-49.3,17-49.3,3.3z M-32.1,7.9 c2.5,0,4.5-2,4.5-4.5s-2-4.5-4.5-4.5s-4.5,2-4.5,4.5S-34.6,7.9-32.1,7.9z';
			}
		}

	},
	methods : {
		_scale: function(v){
			return this.scale * v
		},
		lineTo : function(x, y){
			return ' L' + x + ' ' + y;
		},
		translate_spring : function(n){
			var x = this.cfg.attachment.options == 'Top to Bottom' ? this._scale(this.rect(n)[0]) : this._scale(this.rect(n)[0] + .5);
			var y = this.cfg.attachment.options == 'Top to Bottom' ? this._scale(this.rect(n)[1] - .5) : this._scale(this.rect(n)[1] + .25);
			//:width="_scale(rect(n)[2])" :height="_scale(rect(n)[3])"
			if (this.cfg.attachment.options == 'Top to Bottom'){
				return 'translate(' + x + ', ' + y + ') scale(' + this._scale(.02) + ' ' + this._scale(.02) + ') rotate(-90) ';
			}
			return 'translate(' + x + ', ' + y + ') scale(' + this._scale(.02) + ' ' + this._scale(.02) + ')';
		},
		translate_clasp : function(n){
			var x = this.cfg.attachment.options == 'Top to Bottom' ? this._scale(this.rect(n)[2]) : this._scale(this.rect(n)[2] - .5);
			var y = this.cfg.attachment.options == 'Top to Bottom' ? this._scale(this.rect(n)[3] + .5) : this._scale(this.rect(n)[3] + .25);
			if (this.cfg.attachment.options == 'Top to Bottom'){
				return 'translate(' + x + ', ' + y + ') scale(' + this._scale(.02) + ' ' + this._scale(.02) + ') rotate(-90)';
			}
			return 'translate(' + x + ', ' + y + ') scale(' + this._scale(.02) + ' ' + this._scale(.02) + ')';
		},
		rect : function(n){
			var m = (this.num_straps - 1) * this.spacing;
			if (this.cfg.shape.value == 'rectangle' || this.cfg.shape.value == 'trapezoid'){
				switch(this.cfg.attachment.options){
					case 'Top to Bottom':
						var offset = ((this.cfg.width.value - m) / 2) + ((n - 1) * this.spacing) - 0
						return [
							offset, //x
							.5, //y
							offset, //x2
							this.cfg.height.value - .5 //y2
						];
						break;
					case 'Left to Right':
						offset = ((this.cfg.height.value - m) / 2) + ((n - 1) * this.spacing) - .5
						return [
							this.cfg.width.value - .5,	//x
							offset,	//y
							.5,	//x2
							offset,	//y
						];
						break;
				}
			}else{
				switch(this.cfg.attachment.options){
					case 'Top to Bottom':
						offset = ((this.cfg.diameter.value - m) / 2) + ((n - 1) * this.spacing)
						return [
							offset, //x
							.5, //y
							offset, //x2
							this.cfg.diameter.value - .5 //y2
						];
						break;
					case 'Left to Right':
						offset = ((this.cfg.diameter.value - m) / 2) + ((n - 1) * this.spacing)
						return [
							this.cfg.diameter.value - .5,	//x
							offset,	//y
							.5,	//x2
							offset	//y2
						];
						break;
				}
			}
			return [0, 0, 0, 0];
		},
	}
}

</script>


<style type="text/css">
	.clasp{fill:#C4C4C4;stroke:#010101;stroke-miterlimit:10;}
	.spring{fill:none;stroke:#010101;stroke-width:2;stroke-miterlimit:10;}
</style>