import 'babel-polyfill'
import '@fortawesome/fontawesome-free/css/all.css';

import './css/style.css';

import Vue from 'vue'
import './plugins/vuetify'
//import App from './App.vue'
//import FlexibleHeaterConfig from './components/FlexibleHeaterConfig.vue'
import FlexibleHeaterMini from './components/FlexibleHeaterMini.vue'

Vue.config.productionTip = false


const app = new Vue(
	{ 
		el: '#app-mini',
		//el: '#app',
		data: {
			//product_data : window.product_data,
		},
		components : {
			//App,
			//FlexibleHeaterConfig,
			FlexibleHeaterMini
		},
		methods :{
			
		}
	}
);
