<template>
  <v-container>
    <v-layout class="preview" row wrap v-if="cfg.material && cfg.shape && cfg.shape.value != 'custom'">
      <v-flex xs12 class="text-xs-center" v-if="this.$vuetify.breakpoint.name != 'xs'"><h3>{{cfg.label}} Preview</h3></v-flex>
      
      <v-flex xs12 :class="cfg.material.value + ' flex-preview'" style="position:relative">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" :viewBox="_view_box" :style="getResponsiveStyle" xml:space="preserve">

          <acc-straps v-if="has_straps && cfg.shape.value != 'cone' && cfg.shape.value != 'star'" :cfg="cfg" :scale="scale" :x="0" :y="0"></acc-straps>
          <acc-springs v-if="has_springs && cfg.shape.value != 'cone' && cfg.shape.value != 'star'" :cfg="cfg" :scale="scale" :x="0" :y="0"></acc-springs>

          <heater-rectangle :density="stroke_width" :stroke-style="element_style" :stroke-width="stroke_width" ref="rectanlge_heater" v-if="cfg.shape.value == 'rectangle'" :cfg="cfg" :scale="scale" :x="0" :y="0"></heater-rectangle>

          <g id="HeaterCircle" v-if="cfg.shape.value == 'circle' || cfg.shape.value == 'donut'">
            <circle :cx="dim_center_diameter" :cy="dim_center_diameter" :r="dim_center_diameter" class="flex-heater"/>
            <g id="HeaterCircle-elements">
              <path :style="element_style" class="heater-element" :d="element_path"/>
            </g>
            <acc-leads :cfg="cfg" :scale="scale" :x="0" :y="0"></acc-leads>
            <g id="Dim-Diameter" :transform="dim_diameter_transform">
              <polyline class="dim-bracket" :points="dim_diameter_left"/>
              <polyline class="dim-bracket" :points="dim_diameter_right"/>
              <text text-anchor="middle" :transform="dim_diameter_text">{{cfg.diameter.value}}”</text>
            </g>
            <g id="donut_hole" v-if="cfg.shape.value == 'donut'">
              <circle  :cx="dim_center_diameter" :cy="dim_center_diameter" :r="dim_center_inner_diameter" class="donut-hole"/>
            </g>
            <g id="Dim-Inner-Diameter" v-if="cfg.shape.value == 'donut'">
              <polyline class="dim-bracket" :points="dim_inner_diameter_left"/>
              <polyline class="dim-bracket" :points="dim_inner_diameter_right"/>
              <text text-anchor="middle" :transform="dim_inner_diameter_text">{{cfg.inner_diameter.value}}”</text>
            </g>
            
          </g>

          <heater-star :density="stroke_width" :stroke-style="element_style" ref="star_heater" v-if="cfg.shape.value == 'star'" :cfg="cfg" :scale="scale" :x="0" :y="0"></heater-star>
          <heater-cone :density="stroke_width" :stroke-style="element_style" ref="cone_heater" v-if="cfg.shape.value == 'cone'" :cfg="cfg" :scale="scale" :x="0" :y="0"></heater-cone>
          <heater-trapezoid :density="stroke_width" :stroke-style="element_style" ref="trapezoid_heater" v-if="cfg.shape.value == 'trapezoid'" :cfg="cfg" :scale="scale" :x="0" :y="0"></heater-trapezoid>

          <g v-if="has_mechanical && cfg.shape.value != 'cone' && cfg.shape.value != 'star'">
            <g :transform="'translate(' + getBolt(1) + ')'">
              <circle class="washer" cx="0.1" cy="0.2" r="4.7"/>
              <polygon class="bolt" points="1.9,-2.9 -1.7,-2.9 -3.4,0.2 -1.7,3.3 1.9,3.3 3.6,0.2 "/>
            </g>
            <g :transform="'translate(' + getBolt(2) + ')'">
              <circle class="washer" cx="0.1" cy="0.2" r="4.7"/>
              <polygon class="bolt" points="1.9,-2.9 -1.7,-2.9 -3.4,0.2 -1.7,3.3 1.9,3.3 3.6,0.2 "/>
            </g>
            <g :transform="'translate(' + getBolt(3) + ')'">
              <circle class="washer" cx="0.1" cy="0.2" r="4.7"/>
              <polygon class="bolt" points="1.9,-2.9 -1.7,-2.9 -3.4,0.2 -1.7,3.3 1.9,3.3 3.6,0.2 "/>
            </g>
            <g :transform="'translate(' + getBolt(4) + ')'">
              <circle class="washer" cx="0.1" cy="0.2" r="4.7"/>
              <polygon class="bolt" points="1.9,-2.9 -1.7,-2.9 -3.4,0.2 -1.7,3.3 1.9,3.3 3.6,0.2 "/>
            </g>
          </g>
          


          <acc-thermocouple v-if="has_thermocouple" :type="cfg.controls.sensor.value" :scale="scale" :x="sensor_x" :y="thermostat_y"></acc-thermocouple>
          <acc-thermocouple-mini v-if="has_thermocouple_mini" :type="cfg.controls.sensor.value" :scale="scale" :x="sensor_x" :y="thermostat_y"></acc-thermocouple-mini>
          <acc-rtd v-if="has_rtd" :type="cfg.controls.sensor.value" :scale="scale" :x="sensor_x" :y="thermostat_y"></acc-rtd>
          <acc-thermistor v-if="has_thermistor" :type="cfg.controls.sensor.value" :scale="scale" :x="sensor_x" :y="thermostat_y"></acc-thermistor>
          <acc-adjustable v-if="has_adjustable" :type="cfg.controls.sensor.value" :scale="scale" :x="thermostat_x" :y="thermostat_y"></acc-adjustable>
          <acc-preset v-if="has_preset" :type="cfg.controls.sensor.value" :scale="scale" :x="thermostat_x" :y="thermostat_y"></acc-preset>

          <acc-twoprong v-if="has_twoprongplug" :scale="scale" :x="plug_x" :y="plug_y"></acc-twoprong>
          <acc-threeprong v-if="has_threeprongplug" :scale="scale" :x="plug_x" :y="plug_y"></acc-threeprong>
          <acc-molex v-if="has_molexplug" :scale="scale" :x="plug_x" :y="plug_y"></acc-molex>
          <acc-ampnlock v-if="has_ampnlockplug" :scale="scale" :x="plug_x" :y="plug_y"></acc-ampnlock>

          <defs>
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop  offset="0%" style="stop-color:#AAAAAA"/>
              <stop  offset="35%" style="stop-color:#FFFFFF"/>
              <stop  offset="36%" style="stop-color:#AAAAAA"/>
              <stop  offset="50%" style="stop-color:#FFFFFF"/>
              <stop  offset="75%" style="stop-color:#AAAAAA"/>
              <stop  offset="100%" style="stop-color:#FFFFFF"/>
            </linearGradient>
          </defs>

        </svg>
        <v-btn v-if="!mini && $vuetify.breakpoint.xs" color="grey" dark small absolute bottom right fab @click="show_info_panel = !show_info_panel">
          <v-icon>fa-info</v-icon>
        </v-btn>
      </v-flex>
      <v-footer v-if="!mini && $vuetify.breakpoint.xs" color="grey" app fixed height="50">
        <v-flex xs3 class="text-xs-left">
          <v-btn flat href="/custom/flexible-heaters"><v-icon small left>fa-chevron-circle-left</v-icon>Exit</v-btn>
        </v-flex>
        <v-flex xs8 class="text-xs-right" v-if="$vuetify.breakpoint.xs && !mini && step < 5">
          <v-btn @click="prevStep()" v-if="step > 1">
            Back
          </v-btn>
          <v-btn color="#e81f30" @click="submitStep()">
            Continue
          </v-btn>
        </v-flex>
      </v-footer>
      <v-flex xs12 v-if="!mini && !ignoreWatchers && show_info" class="text-xs-left caption specs">
        <h3 class="text-xs-center">{{cfg.label}} Details</h3>
        <v-layout row wrap>
          <v-flex :class="{'text-xs-left' : true, 'xs12' : true, 'sm6' : typeof(v.value) != 'undefined'}" v-for="(v, k) in cfg" :key="k">
            <div v-if="typeof(v.value) == 'undefined'">
              <span class="main-spec-label"><strong>{{ titleCase(v.label) }}</strong></span>
              <v-layout row wrap>
                <v-flex xs12 v-for="(sv, sk) in v"  :key="sk" v-if="sv.value">
                  <span class="spec-label">{{ titleCase(sv.label) }}: </span>
                  <span class="spec-value">{{ sv.value }}{{ sv.units }}<span class="options" v-if="typeof(sv.options) == 'string' && sv.options != ''"> - {{ sv.options }}</span></span>
                  <br>
                </v-flex>
              </v-layout>
            </div>
            <div v-else>
              <span v-if="v.value" class="spec-label"><strong>{{ titleCase(v.label) }}: </strong></span>
              <span v-if="v.value" class="spec-value">{{ titleCase(v.value) }} {{ v.units }}<span class="options" v-if="typeof(v.options) == 'string'"> > {{ v.options }}</span></span>
            </div>
          </v-flex>
          
           <v-flex class="text-xs-left xs12" style="height:0px; overflow:hidden;">
              <span class="main-spec-label"><strong>Engineering Calculations</strong></span>
              <v-layout row wrap>
                <v-flex xs12 sm12 v-for="(sv, sk) in flexCalc"  :key="sk">
                  <span class="spec-label">{{ titleCase(sv.label) }}: </span>
                  <span class="spec-value">{{ titleCase(sv.value) }} {{ sv.units }}</span><br>
                </v-flex>
              </v-layout>
           </v-flex>
          
        </v-layout>
      </v-flex>

    </v-layout>
    <v-layout row wrap v-else-if="cfg.shape">
      <v-flex xs12>
        <h3>Custom Shape</h3>
        <img :src="customImageSrc" height="150" v-if="customImageSrc"/>
      </v-flex>
    </v-layout>
  </v-container>
</template>



<script>

import HeaterCone from './heater-cone.vue';
import HeaterTrapezoid from './heater-trapezoid.vue';
import HeaterStar from './heater-star.vue';
import HeaterRectangle from './heater-rectangle.vue';

import AccThermocouple from './acc-thermocouple.vue';
import AccThermocoupleMini from './acc-thermocouple-mini.vue';
import AccRtd from './acc-rtd.vue';
import AccPreset from './acc-preset.vue';
import AccAdjustable from './acc-adjustable.vue';
import AccThermistor from './acc-thermistor.vue';
import AccLeads from './acc-leads.vue';
import AccThreeprong from './acc-threeprong.vue';
import AccTwoprong from './acc-twoprong.vue';
import AccAmpnlock from './acc-ampnlock.vue';
import AccMolex from './acc-molex.vue';
import AccStraps from './acc-straps.vue';
import AccSprings from './acc-springs.vue';

import PlainDraggable from 'plain-draggable';

import FlexSpec from '@/plugins/FlexSpecCalculations.js';
import DefaultConfig from "./default-config.js";

  export default {
    props :['cfg', 'mini', 'step', 'ignoreWatchers'],
    data: () => ({
      customImageSrc : null,
      stroke_width : 1,
      show_info_panel : false,
    }),
    created : function(){
      //check to be sure this.cfg.controls.thermostat_preset.value is set before rendering
      if (typeof this.cfg.controls.connector == 'undefined'){
        var _cfg = JSON.parse(JSON.stringify(DefaultConfig));
        this.$set(this.cfg.controls, 'connector', _cfg.controls.connector);
      }
    },
    mounted : function() {
      this.makeDraggable();
    },
    computed : {
      show_info : function(){
        if (this.$vuetify.breakpoint.name != 'xs'){
          return true;
        }else{
          return this.show_info_panel;
        }
      },
      getResponsiveStyle : function(){
        if (this.$vuetify.breakpoint.name != 'xs'){
          return "height:400px; max-width: 100%; max-height:450px; enable-background:new -50 -50 300 300";
        }else{
          return "height:200px; max-width: 100%; max-height:250px; enable-background:new -50 -50 300 300";
        }
      },
      //left side height dim bars
      dim_height_bottom : function(){
        return '-10, ' + this._bottom + ' -15,' + this._bottom + ' -15,' + (this._center_height + 20);  
      },
      dim_height_top : function(){
        return '-10, ' + this._top + ' -15,' + this._top + ' -15,' + (this._center_height - 20);  
      },
      dim_height_text : function(){
        return 'matrix(1 0 0 1 -30 ' + (this._center_height + 3) + ')';
      },

      //top side width dim bars
      dim_width_left : function(){
        return  this._left + ',-5 ' + this._left + ',-15 ' + (this._center_width - 20) + ',-15';  
      },
      dim_width_right : function(){
        return  this._right + ',-5 ' + this._right + ',-15 ' + (this._center_width + 20) + ',-15';  
      },
      dim_width_text : function(){
        return 'matrix(1 0 0 1 ' + (this._center_width - 7) + ' -12 )';
      },

      //circles
      dim_center_diameter : function(){
        return this._scale(this.cfg.diameter.value / 2);
      },
      dim_center_inner_diameter : function(){
        return this._scale(this.cfg.inner_diameter.value / 2);
      },
      dim_diameter_text : function(){
        return 'matrix(1 0 0 1 ' + (this.dim_center_diameter - 2) + ' -12 )';
      },
      dim_diameter_left : function(){
        return  this._left + ',-5 ' + this._left + ',-15 ' + (this.dim_center_diameter - 20) + ',-15';  
      },
      dim_diameter_right : function(){
        return  this._scale(this.cfg.diameter.value) + ',-5 ' + this._scale(this.cfg.diameter.value) + ',-15 ' + (this.dim_center_diameter + 20) + ',-15';  
      },
      //adjusted the top diameter dimension label when donut dimension need placing too. 
      dim_diameter_transform : function(){
        if (this.cfg.shape.value == 'donut'){
          return 'matrix(1 0 0 1 0 -20)';
        }
        return 'matrix(1 0 0 1 0 0)';
      },
      dim_inner_diameter_left : function(){
        return  this._scale(this._left + ((this.cfg.diameter.value - this.cfg.inner_diameter.value)/2)) + ',-5 ' + this._scale(this._left + ((this.cfg.diameter.value - this.cfg.inner_diameter.value)/2)) + ',-15 ' + (this.dim_center_diameter - 20) + ',-15';  
      },
      dim_inner_diameter_right : function(){
        return  (this._scale( (this.cfg.diameter.value / 2) + (this.cfg.inner_diameter.value/2) ) ) + ',-5 ' + (this._scale( (this.cfg.diameter.value / 2) + (this.cfg.inner_diameter.value/2) ) ) + ',-15 ' + (this.dim_center_diameter + 20) + ',-15';  
      },
      dim_inner_diameter_text : function(){
        return 'matrix(1 0 0 1 ' + (this.dim_center_diameter - 2) + ' -12 )';
      },

      //general purpose reference points
      _top : function(){
        return 0
      },
      _bottom : function(){
        return this._scale(this.cfg.height.value)
      },
      _left : function(){
        return 0
      },
      _right : function(){
        return this._scale(this.cfg.width.value)
      },
      _center_height : function(){
        return this._scale(this.cfg.height.value / 2);
      },
      _center_width : function(){
        return this._scale(this.cfg.width.value / 2);
      },
      trap_total_width : function(){
        var offset = parseFloat(this.cfg.offset.value)
        if (isNaN(offset)){
          offset = 0;
        }
        if (offset <= 0){
          if(this.cfg.width.value + Math.abs(offset) > this.cfg.top_width.value){
            return this.cfg.width.value + Math.abs(offset);
          }else{
            return this.cfg.top_width.value;
          }
        }
        if(this.cfg.width.value >= this.cfg.top_width.value + Math.abs(offset)){
          return this.cfg.width.value
        }else if(offset < 0 && this.cfg.width.value + Math.abs(offset) > this.cfg.top_width.value + Math.abs(offset)){
          if (this.cfg.shape.options.type == "isosceles"){
            return this.cfg.width.value;
          }
          return this.cfg.width.value + Math.abs(offset);
        }else{
          if (this.cfg.shape.options.type == "isosceles"){
            return this.cfg.top_width.value;
          }
          return this.cfg.top_width.value + Math.abs(offset);
        }
      },

      //element 
      element_path : function(){
        if(this.cfg.watt_density.value > 60){
          return '';
        }
        if(this.flexCalc.distance_Between_Traces.value < .035){
          return '';
        }
        if (this.cfg.material.value == "mica"){
            var margin = -this._scale(.05);
        }else{
          margin = this._scale(.25);
        }
        
        var density = this.cfg.material.value == "mica" ? this.stroke_width/4 : this.stroke_width/5; //1 - (this.cfg.watt_density.value / 20);
        if(this.cfg.shape.value == 'rectangle'){
          var x = (this._left + (margin));
          if (this.cfg.material.value == "mica"){
            x = x + this._scale(.5);
          }
          var y = (this._top + (margin))
          var p = 'M' + x + ' ' + y;
          var i;
          var c = (this._scale(this.cfg.width.value) - (margin * 2)) / (this._scale(density) / 2);
          var h = this._scale(density);
          for (i = 0; i < c; i++){
            //bottom curve
            if (x + margin > this._scale(this.cfg.width.value)) break;
            y = this._scale(this.cfg.height.value) - (margin * 2);
            if (this.cfg.material.value == "mica"){
              x = x + this._scale(density)
            }
            p = p + this.lineTo(x, y);
            if (x + this._scale(density) >= this._scale(this.cfg.width.value)) break;
            if (this.cfg.material.value == "mica"){
              p = p + 'M ' + (x + this._scale(density)) + ' ' + y;
            }else{
              p = p + this.curveTo(x, y, x + this._scale(density), y, h);
            }
            x = x + this._scale(density);
            if (this.cfg.material.value == "mica"){
              x = x - this._scale(density)
            }
            y = this._scale(this._top) + (margin * 2);
            if (x + this._scale(density) >= this._scale(this.cfg.width.value)) break;
            p = p + this.lineTo(x, y);
            //top curve
            if (x + margin + this._scale(density) >= this._scale(this.cfg.width.value)) break;
            if (this.cfg.material.value == "mica"){
              if (x + margin + this._scale(density * 2) >= this._scale(this.cfg.width.value)) break;
              p = p + 'M ' + (x + this._scale(density)) + ' ' + y;
            }else{
              p = p + this.curveTo(x, y, x + this._scale(density), y, -h);
            }
            
            x = x + this._scale(density);
          }
          return p;
        }
        if(this.cfg.shape.value == 'circle' || this.cfg.shape.value == 'donut' ){
          var diameter = this._scale(this.cfg.diameter.value); 
          var radius = (diameter / 2) - (margin * 3);
          var offsety = (diameter / 2) - (margin/3);
          var offsetx = (diameter / 2) - (margin/3);
          if (this.cfg.material.value == "mica"){
            radius = radius + this._scale(.02);
          }
          h = this._scale(density); // the height of the curve
          x = (-radius);
          if (this.cfg.material.value == "mica"){
            x = x + this._scale(.5);
            y  = -Math.sqrt( Math.abs((radius * radius) - (x * x)) );
          }else{
            y = 0 ;
          }
          p = 'M' + (x + offsetx) + ' ' + (y + offsety); // the path string
          c = (radius * 2) / (this._scale(density) / 2); //determines how many vertical lines/curves to make to fill the space. 
          if (this.cfg.material.value == "mica"){
            x = x + this._scale(density)
          }
          for (i = 0; i < c; i++){
            if (x > radius) break
            y  = Math.sqrt( Math.abs((radius * radius) - (x * x)) );
            p = p + this.lineTo(x + offsetx, y + offsety);
            if (x + this._scale(density) > radius) break;
            if (this.cfg.material.value == "mica"){
              x = x + this._scale(density);
              y  = Math.sqrt( Math.abs((radius * radius) - (x * x)) );
              p = p + 'M ' + (x + offsetx) + ' ' + (y + offsety);
            }else{
              p = p + this.curveTo(x + offsetx, y + offsety, x + this._scale(density) + offsetx, y + offsety, h);
            }
            if (this.cfg.material.value == "mica"){
              x = x - this._scale(density)
            }else{
              x = x + this._scale(density);
            }
            y  = -Math.sqrt( Math.abs((radius * radius) - (x * x)) ) ;
            p = p + this.lineTo(x + offsetx, y + offsety);
            if (x + this._scale(density) > radius) break;
            if (this.cfg.material.value == "mica"){
              x = x + this._scale(density);
              y  = -Math.sqrt( Math.abs((radius * radius) - (x * x)) );
              p = p + 'M ' + (x + offsetx) + ' ' + (y + offsety);
            }else{
              p = p + this.curveTo(x + offsetx, y + offsety, x + this._scale(density) + offsetx, y + offsety, -h);
            }
            x = x + this._scale(density);
          }
          return p;
        }
        return '';
      },

      //svg document params
      scale : function(){
        return 12;
        if (this.cfg.shape.value == 'rectangle'){
          if (this.cfg.width.value >= this.cfg.height.value){
            if (this.cfg.width.value > 13){
              return 28 * (13 / this.cfg.width.value)
            }
          }
          if (this.cfg.width.value < this.cfg.height.value){
            if (this.cfg.height.value > 13){
              return 26 * (13 / this.cfg.height.value)
            }
          }
          return 28;
        }
        if (this.cfg.shape.value == 'circle' || this.cfg.shape.value == 'donut' || this.cfg.shape.value == 'star'){
          if (this.cfg.diameter.value > 13){
            return 28 * (13 / this.cfg.diameter.value)
          }
        }
        return 28;
      },

      _view_box : function(){
        if (this.cfg.shape.value == 'rectangle'){
          if (this.cfg.height.value < 22 && this.cfg.width.value < 18){
            var height = (12 * (this.cfg.height.value) + 125)
            var width = (12 * 18 + 40);
            var z_offset = (width - (12 * this.cfg.width.value + 40)) /2.5;
            return (-30 - z_offset) + " -30 " + (width - z_offset) + " " + height;
          }
          var height = (12 * this.cfg.height.value) + (((12 * this.cfg.height.value) / 400) + 120)
          return "-30 -30 " + (12 * this.cfg.width.value + 40) + " " + height;
        }
        if (this.cfg.shape.value == 'circle' || this.cfg.shape.value == 'donut' || this.cfg.shape.value == 'cone' || this.cfg.shape.value == 'star'){
          var height = (12 * this.cfg.diameter.value) + (((12 * this.cfg.diameter.value) / 400) + 120)
          return "-30 -45 " + (12 * this.cfg.diameter.value + 40) + " " + height;
        }
        if (this.cfg.shape.value == 'trapezoid'){
          var total_width = this.trap_total_width;
          if (this.cfg.height.value < 22 && total_width < 18){
            var height = (12 * (this.cfg.height.value) + 125)
            var width = (12 * 18 + 40);
            var z_offset = (width - (12 * total_width + 40)) /2.5;
            return (-30 - z_offset) + " -30 " + (width - z_offset) + " " + height;
          }
          var height = (12 * this.cfg.height.value) + (((12 * this.cfg.height.value) / 400) + 120)
          return "-30 -30 " + (12 * total_width + 50) + " " + height;
          
        }
        return "-40 -80 400 580";
      },




      //accessories
      has_thermocouple : function(){
        return this.cfg.controls.sensor.value == 'Thermocouple - 18in TYPE K Fiberglass' &&
          this.cfg.controls.connector.value != 'Mini K SMPW-K-M-RoHS';
      },
      has_thermocouple_mini : function(){
        return this.cfg.controls.connector.value == 'Mini K SMPW-K-M-RoHS';
      },
      has_rtd : function(){
        return this.cfg.controls.sensor.value.indexOf('RTD') > -1;
      },
      has_thermistor : function(){
        return this.cfg.controls.sensor.value.indexOf('Thermistor') > -1;
      },
      has_adjustable : function(){
        return this.cfg.controls.thermostat.value == 'Adjustable';
      },
      has_mechanical : function(){
        return this.cfg.attachment.value == 'Mechanical Fasteners';
      },
      has_preset : function(){
        return this.cfg.controls.thermostat.value == 'Preset';
      },
      has_twoprongplug : function(){
        return this.cfg.leads.connectors.value == 'Over Mold 120V Plug';
      },
      has_threeprongplug : function(){
        return this.cfg.leads.connectors.value == '3 Prong';
      },
      has_ampnlockplug : function(){
        return this.cfg.leads.connectors.value.indexOf("AMP") > -1;
      },
      has_molexplug : function(){
        return this.cfg.leads.connectors.value.indexOf("Molex") > -1;
      },
      has_straps : function(){
        return this.cfg.attachment.value == 'Straps';
      },
      has_springs : function(){
        return this.cfg.attachment.value == 'Springs';
      },

      sensor_x : function(){
        if (this.cfg.shape.value == 'rectangle'){
          if (typeof this.cfg.hole_offset != 'undefined' && this.cfg.hole_offset.value > 0){
            return this._scale(this.cfg.width.value - (this.cfg.hole_offset.value / 2));
          }
          return this._center_width - this._scale(1);
        }else if (this.cfg.shape.value == 'trapezoid'){
          var off = this.cfg.offset.value;
          if (this.cfg.shape.options.type == "isosceles"){
            off = this.cfg.width.value/2 - this.cfg.top_width.value/2;
          }
          if (isNaN(off)){
            off = 0;
          }
          var top_mid = (this.cfg.top_width.value/2) - 1.25 + (off <= 0 ? 0 : Math.abs(off));
          var bottom_mid = (this.cfg.width.value/2) - 1.25 + (off >= 0 ? 0 : Math.abs(off));
          return this.find_point(top_mid, 0, bottom_mid, this.cfg.height.value, 0.5).x - this._scale(.75);
        }else if(this.cfg.shape.value == 'cone'){
          return this.dim_center_diameter - this._scale(1);
        }else if(this.cfg.shape.value == 'donut'){
          return (this.dim_center_diameter * 2) - ((this.dim_center_diameter - this.dim_center_inner_diameter)/2);
        }else{
          return this.dim_center_diameter - this._scale(1);
        }      
      },
      sensor_y : function(){
        if (this.cfg.shape.value == 'rectangle'){
          if (this.cfg.height.value < 4) return this._bottom - this._scale(1)
          return this._bottom - this._scale(2.5);
        }else if (this.cfg.shape.value == 'trapezoid'){
          var off = this.cfg.offset.value;
          if (this.cfg.shape.options.type == "isosceles"){
            if (this.cfg.top_width.value > this.cfg.width.value){
              off = this.cfg.top_width.value/2 - this.cfg.width.value/2
            }else{
              off = this.cfg.width.value/2 - this.cfg.top_width.value/2
            }
          }
          if (isNaN(off)){
            off = 0;
          }
          var top_mid = (this.cfg.top_width.value/2) - 1.25 + (off < 0 ? 0 : Math.abs(off));
          var bottom_mid = (this.cfg.width.value/2) - 1.25 + (off >= 0 ? 0 : Math.abs(off));
          return this.find_point(top_mid, 0, bottom_mid, this.cfg.height.value, .65).y;
        }else if(this.cfg.shape.value == 'cone'){
          return this.dim_center_diameter + ((this.dim_center_diameter - this.dim_center_inner_diameter) / 2) + this.dim_center_inner_diameter;
        }else{
          if (this.cfg.diameter.value < 11) return this.dim_center_diameter;
          return (this.dim_center_diameter * 2) - this._scale(5);
        }
      },
      thermostat_x : function(){
        if (this.cfg.shape.value == 'rectangle'){
          if (this.cfg.hole_offset.value > 0){
            return this._scale(this.cfg.hole_offset.value / 2);
          }
          return this._center_width + this._scale(1);
        }else if (this.cfg.shape.value == 'trapezoid'){
          var off = this.cfg.offset.value;
          if (this.cfg.shape.options.type == "isosceles"){
            off = this.cfg.width.value/2 - this.cfg.top_width.value/2;
          }
          if (isNaN(off)){
            off = 0;
          }
          var top_mid = (this.cfg.top_width.value/2) - 1.25 + (off <= 0 ? 0 : Math.abs(off));
          var bottom_mid = (this.cfg.width.value/2) - 1.25 + (off >= 0 ? 0 : Math.abs(off));
          return this.find_point(top_mid, 0, bottom_mid, this.cfg.height.value, 0.5).x + this._scale(.75);
        }else{
          if (this.cfg.shape.value == 'donut'){
            return (this.dim_center_diameter - this.dim_center_inner_diameter) / 2 + this._scale(.5);
          }else{
            return this.dim_center_diameter + this._scale(.5);
          }
        }
      },
      thermostat_y : function(){
        if (this.cfg.shape.value == 'rectangle'){
          return this._center_height;
        }else if (this.cfg.shape.value == 'trapezoid'){
          var off = this.cfg.offset.value;
          if (this.cfg.shape.options.type == "isosceles"){
            if (this.cfg.top_width.value > this.cfg.width.value){
              off = this.cfg.top_width.value/2 - this.cfg.width.value/2
            }else{
              off = this.cfg.width.value/2 - this.cfg.top_width.value/2
            }
          }
          if (isNaN(off)){
            off = 0;
          }
          var top_mid = (this.cfg.top_width.value/2) + (off < 0 ? 0 : Math.abs(off));
          var bottom_mid = (this.cfg.width.value/2) + (off >= 0 ? 0 : Math.abs(off));
          return this.find_point(top_mid, 0, bottom_mid, this.cfg.height.value, .5).y;
        }else if (this.cfg.shape.value == 'cone'){
          return this.dim_center_diameter + ((this.dim_center_diameter - this.dim_center_inner_diameter) / 2) + this.dim_center_inner_diameter;
        }else{
          return this.dim_center_diameter;
        }
      },
      plug_x : function(){
        if (this.cfg.shape.value == 'rectangle'){
          return this._scale(this.cfg.width.value/2 + 2)
        }else if (this.cfg.shape.value == 'trapezoid'){
          var offset = this.cfg.offset.value < 0 ? Math.abs(this.cfg.offset.value) : 0;
          return this._scale(this.cfg.width.value/2 + offset + 2)
        }else{
          return this._scale(this.cfg.diameter.value/2 + 2)
        }      
      },
      plug_y : function(){
        if (this.cfg.shape.value == 'rectangle'){
          return this._scale(this.cfg.height.value + 4.1)
        }else if (this.cfg.shape.value == 'trapezoid'){
          return this._scale(this.cfg.height.value + 4.1)
        }else{
          return this._scale(this.cfg.diameter.value + 4.1)
        }   
      },

      flexCalc : function(){
        if(typeof this.cfg.area.value == 'undefined' || typeof this.cfg.watts.value == 'undefined' || typeof this.cfg.volts.value == 'undefined'){
          return {};
        }
        if(typeof this.cfg.area.value == null || typeof this.cfg.watts.value == null || typeof this.cfg.volts.value == null){
          return {};
        }
        if (parseFloat(this.cfg.watts.value) <= 0){
          return {};
        }
        var c = FlexSpec.getFlexSpecsCalculations(this.cfg.watts.value, this.cfg.volts.value, this.cfg.area.value);
        if(typeof c.trace_Width != 'undefined'){
          this.stroke_width = this.cfg.material.value == "mica" ? (c.trace_Width.value * 10 * 3) : (c.trace_Width.value * 10 * 2);
        }
        return c;
      },

      element_style : function(){
        return 'stroke-width: ' + this.stroke_width + "px;";
      }
      //leads

    },
    methods : {
      submitStep : function(){
        this.$emit('next-step', this.cfg)
      },
      resetConfig : function(){
        this.$emit('reset');
      },
      prevStep : function(){
        this.$emit('prev-step', this.cfg)
      },
      _scale : function(v){
        return v * this.scale;
      },
      lineTo : function(x, y){
        return ' L' + x + ' ' + y;
      },
      curveTo : function(x, y, x2, y2, h){ // pass negative h for top curve and positive h for bottom curve.
        return ' L' + x2 + ' ' + y2;
        return ' C' + x + ' ' + (y + h) + ' ' + x2 + ' ' + (y2 + h) + ' ' + x2 + ' ' + y2;
      },
      makeDraggable : function(){
        //not yet... but maybe... 
        /**
        setTimeout(function(){
          var thermo = new PlainDraggable(document.getElementById('Thermocouple'));
        }, 500)
        **/
      },
      titleCase : function(str) {
        if(typeof(str) == 'number') return Math.round(str * 10000) / 10000;
        if (typeof(str) == 'undefined' || str === null) return str;
        str = str.replace(/_/g, ' ')
        return str.toLowerCase().split(' ').map(function(word) {
          return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
      },
      getBolt : function(i){
        if (this.cfg.shape.value == 'rectangle'){
          if (i == 1){
            return '8,8';
          }
          if (i == 2){
            return (this._right - 8) + ',8';
          }
          if (i == 3){
            return '8,' + (this._bottom - 8);
          }
          if (i == 4){
            return (this._right - 8) + ',' + (this._bottom - 8)
          }
        }else if (this.cfg.shape.value == 'circle' || this.cfg.shape.value == 'donut'){
          var r = this._scale((this.cfg.diameter.value / 2) - .5);
          var r2 = this.dim_center_diameter;
          if (i == 1){
            return (r * Math.sin(this.radian(-45)) + r2) + "," + (r * Math.cos(this.radian(-45)) + r2);
          }
          if (i == 2){
            return (r * Math.sin(this.radian(45)) + r2) + "," + (r * Math.cos(this.radian(45)) + r2);
          }
          if (i == 3){
            return (r * Math.sin(this.radian(135)) + r2) + "," + (r * Math.cos(this.radian(135)) + r2);
          }
          if (i == 4){
            return (r * Math.sin(this.radian(-135)) + r2) + "," + (r * Math.cos(this.radian(-135)) + r2);
          }
        }else if (this.cfg.shape.value == 'trapezoid'){
          var margin = 1;
          var off = parseFloat(this.cfg.offset.value);
          if (isNaN(off)){
            off = 0;
          }
          if (this.cfg.shape.options.type == "isosceles"){
            off = this.cfg.width.value/2 - this.cfg.top_width.value/2
          }
          var top_left = off < 0 ? margin : Math.abs(off) + margin;
          var bottom_left = off > 0 ? margin : Math.abs(off) + margin;
          var top_right = off < 0 ? this.cfg.top_width.value - margin : this.cfg.top_width.value + Math.abs(off) - margin
          var bottom_right = off > 0 ? this.cfg.width.value - margin : this.cfg.width.value + Math.abs(off) - margin;
          var r = margin / this.cfg.height.value;
          if (i == 1){
            var p = this.find_point(top_left, margin, bottom_left, this.cfg.height.value - margin, r);
            return p.x + "," + this._scale(margin);
          }
          if (i == 2){
            var p = this.find_point(top_right, margin, bottom_right, this.cfg.height.value - margin, r);
            return p.x + "," + this._scale(margin);
          }
          if (i == 3){
            var p = this.find_point(top_left, margin, bottom_left, this.cfg.height.value - margin, 1 - r);
            return p.x + "," + this._scale(this.cfg.height.value - margin);
          }
          if (i == 4){
            var p = this.find_point(top_right, margin, bottom_right, this.cfg.height.value - margin, 1 - r);
            return p.x + "," + this._scale(this.cfg.height.value - margin);;
          }
        }
      },
      radian : function(angle){
        return angle * Math.PI / 180;
      },
      find_point : function(x1,y1,x2,y2,r){
        var x3 = parseFloat(x1) + ((parseFloat(x2)-parseFloat(x1)) * r)
        var y3 = y1 + ((y2-y1) * r)
        return{"x" : this._scale(x3), "y" : this._scale(y3)};
      },
    },
    watch : {
      'cfg.controls.sensor.value' : function(){
        this.makeDraggable();
      },
      'cfg.controls.sensor' : function(){
        this.makeDraggable();
      },
      'cfg.shape.options.imageUrl' : function(newV){
        //console.log('updating...image src')
        this.customImageSrc = newV;
      }
    },
    components : {
      AccThermocouple,
      AccThermocoupleMini,
      AccRtd,
      AccAdjustable,
      AccPreset,
      AccLeads,
      AccThreeprong,
      AccTwoprong,
      AccMolex,
      AccAmpnlock,
      AccStraps,
      AccSprings,
      AccThermistor,
      HeaterCone,
      HeaterTrapezoid,
      HeaterStar,
      HeaterRectangle
    }
  }
</script>

<style type="text/css">
  text {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: .8em;
  }
  .flex-heater{fill:#b51717;stroke:#000000;stroke-miterlimit:10;}
  .dim-bracket{fill:none;stroke:#999999;stroke-miterlimit:10;}
  .heater-element{fill:none;stroke:#cE0000;stroke-miterlimit:10;stroke-width:2px;}
  .donut-hole{fill:#ffffff;stroke:#000000;stroke-miterlimit:10;}

  .mica .flex-heater{fill:#ac887f;stroke:#ac887f;stroke-width:1;stroke-miterlimit:10;}
  /*.mica .flex-heater.lead-cover{opacity:0;}*/
  .polyimide .flex-heater{fill:#76140b;stroke:#000000;stroke-miterlimit:10;}
  .polyimide .heater-element{fill:none;stroke:#CF3F27;stroke-miterlimit:10;stroke-width:2px;}
  .mica .heater-element{fill:none;stroke:#979797;stroke-miterlimit:10;stroke-width:2px;opacity:1;}

  .flex-preview .st0{fill:#A9A8A8;stroke:#010101;stroke-miterlimit:10;}
  .flex-preview .st0{fill:#A9A8A8;stroke:#010101;stroke-miterlimit:10;}
  .flex-preview .st0-a{fill:#393838;stroke:#010101;stroke-miterlimit:10;}
  .flex-preview .st1{fill:#FFFFFF;stroke:#010101;stroke-miterlimit:10;}
  .flex-preview .st1-a{fill:#FFFFFF;stroke:#ffffff;stroke-miterlimit:10;}
  .flex-preview .st2{fill:#D7D6D6;stroke:#010101;stroke-miterlimit:10;}
  .flex-preview .st2-A{fill:none;stroke:#010101;stroke-miterlimit:10;}
  .flex-preview .st3{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
  .flex-preview .st3-a{fill:none;stroke:#B9B8B8;stroke-miterlimit:10;}
  .flex-preview .st4{stroke:#010101;stroke-miterlimit:10;}
  .flex-preview .st3-b{stroke:#000000;fill:none;stroke-miterlimit:10;}
  .flex-preview .st5{fill:#ECE712;stroke:#010101;stroke-miterlimit:10;}

  .lead-b{stroke:#000000;fill:none;stroke-miterlimit:10;stroke-width:3px;}
  .lead-w{stroke:#ffffff;fill:none;stroke-miterlimit:10;stroke-width:1px;}
  
  .black-plastic{fill:#555555;stroke:#000000;stroke-miterlimit:10;}
  .white-plastic{fill:#E5E5E5;stroke:#000000;stroke-miterlimit:10;}
  .metal{fill:#cccccc;stroke:#000000;stroke-miterlimit:10;}

  .washer{fill:#D2D2D2;}
  .bolt{fill:#9B9B9B;stroke:#828282;stroke-miterlimit:10;}

  .specs .main-spec-label{
    display:  block;
    border-bottom: 1px solid #aaaaaa;
  }
  .specs .spec-label{
    display:  inline-block;
    width: 50%;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .specs .spec-value{
    display:  inline-block;
    width: 50%;
    vertical-align: top;
  }

  #ThermocoupleMini .st1{fill:none;stroke:#000000;stroke-width:3;stroke-miterlimit:10;}
  #ThermocoupleMini .st2{fill:none;stroke:#2B2B2B;stroke-miterlimit:10;}
  #ThermocoupleMini .st3{fill:#FFFFFF;stroke:#010101;stroke-miterlimit:10;}
  #ThermocoupleMini .st4{fill:#2B2B2B;stroke:#010101;stroke-miterlimit:10;}

  .controller-image img{
    background-color: #ffffff;
    position: absolute;
    left: 0;
    bottom: 70px;
    height: auto;
    border: 1px solid #aaaaaa;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .25);
  }

</style>
