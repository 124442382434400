import { render, staticRenderFns } from "./acc-ampnlock.vue?vue&type=template&id=34ca3362&"
import script from "./acc-ampnlock.vue?vue&type=script&lang=js&"
export * from "./acc-ampnlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "acc-ampnlock.vue"
export default component.exports