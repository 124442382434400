<template>
	<g id="Adjustable" :transform="transform">
            <path class="st0" d="M20.8,30.5h-39.1c-1.4,0-2.5-1.1-2.5-2.5v-51c0-1.4,1.1-2.5,2.5-2.5h39.1c1.4,0,2.5,1.1,2.5,2.5v51
              C23.3,29.4,22.2,30.5,20.8,30.5z"/>
            <circle class="st2" cx="1.6" cy="1" r="13.8"/>
            <circle class="st1" cx="1.6" cy="1" r="9.3"/>
            <circle class="st2" cx="1.6" cy="1" r="7.6"/>
            <polygon class="st1" points="4.6,-6.3 10.4,-9.9 7.9,-3.6  "/>
    </g>
</template>


<script>

import Accessory from './accessory-template.vue';

export default{
	extends: Accessory,
}

</script>