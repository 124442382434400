<template>
	<g id="RTD" :transform="transform">
        <rect x="-14.5" y="-18.1" class="st0" width="30.3" height="38.9"/>
        <polyline class="st3-a" points="-10.5,25.8 -10.5,-13.4 -6.2,-13.4 -6.2,15.8 -1.6,15.8 -1.6,-13.4 3.1,-13.4 3.1,15.8 7.4,15.8 
          7.4,-13.4 11.7,-13.4 11.7,25.8  "/>
        <circle class="st4" cx="-10.5" cy="20.8" r="2"/>
        <circle class="st4" cx="11.7" cy="20.8" r="2"/>
        <path class="lead-b" d="M-10.5,20.8c0,0-7.2,119.7,27.9,185.2c30.2,56.3,89.6,39.9,89.6,39.9"/>
        <path class="lead-b" d="M11.7,20.8c0,0-4.1,65,1.3,123.6c10.2,111.3,90.9,97.5,90.9,97.5"/>
        <path class="lead-w" d="M-10.5,20.8c0,0-7.2,119.7,27.9,185.2c30.2,56.3,89.6,39.9,89.6,39.9"/>
        <path class="lead-w" d="M11.7,20.8c0,0-4.1,65,1.3,123.6c10.2,111.3,90.9,97.5,90.9,97.5"/>
        <g transform="translate(-30, -45)">
          <polyline class="st0" points="18.1,46 22.8,46 24.1,43.7 26.9,48.3 29.5,43.7 32.3,48.3 35,43.7 37.5,48.3 38.6,46 43.1,46     "/>
          <g>
            <line class="st0" x1="26.7" y1="51.7" x2="33.6" y2="41.7"/>
            <g>
              <path d="M33.3,42.2l-1.5-0.1l0-0.1l1.8-1.2c0.5-0.5,1.1-1,1.6-1.5c-0.3,0.7-0.5,1.4-0.8,2l-0.5,2.1l0,0L33.3,42.2z"/>
            </g>
          </g>
        </g>
    </g>
</template>


<script>

import Accessory from './accessory-template.vue';

export default{
	extends: Accessory,
}

</script>
