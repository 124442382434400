<template>
  <v-container class="text-xs-left">
    <v-alert v-if="!mini" transition="scroll-y-transition" :value="show_error" type="warning" dark>
      {{error}}
    </v-alert>
    <h2 v-if="showNameField && !$vuetify.breakpoint.xs">Configuration Name</h2>
    <v-layout row wrap v-if="showNameField && !$vuetify.breakpoint.xs">
      <v-flex xs12>
        <v-text-field ref="heater_name" label="Name This Heater" box v-model="cfg.label" clearable></v-text-field>
      </v-flex>
    </v-layout>

    <v-tabs v-if="$vuetify.breakpoint.xs && !mini" v-model="active_tab" fixed-tabs>
      <v-tab ripple href="#tab-material"><v-icon small left>fa-sticky-note</v-icon>Material</v-tab>
      <v-tab ripple href="#tab-shape"><v-icon small left>fa-shapes</v-icon>Shape</v-tab>
      <v-tab ripple href="#tab-power"><v-icon small left>fa-bolt</v-icon>Power</v-tab>
    </v-tabs>

    <v-tabs-items touchless :is="$vuetify.breakpoint.xs ? 'v-tabs-items' : 'div'" v-model="active_tab">
      <v-tab-item :is="$vuetify.breakpoint.xs ? 'v-tab-item' : 'div'" value="tab-material">
        <h2 v-if="!$vuetify.breakpoint.xs && !mini">Material <v-icon @click="showMaterialDialog">fa-question-circle</v-icon></h2>
        <v-layout v-if="!mini" row :wrap="this.$vuetify.breakpoint.name == 'xs'"">
          <v-flex xs12 sm4 pr-2 v-if="typeof preset == 'undefined' || preset == 'silicone' || preset === null">
            <v-btn :dark="cfg.material.value == 'silicone'" :color="cfg.material.value == 'silicone' ? 'primary' : ''" block @click="cfg.material.value = 'silicone'"><v-icon left small v-if="cfg.material.value == 'silicone'">fa-check</v-icon>Silicone</v-btn>
          </v-flex>
          <v-flex xs12 sm4 pr-2 v-if="typeof preset == 'undefined' || preset == 'polyimide' || preset === null">
            <v-btn :dark="cfg.material.value == 'polyimide'" :color="cfg.material.value == 'polyimide' ? 'primary' : ''" block @click="cfg.material.value = 'polyimide'"><v-icon left small v-if="cfg.material.value == 'polyimide'">fa-check</v-icon>Polyimide</v-btn>
          </v-flex>
          <v-flex xs12 sm4 v-if="typeof preset == 'undefined' || preset == 'mica' || preset === null">
            <v-btn :dark="cfg.material.value == 'mica'" :color="cfg.material.value == 'mica' ? 'primary' : ''" block @click="cfg.material.value = 'mica'"><v-icon left small v-if="cfg.material.value == 'mica'">fa-check</v-icon>Mica</v-btn>
          </v-flex>
        </v-layout>
        <h3 v-if="showNameField && $vuetify.breakpoint.xs">Configuration Name</h3>
        <v-layout row wrap v-if="showNameField && $vuetify.breakpoint.xs">
          <v-flex xs12>
            <v-text-field ref="heater_name" label="Name This Heater" box v-model="cfg.label" clearable></v-text-field>
          </v-flex>
        </v-layout>
      </v-tab-item>
    
      <v-tab-item v-if="!mini" :is="$vuetify.breakpoint.xs ? 'v-tab-item' : 'div'" value="tab-power">
        <h2 v-if="!$vuetify.breakpoint.xs">Power</h2>
        <v-layout :row="$vuetify.breakpoint.xs" wrap>
          <v-flex xs12 sm6 pr-2>
            <v-text-field label="Volts" box v-model="cfg.volts.value"></v-text-field>
          </v-flex>
          <v-flex xs12 sm6>
            <v-text-field label="Watts" box v-model="cfg.watts.value" :messages="watt_density > 0 ? watt_density.toFixed(2) + ' watts/sq.in.' : ''" append-outer-icon="fa-calculator" @click:append-outer="showWattDialog"></v-text-field>
          </v-flex>
        </v-layout>
      </v-tab-item>

      <v-tab-item :is="$vuetify.breakpoint.xs && !mini ? 'v-tab-item' : 'div'" value="tab-shape">
        <h2 v-if="!$vuetify.breakpoint.xs">Shape</h2>
        <v-layout row wrap>
          <v-flex d-flex xs12 v-if="$vuetify.breakpoint.xs && !mini">
            <v-select :items="shape_options" box v-model="cfg.shape.value"></v-select>
          </v-flex>
          <v-flex d-flex xs12 sm4 v-else>
            <v-radio-group :row="mini && $vuetify.breakpoint.xs" v-model="cfg.shape.value">
              <v-radio label="Rectangle" value="rectangle"></v-radio>
              <v-radio label="Circle" value="circle"></v-radio>
              <v-radio label="Donut" value="donut"></v-radio>
              <v-radio label="Cone" value="cone"></v-radio>
              <v-radio label="Star/Sphere" value="star"></v-radio>
              <v-radio label="Trapezoid" value="trapezoid"></v-radio>
              <v-radio label="Custom" value="custom"></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex d-flex xs12 sm7>
            <v-layout wrap>
              <v-flex xs12 v-if="cfg.shape.value == 'trapezoid'">
                Type of Trapezoid
                <v-radio-group row v-model="cfg.shape.options.type">
                  <v-radio label="Right" value="right"></v-radio>
                  <v-radio label="Isosceles" value="isosceles"></v-radio>
                  <v-radio label="Acute/Obtuse" value="acute-obtuse"></v-radio>
                  <v-radio label="Parallelogram" value="parallelogram"></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex xs12 v-if="cfg.shape.value == 'rectangle' || cfg.shape.value == 'trapezoid'">

                <v-slider v-model="width" persistent-hint xhint='Min 1" - Max 18"'  thumb-label="always" label="Width (.in)" :step=".25" min="1" :max="maxWidth" append-icon="fa-keyboard" @click:append="useKeyboard = !useKeyboard" v-if="!useKeyboard"></v-slider>
                <v-text-field v-if="useKeyboard" v-model="width" type="number" :rules="[rules.number_width]" append-outer-icon="fa-map-marker" label="Width (.in)" @click:append-outer="useKeyboard = !useKeyboard"></v-text-field>
                <v-slider v-model="height" persistent-hint xhint='Min 1" - Max 24"' thumb-label="always" label="Height (.in)" :step=".25" min="1" :max="maxHeight" append-icon="fa-keyboard" @click:append="useKeyboard = !useKeyboard" v-if="!useKeyboard"></v-slider>
                <v-text-field v-if="useKeyboard" v-model="height" type="number" :rules="[rules.number_height]" append-outer-icon="fa-map-marker" label="Height (.in)" @click:append-outer="useKeyboard = !useKeyboard"></v-text-field>

              </v-flex>
              <v-flex xs12 v-if="cfg.shape.value == 'trapezoid'">

                <v-slider v-if="(cfg.shape.options.type == 'acute-obtuse' || cfg.shape.options.type == 'isosceles' || cfg.shape.options.type == 'right') && !useKeyboard" v-model="top_width" thumb-label="always" label="Top Width (.in)" :step=".25" min="1" :max="maxWidth" append-icon="fa-keyboard" @click:append="useKeyboard = !useKeyboard"></v-slider>
                <v-text-field v-if="(cfg.shape.options.type == 'acute-obtuse' || cfg.shape.options.type == 'isosceles' || cfg.shape.options.type == 'right') && useKeyboard" v-model="top_width" type="number" :rules="[rules.number_width]" append-outer-icon="fa-map-marker" label="Top Width (.in)" @click:append-outer="useKeyboard = !useKeyboard"></v-text-field>
                <v-slider v-if="(cfg.shape.options.type == 'acute-obtuse' || cfg.shape.options.type == 'parallelogram') && !useKeyboard" v-model="offset" thumb-label="always" label="Offset (.in)" :step=".25" :min="negMinWidth" :max="maxWidth" append-icon="fa-keyboard" @click:append="useKeyboard = !useKeyboard"></v-slider>
                <v-text-field v-if="(cfg.shape.options.type == 'acute-obtuse' || cfg.shape.options.type == 'parallelogram') && useKeyboard" v-model="offset" type="number" :rules="[rules.number_width]" append-outer-icon="fa-map-marker" label="Offset (.in)" @click:append-outer="useKeyboard = !useKeyboard"></v-text-field>

              </v-flex>
              <v-flex xs12 v-if="cfg.shape.value == 'circle' || cfg.shape.value == 'donut' || cfg.shape.value == 'cone'">

                <v-slider v-model="diameter" xpersistent-hint hint='Min 3" - Max 24"' thumb-label="always" label="Diameter (.in)" :step=".25" :min="minDiameter" :max="maxWidth" append-icon="fa-keyboard" @click:append="useKeyboard = !useKeyboard" v-if="!useKeyboard"></v-slider>
                <v-text-field v-if="useKeyboard" v-model="diameter" type="number" :rules="[rules.number_width]" append-outer-icon="fa-map-marker" label="Diameter (.in)" @click:append-outer="useKeyboard = !useKeyboard"></v-text-field>
                <v-slider v-if="(cfg.shape.value == 'donut' || cfg.shape.value == 'cone') && !useKeyboard" v-model="inner_diameter" thumb-label="always" label="Inner Diameter (.in)" :step=".25" min="1" :max="maxInnerDiameter" append-icon="fa-keyboard" @click:append="useKeyboard = !useKeyboard"></v-slider>
                <v-text-field v-if="(cfg.shape.value == 'donut' || cfg.shape.value == 'cone') && useKeyboard" v-model="inner_diameter" type="number" :rules="[rules.number_inner]" append-outer-icon="fa-map-marker" label="Inner Diameter (.in)" @click:append-outer="useKeyboard = !useKeyboard"></v-text-field>
                <v-slider v-if="cfg.shape.value == 'cone' && !useKeyboard" v-model="cone_sweep" thumb-label="always" label="Sweep Angle (degrees)" :step=".5" :min="3" max="359" append-icon="fa-keyboard" @click:append="useKeyboard = !useKeyboard"></v-slider>
                <v-text-field v-if="cfg.shape.value == 'cone' && useKeyboard" v-model="cone_sweep" type="number" :rules="[rules.angle]" append-outer-icon="fa-map-marker" label="Sweep Angle (degrees)" @click:append-outer="useKeyboard = !useKeyboard"></v-text-field>

              </v-flex>

              <v-flex xs12 v-if="cfg.shape.value == 'star'">
                <v-slider v-model="diameter" xpersistent-hint hint='Min 3" - Max 24"' thumb-label="always" label="Diameter (.in)" :step=".25" :min="minDiameter" :max="maxWidth" append-icon="fa-keyboard" @click:append="useKeyboard = !useKeyboard" v-if="!useKeyboard"></v-slider>
                <v-text-field v-if="useKeyboard" v-model="diameter" type="number" :rules="[rules.number_width]" append-outer-icon="fa-map-marker" label="Diameter (.in)" @click:append-outer="useKeyboard = !useKeyboard"></v-text-field>
                <v-slider v-model="inner_diameter" thumb-label="always" label="Inner Diameter (.in)" :step=".25" min="1" :max="maxInnerDiameter" append-icon="fa-keyboard" @click:append="useKeyboard = !useKeyboard" v-if="!useKeyboard"></v-slider>
                <v-text-field v-if="useKeyboard" v-model="inner_diameter" type="number" :rules="[rules.number_inner]" append-outer-icon="fa-map-marker" label="Inner Diameter (.in)" @click:append-outer="useKeyboard = !useKeyboard"></v-text-field>
                <v-slider v-model="edge_length" thumb-label="always" label="Edge Length" :step=".25" min="0" :max="maxEdgeLength"></v-slider>
                <v-slider v-model="num_sides" thumb-label="always" label="Number of Sides" :step="1" min="3" max="18"></v-slider>
              </v-flex>


              <v-flex xs12 v-if="cfg.shape.value == 'custom'">
                  <h3>Upload Custom Drawing</h3>
                  <div v-if="!mini">
                    <p>Maximum file size is 20 MB. 1 file per request.</p>
                    <v-text-field label="Select Image" @click='pickFile' v-model='cfg.shape.options.imageName' prepend-icon='fa-attach_file'></v-text-field>
                    <input type="file" style="display: none" ref="image" accept="image/*" @change="onFilePicked">
                    <p>Please note that when sending custom request, you will only be able to send 1 file per quote request. If you need to send multiple custom files, please send each one in it's own quote request, or you can email us multiple files as attachments. </p>
                  </div>
                  <div v-else>
                    <p>To upload a custom drawing, click "Next" below to visit the full FlexSpecs configurator and select "Custom Shape". </p>
                  </div>
              </v-flex>

              <v-flex xs4 v-if="!mini && cfg.shape.value == 'rectangle'">
                  <v-checkbox v-model="add_hole" label="Add Hole?"></v-checkbox>
              </v-flex>
              <v-flex xs8 v-if="cfg.shape.value == 'rectangle'">
                  <v-slider v-if="add_hole" v-model="hole_offset" thumb-label="always" label="Inset from Edge" :step=".25" min=".5" :max="maxHoleOffset"></v-slider>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex v-if="!$vuetify.breakpoint.xs || mini" xs12 class="text-xs-right">
            <v-btn @click="resetConfig()" v-if="!mini">
              Reset&nbsp;<span v-if="$vuetify.breakpoint.smAndUp">All Parameters</span>
            </v-btn>
            <v-btn class="mr-0" :color="valid_config() ? 'primary' : ''" :disabled="!valid_config()" @click="submitStep()" v-if="!mini">
              Continue
            </v-btn>
            <v-btn class="mr-0" :color="valid_config() ? 'primary' : ''" :disabled="!valid_config()" @click="submitStep()" v-if="mini">
              Next Step
            </v-btn>
          </v-flex>
        </v-layout>
      </v-tab-item>
    </v-tabs-items>

    
  </v-container>
</template>

<script>

  /* eslint-disable */
  import debounce from 'lodash/debounce';
  var debounce_time = screen.width > 1100 ? 0 : Math.round(((100/screen.width) * 100));
  import Star from '@/plugins/ShapeStar';



  export default {
    props : ['cfg', 'preset', 'show-name-field', 'ignore-watchers', 'mini'],
    data: function(){
      return {
        active_tab : null,
        error : '',
        shape_options : [
          {text : 'Rectangle', value : 'rectangle'},
          {text : 'Circle', value : 'circle'},
          {text : 'Donut', value : 'donut'},
          {text : 'Cone', value : 'cone'},
          {text : 'Trapezoid', value : 'trapezoid'},
          {text : 'Custom', value : 'custom'},
        ],
        width : null,
        height : null,
        diameter : null,
        inner_diameter : null,
        cone_sweep : null,
        offset : 0,
        top_width : null,
        edge_length : null,
        num_sides : null,
        add_hole : false,
        hole_offset : null,
        useKeyboard : false,
        rules : {
          required : v => !!v || 'This field is required',
          email : v => /.+@.+/.test(v) || 'E-mail must be valid',
          phone : v => /^[\d ()+-]+$/.test(v) || 'Phone number must be valid',
          number_width : v => (!isNaN(v) && v <= this.maxWidth) || 'Max width is ' + this.maxWidth + ' inches.',
          number_height : v => (!isNaN(v) && v <= this.maxHeight) || 'Max height is ' + this.maxHeight + ' inches.',
          number_inner : v => (!isNaN(v) && v <= this.maxInnerDiameter) || 'Max diameter is ' + this.maxInnerDiameter + ' inches.',
          angle : v => (!isNaN(v) && v < 360) || 'Enter a valid angle in degrees.',
        },
        valid_shape : {
          rectangle : function(t){
            if (t.cfg.width.value == null || t.cfg.height.value == null){return false;}
            if (t.cfg.width.value > t.maxWidth || t.cfg.height.value > t.maxHeight){return false;}
            return true;
          },
          circle : function(t){
            if (t.cfg.diameter.value == null){return false;}
            if (t.cfg.diameter.value > t.maxWidth){return false;}
            return true;
          },
          donut : function(t){
            if (t.cfg.diameter.value == null || t.cfg.inner_diameter.value == null){return false;}
            if (t.cfg.diameter.value > t.maxWidth || t.cfg.inner_diameter.value > t.maxInnerDiameter){return false;}
            return true;
          },
          cone : function(t){
            if (t.cfg.diameter.value == null || t.cfg.inner_diameter.value == null || t.cfg.cone_sweep.value == null){return false;}
            if (t.cfg.diameter.value > t.maxWidth || t.cfg.inner_diameter.value > t.maxInnerDiameter || t.cfg.cone_sweep.value > 360){return false;}
            return true;
          },
          trapezoid : function(t){
            if (t.cfg.width.value == null || t.cfg.height.value == null){return false;}
            if (t.cfg.width.value > t.maxWidth || t.cfg.height.value > t.maxHeight){return false;}
            return true;
          },
          star : function(t){
            if (t.cfg.diameter.value == null || t.cfg.inner_diameter.value == null || t.cfg.edge_length.value == null){return false;}
            if (t.cfg.diameter.value > t.maxWidth || t.cfg.inner_diameter.value > t.maxInnerDiameter){return false;}
            return true;
          },
          'custom' : function(t){
            return true;
          }
        },
      }
    },
    mounted : function() {
      this.$emit('update', this.cfg)
      this.setLocalVars();
    },
    computed : {
      maxWidth : function(){
        if (this.cfg.material.value == 'silicone'){
          switch(this.cfg.shape.value){
            case 'rectangle':
              return this.cfg.height.value <= 17.25 ? 23.25 : 17.25;
              break;
            case 'circle':
              return 17.25;
              break;
            case 'trapezoid':
              return this.cfg.height.value <= 17.25 ? 23.25 : 17.25;
              break;
            case 'cone':
              return 17.25;
              break;
            case 'donut':
              return 17.25;
              break;
          }
          return 17.25;
        }
        if (this.cfg.material.value == 'mica'){
          switch(this.cfg.shape.value){
            case 'rectangle':
              return this.cfg.height.value <= 27 ? 45.5 : 27;
              break;
            case 'trapezoid':
              return this.cfg.height.value <= 27 ? 45.5 : 27;
              break;
          }
          return 27;
        }
        if (this.cfg.material.value == 'polyimide'){
          switch(this.cfg.shape.value){
            case 'rectangle':
              return this.cfg.height.value <= 19.5 ?  27.5 : 19.5;
              break;
            case 'circle':
              return 19.625; 
              break;
            case 'trapezoid':
              return this.cfg.height.value <= 19.5 ?  27.5 : 19.5;
              break;
            case 'cone':
              return 19.625;
              break;
            case 'donut':
              return 19.625;
              break;
          }
          return 19.625;
        }
      },
      negMinWidth : function(){
        return -(this.maxWidth);
      },
      maxHeight : function(){
        if (this.cfg.material.value == 'silicone'){
          switch(this.cfg.shape.value){
            case 'rectangle':
              return this.cfg.width.value <= 18 ? 24 : 18;
              break;
            case 'trapezoid':
              return this.cfg.width.value <= 18 ? 24 : 18;
              break;
          }
          return 18;
        }
        if (this.cfg.material.value == 'mica'){
          switch(this.cfg.shape.value){
            case 'rectangle':
              return this.cfg.width.value <= 27 ? 45.5 : 27;
              break;
            case 'trapezoid':
              return this.cfg.width.value <= 27 ? 45.5 : 27;
              break;
          }
          return 27;
        }
        if (this.cfg.material.value == 'polyimide'){
          switch(this.cfg.shape.value){
            case 'rectangle':
              return this.cfg.width.value <= 19.5 ?  27.5 : 19.5;
              break;
            case 'trapezoid':
              return this.cfg.width.value <= 19.5 ?  27.5 : 19.5;
              break;
          }
          return 19.625;
        }
      },
      minDiameter : function(){
        if (this.cfg.shape.value == 'donut') return 1.5;
        return 1;
      },
      maxInnerDiameter : function(){
        return this.cfg.diameter.value - .5;
      },
      maxHoleOffset : function(){
        switch(this.cfg.shape.value){
            case 'rectangle':
              return this.cfg.width.value < this.cfg.width.value ?  this.cfg.width.value / 2 - .25 : this.cfg.height.value / 2 - .25;
              break;
            case 'trapezoid':
              return this.cfg.width.value <= 19.5 ?  27.5 : 19.5;
              break;
          }
      },
      maxEdgeLength : function(){
        return Star.findMaxEdgeLength(this.cfg);
      },
      watt_density : function(){
        this.calcResistanceDensity()
        return this.calcWattDensity();
      },
      resistance_density : function(){
        return this.calcResistanceDensity();
      },
      show_error : function(){
        return this.error != '';
      }
    },
    methods : {
      //delaying UI updates at different rates is important on mobile devices or devices with slow CPUS. 
      //So we are separating the local UI vars from the config vars that the preview uses to render
      //allowing us to delay updates as needed. 
      setLocalVars : debounce(function(){
        this.width = isNaN(this.cfg.width.value) ? null : parseFloat(this.cfg.width.value);
        this.height = isNaN(this.cfg.height.value) ? null : parseFloat(this.cfg.height.value);
        this.diameter = isNaN(this.cfg.diameter.value) ? null : parseFloat(this.cfg.diameter.value);
        this.inner_diameter = isNaN(this.cfg.inner_diameter.value) ? null : parseFloat(this.cfg.inner_diameter.value);
        this.top_width = isNaN(this.cfg.top_width.value) ? null : parseFloat(this.cfg.top_width.value);
        this.offset = isNaN(this.cfg.offset.value) ? null : parseFloat(this.cfg.offset.value);
        this.cone_sweep = isNaN(this.cfg.cone_sweep.value) ? null : parseFloat(this.cfg.cone_sweep.value);
        this.num_sides = isNaN(this.cfg.num_sides.value) ? null : parseFloat(this.cfg.num_sides.value);
        this.edge_length = isNaN(this.cfg.edge_length.value) ? null : parseFloat(this.cfg.edge_length.value);
        if (typeof this.cfg.hole_offset != 'undefined'){
          this.hole_offset = isNaN(this.cfg.hole_offset.value) ? null : parseFloat(this.cfg.hole_offset.value);
        }else{
          this.hole_offset = null;
          this.$set(this.cfg, 'hole_offset', {label : 'Hole Offset', value : null,units : 'in'});
        }
        this.add_hole = this.hole_offset > 0;
        this.calcWattDensity();
        this.calcResistanceDensity();
      }, debounce_time),
      setConfigVars : debounce(function(){
        this.cfg.width.value = isNaN(this.width) ? null : parseFloat(this.width);
        this.cfg.height.value = isNaN(this.height) ? null : parseFloat(this.height);
        this.cfg.top_width.value = isNaN(this.top_width) ? null :  parseFloat(this.top_width);
        this.cfg.diameter.value = isNaN(this.diameter) ? null : parseFloat(this.diameter);
        this.cfg.inner_diameter.value = isNaN(this.inner_diameter) ? null : parseFloat(this.inner_diameter);
        this.cfg.cone_sweep.value = isNaN(this.cone_sweep) ? null : parseFloat(this.cone_sweep);
        this.cfg.offset.value = isNaN(this.offset) ? null : parseFloat(this.offset);
        this.cfg.num_sides.value = isNaN(this.num_sides) ? null : parseFloat(this.num_sides);
        this.cfg.edge_length.value = isNaN(this.edge_length) ? null : parseFloat(this.edge_length);
        this.cfg.hole_offset.value = isNaN(this.hole_offset) ? null : parseFloat(this.hole_offset);
        this.calcWattDensity();
        this.calcResistanceDensity();
        this.logEngagement(this.cfg.shape.value);
      }, debounce_time),
      logEngagement : debounce(function(type){
        if (typeof ga != 'undefined'){
          if(typeof this.mini != 'undefined' && this.mini===true){
            ga('send', 'event', 'flex-interaction', 'page-mini', type);
          }else{
            ga('send', 'event', 'flex-interaction', 'page-1', type);
          }
        }
      }, 1000),
      submitStep : function(){
        //validate the step inputs...
        //good?
        if (this.valid_config()) this.$emit('next-step', this.cfg)
      },
      valid_config : function(){
        // eslint-disable-next-line
        var f = this.valid_shape[this.cfg.shape.value];
        if(!f(this)){
          this.error = "Invalid shape dimensions";
          return false;
        }
        if (this.cfg.watt_density.value == null || isNaN(this.cfg.watt_density.value)){
          this.error = "Please fix the errors before continuing.";
          return false;
        }
        if (this.cfg.material.value == 'silicone'){
          if (this.watt_density <= 30 && this.cfg.resistance_density.value <= 30){
            this.error = "";
            return true;
          }else{
            if (this.cfg.resistance_density.value > 30){
              this.error = "The resistance density may be too high for this material! Consult our engineers for verification.";
            }else{
              this.error = "The watt density may be too high for this material! Consult our engineers for verification.";
            }
            return true; //false;
          }
        }
        if (this.cfg.material.value == 'polyimide'){
          if (this.watt_density <= 20 && this.cfg.resistance_density.value <= 30){
            this.error = "";
            return true;
          }else{
            if (this.cfg.resistance_density.value > 30){
              this.error = "The resistance density may be too high for this material! Consult our engineers for verification.";
            }else{
              this.error = "The watt density may be too high for this material! Consult our engineers for verification.";
            }
            return true; //false;
          }
        }
        if (this.cfg.material.value == 'mica'){
          if (this.watt_density <= 25 && this.cfg.resistance_density.value <= 30){
            this.error = "";
            return true;
          }else{
            if (this.cfg.resistance_density.value > 30){
              this.error = "The resistance density may be too high for this material! Consult our engineers for verification.";
            }else{
              this.error = "The watt density may be too high for this material! Consult our engineers for verification.";
            }
            
            return true; //false;
          }
        }
        return false;
      },
      calcWattDensity(){
        if(isNaN(parseFloat(this.cfg.watt_density.value))){
          //return 0;
        }
        if (this.cfg.shape.value == 'rectangle'){
          this.cfg.area.value = (this.cfg.width.value * this.cfg.height.value)
          if (typeof this.cfg.hole_offset != 'undefined' && this.cfg.hole_offset.value > 0){
            this.cfg.area.value = this.cfg.area.value - ( (this.cfg.width.value - (this.cfg.hole_offset.value * 2)) * (this.cfg.height.value - (this.cfg.hole_offset.value * 2)) )
          }
          this.cfg.watt_density.value = this.cfg.watts.value / this.cfg.area.value;
          return this.cfg.watt_density.value;
        }
        if (this.cfg.shape.value == 'circle'){
          var radius = this.cfg.diameter.value/2;
          this.cfg.area.value = (Math.PI * (radius * radius) );
          this.cfg.watt_density.value = this.cfg.watts.value / this.cfg.area.value;
          return this.cfg.watt_density.value;
        }
        if (this.cfg.shape.value == 'donut'){
          radius = this.cfg.diameter.value/2;
          var inner_radius = this.cfg.inner_diameter.value/2;
          var circle_area = (Math.PI * (radius * radius) );
          var hole_area = (Math.PI * (inner_radius * inner_radius) );
          this.cfg.area.value = (circle_area - hole_area);
          var area = (circle_area - hole_area);
          this.$set(this.cfg.area, 'value', area);
          this.cfg.watt_density.value = this.cfg.watts.value / this.cfg.area.value;
          return this.cfg.watt_density.value;
        }
        if (this.cfg.shape.value == 'cone'){
          radius = this.cfg.diameter.value/2;
          var inner_radius = this.cfg.inner_diameter.value/2;
          var circle_area = (Math.PI * (radius * radius) );
          var hole_area = (Math.PI * (inner_radius * inner_radius) )
          this.cfg.area.value = ((circle_area - hole_area) * (this.cfg.cone_sweep.value / 360));
          this.cfg.watt_density.value = this.cfg.watts.value / this.cfg.area.value;
          return this.cfg.watt_density.value;
        }
        if (this.cfg.shape.value == 'trapezoid'){
          this.cfg.area.value = ((this.cfg.width.value + this.cfg.top_width.value)/2)*this.cfg.height.value;
          this.cfg.watt_density.value = this.cfg.watts.value / this.cfg.area.value;
          return this.cfg.watt_density.value;
        }
        if (this.cfg.shape.value == 'star'){
          this.cfg.area.value = Star.findArea(this.cfg);
          this.cfg.watt_density.value = this.cfg.watts.value / this.cfg.area.value;
          return this.cfg.watt_density.value;
        }
        if (this.cfg.shape.value == 'custom'){
          this.cfg.area.value = 1;
          this.cfg.watt_density.value = 1;
          return this.cfg.watt_density.value;
        }
        return 0;
      },
      calcResistanceDensity(){
        this.cfg.resistance.value = (this.cfg.volts.value * this.cfg.volts.value) / this.cfg.watts.value;
        this.cfg.resistance_density.value = this.cfg.resistance.value / this.cfg.area.value;
        return this.cfg.resistance_density.value;
      },
      pickFile () {
        this.$refs.image.click ()
      },
      onFilePicked (e) {
        const files = e.target.files
        if(files[0] !== undefined) {
          this.cfg.shape.options.imageName = files[0].name
          if(this.cfg.shape.options.imageName.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader ()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.cfg.shape.options.imageUrl = fr.result
            this.cfg.shape.options.imageFile = files[0] // this is an image file that can be sent to server...
          })
        } else {
          this.cfg.shape.options.imageName = ''
          this.cfg.shape.options.imageFile = ''
          this.cfg.shape.options.imageUrl = ''
        }
      },
      showWattDialog : function(){
        this.$emit('dialog', {title:'How Many Watts Do You Need?', text: '', watt_calculator : true})
      },
      showMaterialDialog : function(){
        this.$emit('dialog', {title:'Polyimide, Silicone or Mica?', text: '<p><strong>Polyimide:</strong> Thin and highly precise. These heaters can handle a higher watt density than silicone. Ideal anywhere there are space or weight restrictions. Maximum temperature is 390°F. 0.10"-0.12" thick with a minimum bend radius of .120"</p><p><strong>Silicone:</strong> More durable than polyimide with other amazing properties. Silicone is flexible and resistant to many chemicals. Heats quickly and can maintain precise temperatures. Maximum temperature is 455°F. .030" thick with a minimum bend radius is .300"</p><p><strong>Mica Surface Heaters:</strong>Less flexible and must be mechanically fastened. Mica heaters can go up to 1200°F. Note: mica is not "sealed", the elements are exposed and wrapped around the mica. Can be .020" thick or more. Bending is not recommended as it\'s too brittle</p>'})
      },
      resetConfig : function(){
        this.$emit('reset');
        this.setLocalVars();
      },
    },
    watch : {
      'error' : function(newV){
        this.cfg.error.value = newV;
      },
      'cfg.volts.value' : function(){
        this.logEngagement('volts');
      },
      'cfg.watts.value' : function(){
        this.logEngagement('watts');
      },
      'cfg.watt_density.value' : function(){
        this.valid_config();
      },
      'cfg.resistance_density.value' : function(){
        this.valid_config();
      },
      'cfg.material.value' : function(new_v, old_v){
        if (this.ignoreWatchers){
          this.setLocalVars();
          return;
        }
        if (old_v == 'mica'){
          this.cfg.controls.thermostat.value = 'None';
          this.cfg.controls.thermostat.options = '';
          if (this.cfg.attachment.value == 'Mechanical Fasteners'){
            this.cfg.attachment.value = 'None';
          }
        }
        if (new_v == 'mica'){
          this.cfg.controls.thermostat.value = 'N/A';
          this.cfg.controls.thermostat.options = '';
          if (this.cfg.attachment.value == 'Pressure Sensitive Adhesive' || this.cfg.attachment.value == 'Vulcanized to Metal'){
            this.cfg.attachment.value = 'Mechanical Fasteners';
          }
        }
        this.valid_config();
        this.setLocalVars();
        this.logEngagement(this.cfg.material.value);
      },
      'cfg.shape.value' : function(newV){
        if (this.ignoreWatchers){
          this.setLocalVars();
          return;
        }
        if (newV == 'custom'){
          this.cfg.shape.options.imageName = '';
          this.cfg.shape.options.imageUrl = '';
          this.cfg.shape.options.imageFile = '';
          this.cfg.area.value = 1;
          this.cfg.watt_density.value = 1;
        }else{
          this.cfg.shape.options.imageName = '';
          this.cfg.shape.options.imageUrl = '';
          this.cfg.shape.options.imageFile = '';
        }
        if (newV == 'rectangle'){
          this.cfg.diameter.value = '';
          this.cfg.inner_diameter.value = '';
          this.cfg.num_sides.value = '';
          this.cfg.edge_length.value = '';
          this.cfg.height.value = 10;
          this.cfg.width.value = 10;
          this.cfg.cone_sweep.value = '';
          this.cfg.shape.options.type = '';
          this.cfg.offset.value = '';
          this.cfg.top_width.value = '';
        }else if (newV == 'circle'){
          this.cfg.height.value = '';
          this.cfg.width.value = '';
          this.cfg.num_sides.value = '';
          this.cfg.edge_length.value = '';
          this.cfg.diameter.value = 10;
          this.cfg.inner_diameter.value = '';
          this.cfg.cone_sweep.value = '';
          this.cfg.shape.options.type = '';
          this.cfg.offset.value = '';
          this.cfg.top_width.value = '';
        }else if (newV == 'donut'){
          this.cfg.height.value = '';
          this.cfg.width.value = '';
          this.cfg.diameter.value = 10;
          this.cfg.num_sides.value = '';
          this.cfg.edge_length.value = '';
          this.cfg.inner_diameter.value = 3;
          this.cfg.cone_sweep.value = '';
          this.cfg.shape.options.type = '';
          this.cfg.offset.value = '';
          this.cfg.top_width.value = '';
        }else if (newV == 'cone'){
          this.cfg.height.value = '';
          this.cfg.width.value = '';
          this.cfg.diameter.value = 10;
          this.cfg.num_sides.value = '';
          this.cfg.edge_length.value = '';
          this.cfg.inner_diameter.value = 3;
          this.cfg.cone_sweep.value = 230;
          this.cfg.shape.options.type = '';
          this.cfg.offset.value = '';
          this.cfg.top_width.value = '';
        }else if (newV == 'trapezoid'){
          this.cfg.height.value = 10;
          this.cfg.width.value = 12;
          this.cfg.offset.value = 0;
          this.cfg.num_sides.value = '';
          this.cfg.edge_length.value = '';
          this.cfg.top_width.value = 9;
          this.cfg.shape.options.type = 'isosceles';
          this.cfg.diameter.value = '';
          this.cfg.inner_diameter.value = '';
          this.cfg.cone_sweep.value = '';
        }else if (newV == 'star'){
          this.cfg.height.value = '';
          this.cfg.width.value = '';
          this.cfg.offset.value = '';
          this.cfg.num_sides.value = 12;
          this.cfg.edge_length.value = 2.5;
          this.cfg.top_width.value = '';
          this.cfg.shape.options.type = '';
          this.cfg.diameter.value = 12;
          this.cfg.inner_diameter.value = 5;
          this.cfg.cone_sweep.value = '';
          this.cfg.leads.location.value = "Standard"
        }else{
          this.cfg.diameter.value = '';
          this.cfg.inner_diameter.value = '';
          this.cfg.height.value = '';
          this.cfg.num_sides.value = '';
          this.cfg.edge_length.value = '';
          this.cfg.width.value = '';
          this.cfg.cone_sweep.value = '';
          this.cfg.shape.options.type = '';
          this.cfg.offset.value = '';
          this.cfg.top_width.value = '';
        }
        this.cfg.hole_offset.value = null;
        this.calcWattDensity()
        this.calcResistanceDensity()
        this.setLocalVars();
      },
      'cfg.shape.options.type' : function(newV){
        if (this.ignoreWatchers){
          this.setLocalVars();
          return;
        }
        if (newV == 'right' || newV == 'isosceles'){
          this.cfg.offset.value = '';
        }
        if(newV == 'parallelogram'){
          this.cfg.top_width.value = this.cfg.width.value;
        }
        this.setLocalVars();
      },
      'cfg.width.value' : function(newV){
          if (this.ignoreWatchers){
            this.setLocalVars();
            return;
          }
          if(this.cfg.shape.value == 'trapezoid' &&  this.cfg.shape.options.type == 'parallelogram'){
            this.cfg.top_width.value = newV;
          }
          this.setLocalVars();
        },
      'cfg.top_width.value' : function(newV){
          this.setLocalVars();
        },
      'cfg.height.value' : function(newV){
          this.setLocalVars();
        },
      'cfg.diameter.value' : function(newV){
          this.setLocalVars();
        },
      'cfg.inner_diameter.value' : function(newV){
          this.setLocalVars();
        },
      'cfg.offset.value' : function(newV){
          this.setLocalVars();
        },
      'cfg.cone_sweep.value' : function(newV){
          this.setLocalVars();
        },
      'cfg.num_sides.value' : function(newV){
          this.setLocalVars();
        },
      'cfg.edge_length.value' : function(newV){
          this.setLocalVars();
        },
       'cfg.hole_offset.value' : function(newV){
          if (newV > 0){
            this.add_hole = true;
          }else{
            this.add_hole = false;
          }
          this.setLocalVars();
        },


      'width' : debounce(function(newV){
          this.setConfigVars();
        }, debounce_time),
      'top_width' : function(newV){
          this.setConfigVars();
        },
      'height' : function(newV){
          this.setConfigVars();
        },
      'diameter' : function(newV){
          this.setConfigVars();
        },
      'inner_diameter' : function(newV){
          this.setConfigVars();
        },
      'offset' : function(newV){
          this.setConfigVars();
        },
      'hole_offset' : function(newV){
        this.setConfigVars();
      },
      'cone_sweep' : function(newV){
          this.setConfigVars();
        },
        'num_sides' : function(newV){
          this.setConfigVars();
        },
        'edge_length' : function(newV){
          this.setConfigVars();
        },

        'add_hole' : function(newV){
          console.log("hole" + this.hole_offset);
          if(newV === true) {
            if (isNaN(this.hole_offset) || this.hole_offset == null){
              this.hole_offset = 3;
            } 
          }else{
            this.hole_offset = null;
          }
        }
    },
    components : {
    
    },
  }
</script>

<style>

</style>
