const i = (Date.now());

export default {
        error : {
          label : 'Errors',
          value : null,
        },
        id : 'flaxible' + i,
        hash : '',
        label : 'Flexible Heater',
        material : {
          label : 'Material',
          value : 'silicone'
        },
        shape : {
          label : 'Shape',
          value : 'rectangle',
          options : {
            imageUrl : '',
            imageName : '',
            imageFile : '',
            type : '',
          }
        },
        height : {
          label : 'Height',
          value : 8,
          units : 'in'
        },
        width : {
          label : 'Width',
          value : 8,
          units : 'in'
        },
        area : {
          label : 'Area',
          value : 1,
          units : 'sq/in'
        },
        top_width : {
          label : 'Top Width',
          value : null,
          units : 'in'
        },
        offset : {
          label : 'Offset',
          value : null,
          units : 'in'
        },
        hole_offset : {
          label : 'Hole Offset',
          value : null,
          units : 'in'
        },
        diameter : {
          label : 'Diameter',
          value : null,
          units : 'in'
        },
        inner_diameter : {
          label : 'Inner Diameter',
          value : null,
          units : 'in'
        },
        edge_length : {
          label : 'Edge Length',
          value : null,
          units : 'in'
        },
        num_sides : {
          label : 'Number of Sides',
          value : null,
        },
        cone_height : {
          label : 'Cone Height',
          value : null,
          units : 'in'
        },
        cone_sweep : {
          label : 'Cone Sweep',
          value : null,
          units : 'degrees'
        },
        volts : {
          label : 'Volts',
          value : 120,
        },
        watts : {
          label : 'Watts',
          value : 150
        },
        resistance : {
          label : 'Resistance',
          value : 1,
          units : "ohms"
        },
        watt_density : {
          label : 'Watt Density',
          value : 1,
          units : 'per sq/in'
        },
        resistance_density : {
          label : 'Res. Density',
          value : 0.1,
          units : 'ohms sq/in'
        },
        attachment : {
          label : "Attachment Method",
          value : "None",
          options : null
        },
        preset : {
          label : "Initial Preset",
          value : null,
        },
        leads : {
          label : 'Leads',
          'location' : {
              label : 'Lead Location',
              value : 'Standard',
              options : ''
          },
          'type' : {
              label : 'Lead Type',
              value : 'PTFE / Teflon',
              options : ''
          },
          'length' : {
              label : 'Lead Length',
              value : '12 in.',
              options : ''
          },
          'connectors' : {
              label : 'Lead Connectors',
              value : 'None Needed',
              options : ''
          }
        },
        controls : {
          label : 'Controls',
          'sensor' : {
              label : 'Sensor',
              value : 'Thermocouple - 18in TYPE K Fiberglass',
              options : ''
          },
          'thermostat' : {
              label : 'Thermostat',
              value : 'MC-5A Temperature Controller',
              options : ''
          },
          'thermostat_preset' : {
              label : 'Custom Preset',
              value : '',
              options : ''
          },
          'connector' : {
              label : 'Connector',
              value : 'Mini K SMPW-K-M-RoHS',
              options : ''
          }
        },
        requirements : {
          label : 'Requirements',
          'usage' : {
              label : 'Annual Usage',
              value : '',
              options : ''
          },
          'listing' : {
              label : 'Listing Requirements',
              value : '',
              options : ''
          },
          'lead_time' : {
              label : 'Lead Time',
              value : '',
              options : ''
          },
          'quantity' : {
              label : 'Quantity of Heaters',
              value : '',
              options : ''
          }
        }

    }